<template>
    <v-dialog v-model="dialog" persistent max-width="400px">
        <v-card v-if="dialog">
            <v-card-text>
                <v-form ref="form1" v-model="form1Valid" lazy-validation>
                    <v-row>
                        <v-col>
                            <v-autocomplete v-model="agenciaId" label="Agência" placeholder=" "
                                            :items="agencias" :loading="isAgenciasLoading"
                                            :search-input.sync="searchAgenciasValue"
                                            item-text="nome" item-value="id" auto-select-first
                                            hide-no-data :rules="requiredRules" eager>
                            </v-autocomplete>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <div class="pa-3" style="width: 100%">
                    <v-btn text @click="closeDialog">Cancelar</v-btn>

                    <div class="float-right">
                        <v-btn text color="primary" @click="save">
                            Salvar
                        </v-btn>
                    </div>

                </div>
            </v-card-actions>
            <v-overlay absolute :value="loading" color="white">
                <v-progress-circular :size="100" :width="7" color="deep-orange" indeterminate></v-progress-circular>
            </v-overlay>
        </v-card>
    </v-dialog>
</template>

<script>
    import {FiscalizacaoQueries} from "../../../queries/FiscalizacaoQueries";
    import gql from "graphql-tag";
    import {getIconByFiscalizacaoTipo} from '../../../assets/FiscaizacaoUtil'
    import {AgenciaQueries} from "../../../queries/AgenciaQueries";

    export default {
        name: "AlteraAgenciaDialog",
        data() {
            return {
                dialog: false,
                fiscalizacaoId: null,
                form1Valid: true,
                requiredRules: [
                    (v) => !!v || 'Campo obrigatório',
                ],
                loading: false,
                agenciaId: null,
                searchAgenciasValue: null,
                isAgenciasLoading: false,
                agencias: [],
            }
        },
        watch: {
            searchAgenciasValue(value) {
                if (value !== null) {
                    this.searchAgencias(value);
                }
            },
        },
        methods: {
            openDialog(fiscalizacaoId) {
                this.dialog = true;
                this.fiscalizacaoId = fiscalizacaoId;
                this.getTipos();
            },
            closeDialog() {
                this.dialog = false;
            },
            save(){
                if(!this.$refs.form1.validate()){
                    return false
                }
                this.loading = true;
                this.$apollo.mutate({
                    mutation: gql`mutation ($fiscalizacaoId:Int!, $agenciaId:Int!){
                        alteraAgenciaFiscalizacao(fiscalizacaoId: $fiscalizacaoId, agenciaId: $agenciaId){
                            id
                            nome
                            codigo
                        }
                    }`,
                    variables: {
                        fiscalizacaoId: this.fiscalizacaoId,
                        agenciaId: this.agenciaId
                    },
                }).then((result) => {
                    this.$emit('saved', Object.assign({}, result.data.alteraAgenciaFiscalizacao));
                    this.loading = false;
                    this.closeDialog();
                });
            },
            getTipos() {
                this.$apollo.query({query: FiscalizacaoQueries.getTipos}).then((result) => {
                    this.tipos = result.data.tipos.map((tipo) => {
                        tipo.nome = tipo.nome.toUpperCase();
                        return tipo;
                    });
                });
            },
            getItemIcon(tipo){
                return getIconByFiscalizacaoTipo(tipo);
            },
            searchAgencias(value) {
                this.isAgenciasLoading = true;
                this.$apollo.query({
                    query: AgenciaQueries.findAgencias,
                    variables: {arg: value, limit: 10},
                }).then((result) => {
                    this.agencias = result.data.agencias.map((agencia) => {
                        agencia.nome = agencia.codigo + ' - ' + agencia.nome;

                        return agencia;
                    });
                }).finally(() => {
                    this.isAgenciasLoading = false;
                });
            },
        }
    }
</script>

<style scoped>

</style>