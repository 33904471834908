import gql from 'graphql-tag';

export class FinanciamentoGarantiaQueries {
    public static save = gql`mutation ($financiamentoId: Int!, $financiamentoGatantia:FinanciamentoGarantiaInput!){
        saveFinanciamentoGarantia(financiamentoId: $financiamentoId, data: $financiamentoGatantia){
            id
            quantidade
            valor
            descricao{
                id
                codigo
                descricao
            }
            qualificacao{
                id
                codigo
                descricao
            }
            quantidadeUnidadeMedida{
                id
                sigla
            }
        }
    }`;

    public static update = gql`mutation ($id:Int!, $financiamentoGarantia:FinanciamentoGarantiaInput!){
        updateFinanciamentoGarantia(id: $id, data: $financiamentoGarantia){
            id
            quantidade
            valor
            descricao{
                id
                codigo
                descricao
            }
            qualificacao{
                id
                codigo
                descricao
            }
            quantidadeUnidadeMedida{
                id
                sigla
            }
        }
    }`;

    public static delete = gql`mutation ($id: Int!){
        deleteFinanciamentoGarantia(id: $id)
    }`;
}
