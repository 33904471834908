import gql from 'graphql-tag';

export class GarantiaQualificacaoQueries {
    public static getAll = gql`query {
        garantiaQualificacoes{
            id
            codigo
            descricao
        }
    }`;

    public static save = gql`mutation ($garantiaQualificacao:GarantiaQualificacaoInput!){
        saveGarantiaQualificacao(data: $garantiaQualificacao){
            id
            codigo
            descricao
        }
    }`;
}
