import {GarantiaDescricao} from '@/models/GarantiaDescricao';
import {GarantiaQualificacao} from '@/models/GarantiaQualificacao';
import {Localizacao} from '@/models/Localizacao';
import {UnidadeMedida} from '@/models/UnidadeMedida';

export class FinanciamentoGarantia {
    public descricao: GarantiaDescricao;
    public qualificacao: GarantiaQualificacao;
    public localizacao: Localizacao = new Localizacao();
    public quantidade: number;
    public quantidadeUnidadeMedida: UnidadeMedida;
    public valor: number;
}
