import gql from 'graphql-tag';

export class UnidadeMedidaQuieries {
    public static getAll = gql`query {
        unidadesMedida{
            id
            sigla
            nome
        }
    }`;
}
