import {ObjetoDescricao} from '@/models/ObjetoDescricao';
import {ObjetoQualificacao} from '@/models/ObjetoQualificacao';
import {UnidadeMedida} from '@/models/UnidadeMedida';

export class ObjetoFinanciado {
    public descricao: ObjetoDescricao;
    public qualificacao: ObjetoQualificacao;
    public quantidade: number;
    public quantidadeUnidadeMedida: UnidadeMedida;
    public valor: number;
}
