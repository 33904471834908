<template>
    <div>
        <v-alert color="blue" dense class="py-0" v-if="solicitacaoAberta">
            <v-row align="center">
                <v-col class="shrink">
                    <v-icon>
                        mdi-alert
                    </v-icon>
                </v-col>
                <v-col class="grow">
                    <span class="link" @click="">Você tem uma solicitação de prorrogação em aberto. Se você já possúi uma resposta clique em 'Confirmar'</span>
                </v-col>
                <v-col class="shrink text-right d-flex">
                    <div class="d-flex flex-column justify-center mr-4">
                        <span class="caption text-no-wrap" style="line-height: 12px">{{solicitacaoAberta.createdAt | moment("DD/MM/YYYY")}}</span>
                        <span class="caption text-no-wrap" style="line-height: 12px">{{solicitacaoAberta.createdAt | moment("HH:mm [hrs]")}}</span>
                    </div>
                    <v-btn elevation="1" small
                           @click="$refs.definirProrrogacaoDialog.openDialog(solicitacaoAberta)">Confirmar</v-btn>
                    <v-avatar color="indigo" size="28" class="ml-4">
                        <usuario-avatar :usuario="solicitacaoAberta.emailSolicitacao.enviadoPor" :text-class="'caption'" />
                    </v-avatar>
                </v-col>
            </v-row>
        </v-alert>

        <definir-prorrogacao-dialog ref="definirProrrogacaoDialog" @saved="onSolicitacaoProrrogacaoDefinida" />
    </div>
</template>

<script>
import UsuarioAvatar from "@/components/UsuarioAvatar";
import DefinirProrrogacaoDialog from "@/views/servico/fiscalizacao/DefinirProrrogacaoDialog";

export default {
    name: "SolicitacoesProrrogacaoFragment",
    components: {
        DefinirProrrogacaoDialog,
        UsuarioAvatar,
    },
    computed:{
        solicitacaoAberta(){
            return this.fiscalizacao.solicitacoesProrrogacao.find((solicitacao) => solicitacao.dataLimiteDefinida === null)
        }
    },
    props: {
        fiscalizacao: null,
    },
    methods:{
        onSolicitacaoProrrogacaoDefinida(solicitacaoProrrogacao){
            this.$set(this.fiscalizacao.solicitacoesProrrogacao, this.fiscalizacao.solicitacoesProrrogacao.length -1, solicitacaoProrrogacao);
            this.fiscalizacao.dataLimite = solicitacaoProrrogacao.dataLimiteDefinida;
        }
    }
}
</script>

<style scoped>

</style>