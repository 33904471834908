<template>
    <v-dialog v-model="dialog" persistent max-width="500px">
        <v-card v-if="dialog">
            <v-card-title>
                <span class="headline">Cancelar Fiscalização</span>
            </v-card-title>
            <v-card-text>
                <v-form ref="form1" v-model="form1Valid" lazy-validation>
                    <v-row no-gutters>
                        <v-col>
                            <v-textarea v-model="motivo"
                                        outlined rows="4" hide-details
                                        placeholder="(Opcional) Descreva o motivo do cancelamento"
                                        label="Motivo" auto-grow/>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <div class="pa-3" style="width: 100%">
                    <v-btn text @click="closeDialog">Cancelar</v-btn>

                    <div class="float-right">
                        <v-btn color="primary" text depressed @click="save" >
                            Prosseguir
                        </v-btn>
                    </div>

                </div>
            </v-card-actions>
            <v-overlay absolute :value="loading" color="white">
                <v-progress-circular :size="100" :width="7" color="deep-orange" indeterminate></v-progress-circular>
            </v-overlay>
        </v-card>
    </v-dialog>
</template>

<script>
import gql from "graphql-tag";

export default {
    name: "CancelarFiscalizacaoDialog",
    data() {
        return {
            dialog: false,
            loading: false,
            fiscalizacaoId: null,
            form1Valid: true,
            motivo: null,
            senha: null,
        }
    },
    methods: {
        openDialog(fiscalizacaoId) {
            this.dialog = true;
            this.fiscalizacaoId = fiscalizacaoId;
        },
        closeDialog() {
            this.dialog = false;
        },
        save() {
            this.loading = true;
            this.$apollo.mutate({
                mutation: gql`mutation($id:Int!, $motivo:String) {
                    cancelarFiscalizacao(id:$id, motivo: $motivo){
                        id
                        dataCancelamento
                        motivoCancelamento
                        canceladoPor {
                            id
                            nome
                            imagem {
                                id
                            }
                        }
                    }
                }`,
                variables:{
                    id: this.fiscalizacaoId,
                    motivo: this.motivo
                }
            }).then((result) => {
                this.$emit('sent', Object.assign({}, result.data.cancelarFiscalizacao));
                this.loading = false;
                this.closeDialog();
            });

        }
    }
}
</script>

<style scoped>

</style>