<template>
    <v-dialog v-model="dialog" width="500">
        <v-card>
            <v-card-title>
                <span class="headline">Nova Qualificação</span>
            </v-card-title>
            <v-card-text>
                <v-form ref="formQualificacao" v-model="formQualificacao" lazy-validation>
                    <v-row>
                        <v-col cols="4">
                            <v-text-field v-model="qualificacao.codigo" label="Código"
                                          placeholder=" " v-mask="'###'"></v-text-field>
                        </v-col>
                        <v-col cols="8">
                            <v-text-field v-model="qualificacao.descricao" label="Descrição"
                                          placeholder=" "
                                          :rules="requiredRules" required></v-text-field>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <div class="pa-3" style="width: 100%">
                    <v-btn text @click="cancel">Cancelar</v-btn>

                    <div class="float-right">
                        <v-btn text color="primary" @click="save">Salvar</v-btn>
                    </div>

                </div>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import {ObjetoQualificacaoQueries} from '../../../../queries/ObjetoQualificacaoQueries';

    export default {
        name: 'QualificacaoDialog',
        data() {
            return {
                dialog: false,
                qualificacao: {
                    codigo: null,
                    descricao: null,
                },
                formQualificacao: true,
                requiredRules: [
                    (v) => !!v || 'Campo obrigatório',
                ],
            };
        },
        methods: {
            openDialog(sugestao) {
                if(sugestao){
                    this.qualificacao.index = sugestao.index;
                    this.qualificacao.descricao = sugestao.descricao;
                }
                this.dialog = true;
            },
            closeDialog() {
                this.dialog = false;
                this.qualificacao = {codigo: null, descricao: null};
            },
            resetForm() {
                this.$refs.formQualificacao.reset();
            },
            save() {
                if (this.$refs.formQualificacao.validate()) {
                    let qualificacaoParsed = this._.cloneDeep(this.qualificacao);
                    delete  qualificacaoParsed.index;
                    this.$apollo.mutate({
                        mutation: ObjetoQualificacaoQueries.save,
                        variables: {
                            objetoQualificacao: qualificacaoParsed,
                        },
                    }).then((result) => {
                        let res = Object.assign({}, result.data.saveObjetoQualificacao);
                        res.index = this.qualificacao.index;
                        this.$emit('saved', res);
                        this.resetForm();
                        this.closeDialog();
                    });
                }
            },
            cancel() {
                this.resetForm();
                this.closeDialog();
            },
        },
    };
</script>

<style scoped>

</style>
