import gql from 'graphql-tag';

export class ObjetoQualificacaoQueries {
    public static getAll = gql`query {
        objetoQualificacoes{
            id
            codigo
            descricao
        }
    }`;

    public static save = gql`mutation ($objetoQualificacao:ObjetoQualificacaoInput!){
        saveObjetoQualificacao(data: $objetoQualificacao){
            id
            codigo
            descricao
        }
    }`;
}
