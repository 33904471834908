<template>
    <page v-if="fiscalizacao">
        <v-breadcrumbs slot="breadcrumb" class="pa-0">
            <v-breadcrumbs-item exact :to="{name:'dashboard'}">Início</v-breadcrumbs-item>
            <v-breadcrumbs-divider>></v-breadcrumbs-divider>
            <v-breadcrumbs-item exact :to="{name:'todas_fiscalizacoes'}">Fiscalizações</v-breadcrumbs-item>
        </v-breadcrumbs>
        <v-alert type="error" v-if="fiscalizacao.dataCancelamento">
            <v-row align="center" no-gutters>
                <v-col>
                    Fiscalização cancelada!
                    <p v-if="fiscalizacao.motivoCancelamento" class="mr-8  text-body-2 font-italic mb-0">
                        {{fiscalizacao.motivoCancelamento}}
                    </p>
                </v-col>
                <v-col class="shrink d-flex text-right">
                    <div class="d-flex flex-column justify-center mr-4">
                        <span class="caption text-no-wrap" style="line-height: 12px">{{fiscalizacao.dataCancelamento | moment("DD/MM/YYYY")}}</span>
                        <span class="caption text-no-wrap" style="line-height: 12px">{{fiscalizacao.dataCancelamento | moment("HH:mm [hrs]")}}</span>
                    </div>
                    <v-avatar color="indigo" size="28" class="ml-4">
                        <usuario-avatar :usuario="fiscalizacao.canceladoPor" :text-class="'caption'" />
                    </v-avatar>
                </v-col>
            </v-row>
        </v-alert>
        <lembretes-fragment ref="lembretesFragment" :fiscalizacao="fiscalizacao" />
        <solicitacoes-prorrogacao-fragment :fiscalizacao="fiscalizacao" />

        <v-alert v-if="fiscalizacaoVencida()" :class="'py-1' + (finalizado ? ' mb-1' : '')" dark color="orange darken-3" >
            <span class="caption font-weight-bold">Fiscalização vencida!</span>
            <span class="caption font-weight-regular"> Enviada pelo banco após a data limite.</span>
        </v-alert>



        <div v-if="fiscalizacao">
            <div>
                <div>
                    <span class="title font-weight-regular d-inline-flex" style="line-height: normal; align-items: center">
                        {{fiscalizacao.financiamento.mutuario}}
                        <v-menu :close-on-content-click="false"
                                 offset-y open-on-hover>
                            <template v-slot:activator="{ on }">
                                <v-btn class="ml-2" small icon color="grey" outlined v-on="on">
                                    <v-icon >mdi-information-variant</v-icon>
                                </v-btn>
                            </template>
                            <v-list dense>
                                <v-list-item>
                                    <v-list-item-content>
                                        <v-list-item-title class="font-weight-light">Número da solicitação</v-list-item-title>
                                        <v-list-item-subtitle class="font-weight-bold">{{fiscalizacao.numeroSolicitacao}}</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item>
                                    <v-list-item-content>
                                        <v-list-item-title class="font-weight-light">Data de envio da operação</v-list-item-title>
                                        <v-list-item-subtitle class="font-weight-bold">{{fiscalizacao.dataArquivoBanco | moment('DD/MM/YYYY')}}</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item>
                                    <v-list-item-content>
                                        <v-list-item-title class="font-weight-light">Data limite</v-list-item-title>
                                        <v-list-item-subtitle class="font-weight-bold">{{fiscalizacao.dataLimite | moment('DD/MM/YYYY')}}</v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-action v-if="fiscalizacao.solicitacoesProrrogacao.find((s) => s.dataLimiteDefinida !== null)">
                                        <v-chip @click="$refs.solicitacoesProrrogacaoHistoricoDialog.openDialog(fiscalizacao.solicitacoesProrrogacao)" label x-small color="red" dark>DATA PRORROGADA</v-chip>
                                    </v-list-item-action>
                                </v-list-item>
                                <v-list-item v-if="fiscalizacao.dataFinalizacao">
                                    <v-list-item-content>
                                        <v-list-item-title class="font-weight-light">Data da finalização</v-list-item-title>
                                        <v-list-item-subtitle class="font-weight-bold">{{fiscalizacao.dataFinalizacao | moment('DD/MM/YYYY')}}</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item v-if="fiscalizacao.envioBancoSis">
                                    <v-list-item-content>
                                        <v-list-item-title class="font-weight-light">Data de envio para o banco</v-list-item-title>
                                        <v-list-item-subtitle class="font-weight-bold">{{fiscalizacao.envioBancoSis | moment('DD/MM/YYYY HH:mm')}}</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-menu>

                        <v-menu offset-y >
                            <template v-slot:activator="{ on }">
                                <v-btn class="ml-2" small icon color="grey" outlined v-on="on">
                                    <v-icon small>mdi-dots-vertical</v-icon>
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item v-if="!finalizado && fiscalizacao.dataCancelamento == null" @click="editGeneralInfoDialog">
                                    <v-list-item-title>Editar</v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="$refs.lembretesFragment.newLembreteDialog()" v-if="!finalizado && fiscalizacao.dataCancelamento == null">
                                    <v-list-item-title>Criar Lembrete</v-list-item-title>
                                </v-list-item>
                                <v-list-item :disabled="fiscalizacao.solicitacoesProrrogacao.find((s) => s.dataLimiteDefinida === null)"
                                             @click="$refs.solicitarProrrogacaoDialog.openDialog(fiscalizacao)"
                                             v-if="!finalizado && fiscalizacao.dataCancelamento == null">
                                    <v-list-item-title>Solicitar Prorrogação</v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="atualizarInformacoesPeloLaudo(fiscalizacao.id)" v-if="!finalizado && fiscalizacao.dataCancelamento == null">
                                    <v-list-item-title>Atualizar informações pelo laudo do banco</v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="desfinalizarFiscalizacao(fiscalizacao.id)" v-if="finalizado && fiscalizacao.dataCancelamento == null">
                                    <v-list-item-title >Desfazer Finalização</v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="cancelarFiscalizacao(fiscalizacao.id)" v-if="!finalizado && fiscalizacao.dataCancelamento == null" >
                                    <v-list-item-title class="red--text" >Cancelar Fiscalização</v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="cancelarFiscalizacao(fiscalizacao.id)" v-if="fiscalizacao.dataCancelamento != null" >
                                    <v-list-item-title >Descancelar Fiscalização</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>

                        <v-btn v-if="finalizado" :loading="downloadingXls" :disabled="downloadingXls"
                               class="ml-2" small outlined icon color="indigo" @click="downloadLaudo('pdf')">
                            <v-icon small>mdi-file-download-outline</v-icon>
                        </v-btn>
                    </span>
                    <div v-if="fiscalizacao.supervisedBy" style="opacity: 0.6; float: right" class="d-inline-flex align-center">
                        <div class="d-flex align-center">

                            <div>
                                <p class="mb-0 grey--text " style="font-size:10px; line-height: 12px">Responsável</p>
                                <p class="mb-0 caption grey--text text--darken-2" style="line-height: 12px">{{fiscalizacao.supervisedBy.nome}}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <v-menu offset-y open-on-hover >
                    <template v-slot:activator="{ on }">
                        <v-btn v-if="fiscalizacao.fiscalizacoesMesmoMutuario.length > 0" class="d-block mt-1" small color="blue" dark v-on="on" depressed>
                            <v-chip x-small color="white" light class="mr-2 px-2">{{fiscalizacao.fiscalizacoesMesmoMutuario.length}}</v-chip> fiscalizações com o mesmo nome de mutuário
                        </v-btn>
                    </template>
                    <v-list class="py-0">
                        <template v-for="(fiscalizacao, index) in fiscalizacao.fiscalizacoesMesmoMutuario">
                            <v-list-item :to="{ name: 'fiscalizacao_view', params: { id: parseInt(fiscalizacao.id) }}" :class="getFinalizadoClass(fiscalizacao)">
                                <v-list-item-content>
                                    <v-list-item-title>{{fiscalizacao.financiamento.mutuario}}</v-list-item-title>
                                    <v-list-item-subtitle >
                                        {{'N. Solicitação: ' + fiscalizacao.numeroSolicitacao + ' Operação: ' + fiscalizacao.financiamento.operacao+(fiscalizacao.financiamento.operacaoDigito ? '-'+fiscalizacao.financiamento.operacaoDigito : '')}}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-list-item-action-text >Data Limite: <b>{{new Date(fiscalizacao.dataLimite) | moment("DD MMM YYYY")}}</b></v-list-item-action-text>
                                    <v-list-item-action-text v-if="fiscalizacao.dataFiscalizacao">Data Fiscalização: <b>{{new Date(fiscalizacao.dataFiscalizacao) | moment("DD MMM YYYY")}}</b></v-list-item-action-text>
                                </v-list-item-action>
                            </v-list-item>
                        </template>
                    </v-list>
                </v-menu>
                <span v-if="fiscalizacao.tipo" class="caption text-uppercase">{{fiscalizacao.tipo.nome}}</span>
                <v-btn v-else color="orange"
                       dark  x-small rounded depressed
                       @click="openAlteraTipoDialog">
                    <v-icon left small>mdi-alert-circle-outline</v-icon>
                    Definir Tipo
                </v-btn>
            </div>

            <v-row>
                <v-col>
                    <v-card color="blue-grey darken-3" dark outlined class="mb-3" v-if="fiscalizacao.dataFiscalizacao">
                        <v-row class="pa-4 " no-gutters>
                            <v-col class="shrink mr-4" style="min-width: 300px" v-if="fiscalizacao.caminhoPolyline">
                                <v-img :src="getCaminhoImageUrl(fiscalizacao)"
                                       width="100%" class="fill-height" >
                                    <template v-slot:placeholder>
                                        <v-row
                                            class="fill-height ma-0"
                                            align="center"
                                            justify="center"
                                        >
                                            <v-progress-circular
                                                indeterminate
                                                color="grey lighten-5"
                                            ></v-progress-circular>
                                        </v-row>
                                    </template>
                                </v-img>
                            </v-col>
                            <v-col class="">
                                <div v-if="finalizado" style="border-radius: 4px"
                                     :class="(fiscalizacao.regular ? 'green' : 'red') + ' py-1 text-center mb-2'">
                                    <span class="title-1 white--text font-weight-medium text-uppercase">
                                        Fiscalização {{fiscalizacao.regular ? 'regular' : 'irregular'}}
                                    </span>
                                </div>
                                <v-row no-gutters>
                                    <v-col cols="">
                                        <span class="label" style="color: #d5d5d5 !important;">Data da Fiscalização</span>
                                        {{(fiscalizacao.dataFiscalizacao) | moment('DD [de] MMMM [de] YYYY')}}
                                    </v-col>
                                </v-row>
                                <v-row no-gutters>
                                    <v-col cols="6">
                                        <span class="label" style="color: #d5d5d5 !important;">Distância Percorrida</span>
                                        <template v-if="fiscalizacao.distanciaPercorrida">
                                            {{fiscalizacao.distanciaPercorrida}} km
                                            <span v-if="fiscalizacao.distanciaPercorrida > 500"> ({{fiscalizacao.distanciaPercorrida - 500}} km excedidos)</span>
                                        </template>
                                        <span v-else>Não foi até o local</span>
                                    </v-col>
                                    <v-col cols="6">
                                        <span class="label" style="color: #d5d5d5 !important;">Ponto de Partida</span>
                                        <router-link :to="{ name: 'cidade_view', params: { id: parseInt(fiscalizacao.pontoPartida.cidade.id) }}">
                                        {{fiscalizacao.pontoPartida.cidade.nome}}/{{fiscalizacao.pontoPartida.cidade.estado.sigla}}
                                        </router-link>
                                    </v-col>
                                </v-row>
                                <v-row no-gutters>
                                    <v-col>
                                        <span class="label" style="color: #d5d5d5 !important;">Comentários e Justificativas</span>
                                        {{fiscalizacao.comentario}}
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-card>

                    <div class="d-flex  mb-6">
                        <v-alert class="text-right mb-0 mr-4 py-0" text color="teal" >
                            <span class="overline">Valor Financiado</span>
                            <span class="headline d-block">
                            {{fiscalizacao.financiamento.valor | formatNumber}}
                        </span>
                        </v-alert>

                        <v-alert class="text-right mb-0 mr-4  py-0" text color="blue-grey lighten-2">
                            <span class="overline">Valor Total Grantias</span>
                            <span class="headline d-block">
                            {{fiscalizacao.financiamento.valorTotalGarantias | formatNumber}}
                        </span>
                        </v-alert>

                        <v-alert class="text-right mb-0  py-0" text color="blue-grey lighten-2">
                            <span class="overline">Valor Recursos Próprios</span>
                            <span class="headline d-block">
                            {{fiscalizacao.financiamento.valorRecursosProprios | formatNumber}}
                        </span>
                        </v-alert>
                    </div>

                    <!--INFO-->
                    <v-card outlined class="mb-6 pa-4">
                        <v-row no-gutters>
                            <v-col cols="3">
                                <span class="label">Chave da Operação</span>
                                {{fiscalizacao.financiamento.operacao}}<span v-if="fiscalizacao.financiamento.operacaoDigito">-{{fiscalizacao.financiamento.operacaoDigito}}</span>
                            </v-col>
                            <v-col cols="3">
                                <span class="label">Nº da Cédula</span>
                                {{fiscalizacao.financiamento.cedula}}
                            </v-col>
                            <v-col cols="3">
                                <span class="label">Data da Cédula</span>
                                {{new Date(fiscalizacao.financiamento.dataCedula).toLocaleDateString()}}
                            </v-col>
                            <v-col cols="3">
                                <span class="label">Data Vencimento</span>
                                {{new Date(fiscalizacao.financiamento.dataVencimento).toLocaleDateString()}}
                            </v-col>
                        </v-row>
                        <v-row no-gutters>
                            <v-col>
                                <span class="label" style="">Agencia <v-chip v-if="fiscalizacao.financiamento.agencia && fiscalizacao.financiamento.agencia.fechadaEm" x-small color="red" label dark class="px-1">FECHADA</v-chip></span>
                                <router-link :to="{ name: 'agencia_view', params: { id: parseInt(fiscalizacao.financiamento.agencia.id) }}" v-if="fiscalizacao.financiamento.agencia">
                                    {{fiscalizacao.financiamento.agencia.nome}}
                                    {{fiscalizacao.financiamento.agencia.codigo | codigo}}
                                </router-link>
                                <v-btn v-else color="orange"
                                       dark  x-small rounded depressed
                                       @click="openAlteraAgenciaDialog">
                                    <v-icon left small>mdi-alert-circle-outline</v-icon>
                                    Definir Agência
                                </v-btn>

                            </v-col>
                        </v-row>
                        <v-row no-gutters>
                            <v-col cols="6">
                                <span class="label">Finalidade</span>
                                {{fiscalizacao.financiamento.finalidade.descricao}}
                                {{fiscalizacao.financiamento.finalidade.codigo | codigo}}
                            </v-col>
                            <v-col cols="6">
                                <span class="label">Empreendimento</span>
                                {{fiscalizacao.financiamento.empreendimento.descricao}}
                                {{fiscalizacao.financiamento.empreendimento.codigo | codigo}}
                            </v-col>
                        </v-row>
                        <v-row no-gutters>
                            <v-col cols="6">
                                <span class="label">Loc. Das Garantias</span>
                                {{fiscalizacao.financiamento.localGarantias}}
                            </v-col>
                        </v-row>

                        <v-row no-gutters>
                            <v-col cols="6">
                                <span class="label">Origem dos Recursos</span>
                                <span v-if="fiscalizacao.financiamento.origemRecursos">
                                    {{fiscalizacao.financiamento.origemRecursos.descricao}}
                                    {{fiscalizacao.financiamento.origemRecursos.codigo | codigo}}
                                </span>
                                <span v-else class="orange--text font-weight-regular"><v-icon class="orange--text" left style="font-size: 20px">warning</v-icon>Não informado</span>
                            </v-col>
                        </v-row>
                    </v-card>

                    <area-fragment :fiscalizacao="fiscalizacao" :finalizado="finalizado" />

                    <objetos-financiados-fragment class="mt-6" :fiscalizacao="fiscalizacao" :finalizado="finalizado"/>

                    <garantias-financiamento-fragment class="mt-6" :fiscalizacao="fiscalizacao" :finalizado="finalizado"/>

                    <registro-fotografico-fragment class="mt-6" :fiscalizacao="fiscalizacao" :finalizado="finalizado" />

                    <v-btn block large color="primary" class="mt-6" @click="finalizarFiscalizacao" :disabled="finalizado">Finalizar</v-btn>
                </v-col>
                <v-col cols="3">
                    <v-card flat class="fill-height" style="background-color: whitesmoke">
                        <v-card-text class="pa-0">
                            <nota-fiscal-fragment :fiscalizacao="fiscalizacao" />
                            <v-divider></v-divider>
                            <anexos-fragment :fiscalizacao="fiscalizacao" :finalizado="false" />
                            <v-divider></v-divider>
                            <contatos-fragment :fiscalizacao="fiscalizacao" />
                            <v-divider></v-divider>
                            <emails-fragment :fiscalizacao="fiscalizacao" :finalizado="finalizado" />
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </div>

        <new-dialog ref="generalInfoDialog" @updated="getFiscalizacao" />
        <finalizar-dialog ref="finalizarDialog" @finalized="getFiscalizacao" />
        <altera-tipo-dialog ref="alteraTipoDialog" @saved="onTipoSaved" />
        <altera-agencia-dialog ref="alteraAgenciaDialog" @saved="onAgenciaSaved" />
        <solicitar-prorrogacao-dialog ref="solicitarProrrogacaoDialog" @sent="onSolicitacaoEnviada" />
        <solicitacoes-prorrogacao-historio-dialog ref="solicitacoesProrrogacaoHistoricoDialog" />
        <cancelar-fiscalizacao-dialog ref="cancelarFiscalizacaoDialog" @sent="onCancelamentoConcluido" />
    </page>
</template>

<script>
    // @ts-ignore
    import page from '../../../components/Page';
    // @ts-ignore
    import newDialog from './NewDialog';
    // @ts-ignore
    import FinalizarDialog from './FinalizarDialog';
    import numeral from "numeral";
    // @ts-ignore
    import AnexosFragment from "./fragments/view/AnexosFragment";
    // @ts-ignore
    import ContatosFragment from "./fragments/view/ContatosFragment";
    // @ts-ignore
    import EmailsFragment from "./fragments/view/EmailsFragment";
    // @ts-ignore
    import AreaFragment from "./fragments/view/AreaFragment";
    // @ts-ignore
    import ObjetosFinanciadosFragment from "./fragments/view/ObjetosFinanciadosFragment";
    // @ts-ignore
    import GarantiasFinanciamentoFragment from "./fragments/view/GarantiasFinanciamentoFragment";
    // @ts-ignore
    import RegistroFotograficoFragment from "./fragments/view/RegistroFotograficoFragment";
    import {FiscalizacaoQueries} from '@/queries/FiscalizacaoQueries';
    // @ts-ignore
    import NotaFiscalFragment from "./fragments/view/NotaFiscalFragment";
    // @ts-ignore
    import AlteraTipoDialog from "./AlteraTipoDialog";
    // @ts-ignore
    import AlteraAgenciaDialog from "./AlteraAgenciaDialog";
    // @ts-ignore
    import LembretesFragment from "./fragments/view/LembretesFragment";
    import gql from "graphql-tag";
    import SolicitarProrrogacaoDialog from "@/views/servico/fiscalizacao/SolicitarProrrogacaoDialog";
    import SolicitacoesProrrogacaoFragment
        from "@/views/servico/fiscalizacao/fragments/view/SolicitacoesProrrogacaoFragment";
    import SolicitacoesProrrogacaoHistorioDialog
        from "@/views/servico/fiscalizacao/SolicitacoesProrrogacaoHistorioDialog";
    import {getFinalizadoClass} from "@/assets/FiscaizacaoUtil";
    import CancelarFiscalizacaoDialog from "./CancelarFiscalizacaoDialog";
    import UsuarioAvatar from "@/components/UsuarioAvatar";
    export default {
        name: 'ViewFiscalizacao',
        components: {
            CancelarFiscalizacaoDialog,
            SolicitacoesProrrogacaoHistorioDialog,
            SolicitacoesProrrogacaoFragment,
            SolicitarProrrogacaoDialog,
            LembretesFragment,
            AlteraAgenciaDialog,
            AlteraTipoDialog,
            NotaFiscalFragment,
            RegistroFotograficoFragment,
            GarantiasFinanciamentoFragment,
            ObjetosFinanciadosFragment,
            AreaFragment,
            EmailsFragment,
            ContatosFragment,
            AnexosFragment,
            FinalizarDialog,
            UsuarioAvatar,
            newDialog,
            page,
        },
        filters: {
            codigo(value){
                if(value){
                    return '(' + value + ')';
                }
                return '';
            }
        },
        computed: {
            finalizado() {
                return this.fiscalizacao.dataFiscalizacao != null;
                //return false;
            },
        },
        data() {
            return {
                fiscalizacao: null,
                downloadingXls: false,
                painelAnexoAberto: null,
            };
        },
        watch:{
            '$route.params.id'(id){
                //this.getFiscalizacao();
                location.reload();
            }
        },
        methods: {
            getFinalizadoClass(fiscalizacao){
                return getFinalizadoClass(fiscalizacao);
            },
            formatarNumero(value){
                return numeral(value).format('0,0.00');
            },
            getFiscalizacao() {
                const id = this.$route.params.id;
                this.$apollo.query({
                    query: FiscalizacaoQueries.getById,
                    variables: {id},
                }).then((result) => {
                    this.fiscalizacao = result.data.fiscalizacao;
                    document.title = this.fiscalizacao.financiamento.mutuario + " - JF Assessoria Rural"
                });
            },
            openAlteraTipoDialog(){
                this.$refs.alteraTipoDialog.openDialog(this.fiscalizacao.id);
            },
            onTipoSaved(tipo){
                this.fiscalizacao.tipo = tipo;
            },
            openAlteraAgenciaDialog(){
                this.$refs.alteraAgenciaDialog.openDialog(this.fiscalizacao.id);
            },
            onAgenciaSaved(agencia){
                this.fiscalizacao.financiamento.agencia = agencia;
            },
            onSolicitacaoEnviada(solicitacaoProrrogacao){
                this.$set(this.fiscalizacao.solicitacoesProrrogacao, this.fiscalizacao.solicitacoesProrrogacao.length, solicitacaoProrrogacao)
            },
            onCancelamentoConcluido(cancelarFiscalizacao){
                console.log("onCancelamentoConcluido", cancelarFiscalizacao)
                //this.$set(this.fiscalizacao, {}, cancelarFiscalizacao)
                Object.assign(this.fiscalizacao, cancelarFiscalizacao)
                console.log(this.fiscalizacao)
            },
            finalizarFiscalizacao() {
                if (this.fiscalizacao.financiamento.agencia == null) {
                    this.$dialog.warning({
                        text: 'Defina uma agência antes de finalizar',
                        title: 'Atenção',
                        actions: {text: "ok"}
                    });
                    return
                }

                if (this.fiscalizacao.financiamento.area.localizacao == null
                    || this.fiscalizacao.financiamento.area.localizacao.cidade == null) {
                    this.$dialog.warning({
                        text: 'Defina um município primeiro antes de finalizar',
                        title: 'Atenção',
                        actions: {text: "ok"}
                    });
                    return
                }

                if(this.fiscalizacao.financiamento.objetos.length === 0){
                    this.$dialog.warning({
                        text: 'Adicione objetos financiados.',
                        title: 'Atenção',
                        actions: {text: "ok"}
                    });
                    return
                }

                if(this.fiscalizacao.financiamento.garantias.length === 0){
                    this.$dialog.warning({
                        text: 'Adicione as garantias de financiamento.',
                        title: 'Atenção',
                        actions: {text: "ok"}
                    });
                    return
                }

                if(this.fiscalizacao.fotos.length === 0){
                    this.$dialog.confirm({
                        text: 'A fiscalização está sem fotos. Deseja continuar mesmo assim?',
                        title: 'Atenção',
                    }).then((result) => {
                        if (result) {
                            this.$refs.finalizarDialog.openDialog(this.fiscalizacao, this.fiscalizacaoVencida());
                        }
                    });
                    return
                }

                this.$refs.finalizarDialog.openDialog(this.fiscalizacao, this.fiscalizacaoVencida());
            },
            desfinalizarFiscalizacao(id){
                this.$dialog.confirm({
                    text: 'Tem certeza que deseja desfinalizar essa fiscalização',
                    title: 'Atenção',
                }).then((result) => {
                    if (result) {
                        if(this.fiscalizacao.servico.notaFiscal){
                            this.$dialog.confirm({
                                text: 'Uma nota fiscal já foi gerada para esta fiscalização e não será gerada outra. Deseja continuar mesmo assim?',
                                title: 'Atenção',
                            }).then((result) => {
                                if (result) {
                                    this.$apollo.mutate({
                                        mutation: gql`mutation($id:Int!) {
                                            desfinalizarFiscalizacao(id:$id )
                                        }`,
                                        variables:{
                                            id
                                        }
                                    }).then((result) => {
                                        this.getFiscalizacao()
                                    })
                                }
                            })
                        }else{
                            this.$apollo.mutate({
                                mutation: gql`mutation($id:Int!) {
                                            desfinalizarFiscalizacao(id:$id )
                                        }`,
                                variables:{
                                    id
                                }
                            }).then((result) => {
                                this.getFiscalizacao()
                            })
                        }

                    }
                });
            },
            atualizarInformacoesPeloLaudo(id){
                //verificar se tem o laudo
                let temLaudo = this.fiscalizacao.arquivos.some(arquivo => arquivo.tags.some(tag => tag.id === 4))
                if(!temLaudo){
                    this.$dialog.error({
                        text: 'É necessário um laudo para continuar.',
                        title: 'Ops',
                    });
                    return
                }

                this.$dialog.confirm({
                    text: 'As informações do laudo irão substituir as já cadastradas no sistema. Deseja continuar mesmo assim?',
                    title: 'Atenção',
                }).then((result) => {
                    if (result) {
                        this.$apollo.mutate({
                            mutation: gql`mutation($id:Int!) {
                                atualizaFiscalizacaoPorLaudo(fiscalizacaoId:$id ){
                                    id
                                }
                            }`,
                            variables:{
                                id
                            }
                        }).then((result) => {
                            this.getFiscalizacao()
                        })
                    }
                });
            },
            cancelarFiscalizacao(id){
                if(this.fiscalizacao.envioBancoSis){
                    this.$dialog.error({
                        text: 'Não é possível cancelar uma fiscalização que já foi enviada para o banco.',
                        title: 'Erro',
                        actions: {text: "ok"}
                    });
                }else{
                    this.$refs.cancelarFiscalizacaoDialog.openDialog(this.fiscalizacao.id);
                }
            },
            fiscalizacaoVencida(){
                return new Date(this.fiscalizacao.dataArquivoBanco) > new Date(this.fiscalizacao.dataLimite);
            },
            editGeneralInfoDialog() {
                this.$refs.generalInfoDialog.openDialog(this.fiscalizacao);
            },
            getCaminhoImageUrl(fiscalizacao){
                let pontoPartida = fiscalizacao.pontoPartida.cidade.nome+"/"+fiscalizacao.pontoPartida.cidade.estado.nome;
                let destino = fiscalizacao.financiamento.area.localizacao.cidade.nome + "/" + fiscalizacao.financiamento.area.localizacao.cidade.estado.nome
                let url = 'https://maps.googleapis.com/maps/api/staticmap?key=AIzaSyDVmydqrR66sMvCjiFRy9yTXo3KMzJuQIw'
                url += '&size=420x250'
                url += '&markers=label:A|'+ pontoPartida
                url += '&markers=label:B|'+ destino
                url += '&path=weight:4%7Cenc:' + fiscalizacao.caminhoPolyline
                console.log("url", url)
                return url;
            },
            downloadLaudo(tipo) {
                if(!this.finalizado){
                    this.$dialog.warning({
                        text: 'Finalize a fiscalização primeiro para poder baixar o laudo.',
                        title: 'Atenção',
                        actions: {text: "ok"}
                    });
                    return;
                }
                this.downloadingXls = true;
                this.$axios.get('/fiscalizacoes/' + this.fiscalizacao.id + '/' + tipo, {responseType: 'blob'}).then((response) => {
                    const fileLink = document.createElement('a');
                    fileLink.href = window.URL.createObjectURL(new Blob([response.data]));
                    const fileName = response.headers['content-disposition'].split('filename=')[1];
                    fileLink.setAttribute('download', fileName);
                    document.body.appendChild(fileLink);
                    fileLink.click();
                    document.body.removeChild(fileLink);
                    this.downloadingXls = false;
                }).catch(() => {
                    this.downloadingXls = false;
                    this.$dialog.error({
                        text: 'Não foi possível baixar o arquivo. Contate o desenvolvedor.',
                        title: 'Ops',
                    });

                });
            },
        },
        mounted() {
            numeral.locale('pt-br');
            this.getFiscalizacao(this.$route.params.id);
        },
    };
</script>

<style scoped lang="scss">
    .label{
        color: #616161 !important;
        caret-color: #616161 !important;

        font-size: 0.75rem !important;
        font-weight: 400;
        letter-spacing: 0.0333333333em !important;
        line-height: 1.25rem;

        display: block !important;
    }
    .v-list-item-finalizado::after{
        width: 6px;
        content: "";
        display: inline-block;
        height: 62px;
        margin-right: -16px;
        margin-left: 16px;
    }
    .finalizado-regular::after{
        background-color: #4CAF50;
    }
    .finalizado-irregular::after{
        background-color: #f31b1b;
    }

</style>
