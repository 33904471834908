<template>
    <v-dialog scrollable v-model="dialog" persistent max-width="1000px">
        <v-card v-if="dialog">
            <v-card-title>
                <span class="headline">Solicitar Prorrogação</span>
            </v-card-title>
            <v-card-text>
                <v-alert type="warning" outlined text dense v-if="fiscalizacao.solicitacoesProrrogacao.find((s) => s.dataLimiteDefinida !== null)">
                    <v-row no-gutters align="center">
                        <v-col class="grow">
                            Essa fiscalização já foi prorrogada anteriormente.
                        </v-col>
                        <v-col class="shrink">
                            <v-btn small
                                   @click="$refs.solicitacoesProrrogacaoHistoricoDialog.openDialog(fiscalizacao.solicitacoesProrrogacao)">Ver histórico</v-btn>
                        </v-col>
                    </v-row>
                </v-alert>
                <v-row no-gutters>
                    <v-col>
                        <v-form ref="form1" v-model="form1Valid" lazy-validation>
                        <v-row >
                            <v-col cols="7" class="py-0">
                                <v-textarea v-model="justificativa"
                                            placeholder=" "
                                            label="Justificativa"
                                            rows="1" no-resize hide-details
                                            :rules="requiredRules" required/>
                            </v-col>
                            <v-col cols="2" class="py-0">
                                    <v-menu v-model="dataLimiteSugeridaMenu"
                                            :close-on-content-click="false"
                                            transition="scale-transition"
                                            offset-y
                                            max-width="290px">
                                        <template v-slot:activator="{ on }">
                                            <v-text-field
                                                v-model="dataLimiteSugeridaFormated"
                                                label="Data Sugerida" placeholder=" "
                                                readonly hide-details clearable
                                                v-on="on"
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker v-model="dataLimiteSugerida"
                                                       no-title :min="$moment(new Date()).format('YYYY-MM-DD')"
                                                       locale="pt-br" required
                                                       @input="dataLimiteSugeridaMenu = false">
                                        </v-date-picker>
                                    </v-menu>
                            </v-col>
                            <v-col cols="3" class="caption py-0">
                                <span>Será enviado para:</span><br/>
                                alessandra.r.andrade@bradesco.com.br
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <div class="overflow-y-auto pa-4 email-preview" style="max-height: 320px">
                                    <email-solicitacao-prorrogacao-template :fiscalizacao="fiscalizacao" :data-limite-sugerida="dataLimiteSugerida" :justificativa="justificativa" />
                                </div>
                            </v-col>
                        </v-row>
                        </v-form>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <div class="pa-3" style="width: 100%">
                    <v-btn text @click="closeDialog">Cancelar</v-btn>

                    <div class="float-right">
                        <v-btn color="primary" text depressed @click="enviar" >
                            Enviar
                        </v-btn>
                    </div>

                </div>
            </v-card-actions>
            <v-overlay absolute :value="loading" color="white">
                <v-progress-circular :size="100" :width="7" color="deep-orange" indeterminate></v-progress-circular>
            </v-overlay>
        </v-card>
        <solicitacoes-prorrogacao-historio-dialog ref="solicitacoesProrrogacaoHistoricoDialog" />
    </v-dialog>
</template>

<script>
import EmailSolicitacaoProrrogacaoTemplate
    from "@/views/servico/fiscalizacao/fragments/email_templates/EmailSolicitacaoProrrogacaoTemplate";
import {FiscalizacaoQueries} from "@/queries/FiscalizacaoQueries";
import gql from "graphql-tag";
import SolicitacoesProrrogacaoHistorioDialog from "@/views/servico/fiscalizacao/SolicitacoesProrrogacaoHistorioDialog";
export default {
    name: "SolicitarProrrogacaoDialog",
    components: {SolicitacoesProrrogacaoHistorioDialog, EmailSolicitacaoProrrogacaoTemplate},
    data() {
        return {
            dialog: false,
            loading: false,
            form1Valid: true,
            dataLimiteSugerida: null,
            dataLimiteSugeridaMenu: false,
            justificativa: null,
            fiscalizacao: null,
            requiredRules: [
                (v) => !!v || 'Campo obrigatório',
            ],
        }
    },
    computed: {
        dataLimiteSugeridaFormated: {
            get() {
                if(!this.dataLimiteSugerida){
                    return '';
                }
                return this.$moment(this.dataLimiteSugerida).format('DD/MM/YYYY');
            },
            set(newValue) {
                this.dataLimiteSugerida = newValue;
            },
        },
    },
    methods: {
        openDialog(fiscalizacao) {
            this.dialog = true;
            this.fiscalizacao = fiscalizacao;
            this.dataLimiteSugerida = null;
            this.justificativa = null;
        },
        closeDialog() {
            this.dialog = false;
        },
        enviar(){
            if(!this.$refs.form1.validate()){
                return false
            }
            this.loading = true;
            this.$apollo.mutate({
                mutation: gql`mutation ($id: Int!, $justificativa: String!, $dataSugerida: LocalDate){
                    solicitacaoProrrogacao:enviaEmailSolicitacaoProrrogacao(id: $id, justificativa: $justificativa, dataSugerida: $dataSugerida){
                        id
                        justificativa
                        dataLimiteAnterior
                        dataLimiteSolicitada
                        dataLimiteDefinida
                        emailSolicitacao{
                            enviadoPor{
                                id
                                imagem{
                                    id
                                }
                                nome
                                sobrenome
                            }
                        }
                        createdAt
                    }
                }`,
                variables: {
                    id: this.fiscalizacao.id,
                    justificativa: this.justificativa,
                    dataSugerida: this.dataLimiteSugerida ? new Date(this.dataLimiteSugerida).getTime() : null,
                },
            }).then((result) => {
                this.$notify({text: 'Email enviado com sucesso!', type: 'success'});
                this.$emit('sent', Object.assign({}, result.data.solicitacaoProrrogacao));
                this.loading = false;
                this.closeDialog();
            }).catch((error) => {
                this.$notify({text: 'Não foi possível enviar o email!', type: 'error'});
                this.loading = false;
            });
        }
    }
}
</script>

<style scoped>
.email-preview{
    border: 0.5px solid #b6b6b6;
    background-color: #f9f9f9;
}
</style>