<template>
    <v-dialog scrollable v-model="dialog" persistent max-width="800px">
        <v-card v-if="dialog">
            <v-card-title>
                <span class="headline">Enviar email</span>
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="6">
                        <v-form ref="form1" v-model="form1Valid" lazy-validation>
                            <v-menu v-model="dataLimiteMenu"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                            v-model="dataLimiteFormated"
                                            label="Data limite para entrega dos documentos"
                                            readonly hide-details
                                            v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker v-model="dataLimite"
                                               no-title :min="$moment(new Date()).format('YYYY-MM-DD')"
                                               locale="pt-br" required
                                               @input="dataLimiteMenu = false">
                                </v-date-picker>
                            </v-menu>
                        </v-form>
                    </v-col>
                    <v-col cols="6" class="caption">
                        <span>Será enviado para:</span><br/>
                        {{codigoAgencia}}.adm@bradesco.com.br<br/>
                        {{codigoAgencia}}.gerencia@bradesco.com.br

                        <v-tooltip bottom v-if="agenciaContatosEmails.length > 0">
                            <template v-slot:activator="{ on, attrs }">
                                <v-chip v-on="on" v-bind="attrs" label color="green" x-small dark class="px-2 ml-2">
                                    + {{agenciaContatosEmails.length}}
                                </v-chip>
                            </template>
                            <div>
                                <template v-for="email of agenciaContatosEmails">
                                    <span class="d-block caption" :key="email">{{email}}</span>
                                </template>
                            </div>
                        </v-tooltip>
                    </v-col>
                </v-row>
                <v-row>
                    <div class="overflow-y-auto pa-4 email-preview" style="max-height: 400px">
                        <email-solicitao-documento-template :fiscalizacao="fiscalizacao" :data-limite="dataLimite" />
                    </div>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <div class="pa-3" style="width: 100%">
                    <v-btn text @click="closeDialog">Cancelar</v-btn>

                    <div class="float-right">
                        <v-btn color="primary" text depressed @click="enviar" >
                            Enviar
                        </v-btn>
                    </div>

                </div>
            </v-card-actions>
            <v-overlay absolute :value="loading" color="white">
                <v-progress-circular :size="100" :width="7" color="deep-orange" indeterminate></v-progress-circular>
            </v-overlay>
        </v-card>
    </v-dialog>
</template>

<script>
    import {FiscalizacaoQueries} from '../../../queries/FiscalizacaoQueries';
    // @ts-ignore
    import EmailSolicitaoDocumentoTemplate from "./fragments/email_templates/EmailSolicitacaoDocumentoTemplate";
    import gql from "graphql-tag";
    export default {
        name: "EnviarEmailDialog",
        components: {EmailSolicitaoDocumentoTemplate},
        computed:{
            dataLimiteFormated: {
                get() {
                    return this.$moment(this.dataLimite).format('DD/MM/YYYY');
                },
                set(newValue) {
                    if (newValue) {
                        this.dataLimite = newValue;
                    }
                },
            },
            codigoAgencia(){
                let codigo = this.fiscalizacao.financiamento.agencia.codigo;
                if(codigo.length === 3){
                    codigo = '0' + codigo;
                }
                return codigo;
            },
        },
        data() {
            return {
                dialog: false,
                dataLimite: null,
                dataLimiteMenu: false,
                fiscalizacao: null,
                loading: false,
                form1Valid: true,
                agenciaContatosEmails: [],
            }
        },
        methods: {
            openDialog(fiscalizacao) {
                this.dialog = true;
                this.fiscalizacao = fiscalizacao;
                this.dataLimite = this.getDataLimite();
                this.getEmailsByAgencia();
            },
            closeDialog() {
                this.dialog = false;
            },
            getEmailsByAgencia(){
                this.loading = true;
                this.$apollo.query({
                    query: gql`query ($id: Int!){
                        agenciaContatoEmailsByAgencia(id: $id)
                    }`,
                    variables: {
                        id: this.fiscalizacao.financiamento.agencia.id,
                    },
                }).then((result) => {
                    this.agenciaContatosEmails = result.data.agenciaContatoEmailsByAgencia;
                }).finally(() => {
                    this.loading = false;
                })
            },
            getDataLimite(){
                const daysToAdd = 6;
                let date = this.$moment(new Date());
                date = this.$moment(date.businessAdd(daysToAdd)._d);

                return date.format('YYYY-MM-DD');
            },
            enviar(){
                if(!this.$refs.form1.validate()){
                    return false
                }
                this.loading = true;
                this.$apollo.mutate({
                    mutation: FiscalizacaoQueries.enviarEmailAgencia,
                    variables: {
                        id: this.fiscalizacao.id,
                        dataLimite: new Date(this.dataLimite).getTime()
                    },
                }).then((result) => {
                    this.$notify({text: 'Email enviado com sucesso!', type: 'success'});
                    this.$emit('sent', Object.assign({}, result.data.emailHistorico));
                    this.loading = false;
                    this.closeDialog();
                }).catch((error) => {
                    this.$notify({text: 'Não foi possível enviar o email!', type: 'error'});
                    this.loading = false;
                });
            },
        }
    }
</script>

<style scoped>
    .email-preview{
        border: 0.5px solid #b6b6b6;
        background-color: #f9f9f9;
    }
</style>