<template>
    <v-dialog v-model="dialog" v-if="dialog" width="500">
        <v-card>
            <v-card-title>
                <span class="headline">{{editMode ? 'Editar ' : 'Novo '}} Garantia</span>
            </v-card-title>
            <v-card-text>
                <v-btn v-if="!editMode && garantias.length > 0" small rounded color="primary" @click="copiarGarantia">Copiar Anterior</v-btn>
                <v-form ref="formGarantia" v-model="formGarantia" lazy-validation>
                    <v-row>
                        <v-col cols="12">
                            <v-row>
                                <v-col cols="10" class="py-0">
                                    <v-autocomplete v-model="garantia.descricao" label="Descricão"
                                                    placeholder=" " :items="descricoes"
                                                    item-text="descricao" return-object clearable
                                                    :rules="requiredRules"></v-autocomplete>
                                </v-col>
                                <v-col cols="2" class="py-0 align-self-center">
                                    <v-btn text icon color="primary" @click="newDescricaoDialog">
                                        <v-icon>mdi-plus</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12">
                            <v-row class="">
                                <v-col cols="10" class="py-0">
                                    <v-autocomplete v-model="garantia.qualificacao" label="Qualificação"
                                                    placeholder=" " :items="qualificacoes"
                                                    item-text="descricao" return-object clearable
                                                    ></v-autocomplete>
                                </v-col>
                                <v-col cols="2" class="py-0 align-self-center">
                                    <v-btn text icon color="primary" @click="newQualificacaoDialog">
                                        <v-icon>mdi-plus</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="3">
                            <v-text-field label="Quantidade" placeholder=" " ref="quantidade"
                                          v-model="garantia.quantidade"
                                          v-money="quant" clearable @click:clear="clearQuantidade"
                                          :rules="requiredRules" required></v-text-field>
                        </v-col>

                        <v-col cols="4">
                            <v-select label="Un. Medida" placeholder=" "
                                      v-model="garantia.quantidadeUnidadeMedida" clearable
                                      return-object :items="unidadesMedida" item-text="sigla"
                                      ></v-select>
                        </v-col>

                        <v-col cols="5">
                            <v-text-field label="Valor" prefix="R$" placeholder=" " ref="valor"
                                          v-model="garantia.valor"
                                          v-money="money" clearable @click:clear="clearValor"
                                          :rules="requiredRules" required></v-text-field>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <div class="pa-3" style="width: 100%">
                    <v-btn text @click="cancel">Cancelar</v-btn>

                    <div class="float-right">
                        <v-btn color="primary" text @click="save">Salvar</v-btn>
                    </div>

                </div>
            </v-card-actions>
        </v-card>

        <descricao-dialog ref="descricaoDialog" @saved="onDescricaoSaved" />
        <qualificacao-dialog ref="qualificacaoDialog" @saved="onQualificacaoSaved" />
    </v-dialog>
</template>

<script>
    // @ts-ignore
    import DescricaoDialog from './DescricaoDialog';
    // @ts-ignore
    import QualificacaoDialog from './QualificacaoDialog';
    import {GarantiaDescricaoQueries} from '../../../../queries/GarantiaDescricaoQueries';
    import {GarantiaQualificacaoQueries} from '../../../../queries/GarantiaQualificacaoQueries';
    import {FinanciamentoGarantiaQueries} from '../../../../queries/FinanciamentoGarantiaQueries';
    import {FinanciamentoGarantia} from '../../../../models/FinanciamentoGarantia';
    import {UnidadeMedidaQuieries} from '../../../../queries/UnidadeMedidaQuieries';
    import numeral from "numeral";
    export default {
        name: 'GarantiaDialog',
        components: {QualificacaoDialog, DescricaoDialog},
        props:{
            garantias: null,
        },
        data() {
            return {
                dialog: false,
                editMode: false,
                formGarantia: true,
                requiredRules: [
                    (v) => !!v || 'Campo obrigatório',
                ],
                descricoes: [],
                qualificacoes: [],
                unidadesMedida: [],
                garantia: new FinanciamentoGarantia(),
                currentFinanciamento: null,
                money: {
                    decimal: ',',
                    thousands: '.',
                    precision: 2,
                    masked: false /* doesn't work with directive */
                },
                quant: {
                    decimal: ',',
                    thousands: '.',
                    precision: 2,
                    masked: false /* doesn't work with directive */
                }
            };
        },
        methods: {
            openDialog(financiamento, garantia) {
                this.dialog = true;
                this.currentFinanciamento = financiamento;
                this.garantia = new FinanciamentoGarantia();
                this.getDescricoes();
                this.getQualificacoes();
                this.getUnidadesMedida();
                if (garantia) {
                    this.editMode = true;
                    this.garantia = this._.cloneDeep(garantia);
                    this.fixNumeros();

                    if(this.garantia.descricao && this.garantia.descricao.codigo){
                        this.garantia.descricao.descricao = this.garantia.descricao.codigo + ' - ' + this.garantia.descricao.descricao;
                    }
                }
            },
            closeDialog() {
                this.dialog = false;
                this.editMode = false;
                this.objeto = null;
                this.$refs.formGarantia.resetValidation();
            },
            getDescricoes() {
                this.$apollo.query({query: GarantiaDescricaoQueries.getAll}).then((result) => {
                    this.descricoes = result.data.garantiaDescricoes.map((descricao) => {
                        if(descricao.codigo){
                            descricao.descricao = descricao.codigo + " - " + descricao.descricao;
                        }
                        return descricao;
                    });
                });
            },
            getQualificacoes() {
                this.$apollo.query({query: GarantiaQualificacaoQueries.getAll}).then((result) => {
                    this.qualificacoes = result.data.garantiaQualificacoes.map((qualificacao) => {
                        if(qualificacao.codigo){
                            qualificacao.descricao = qualificacao.codigo + " - " + qualificacao.descricao;
                        }
                        return qualificacao;
                    });
                });
            },
            getUnidadesMedida() {
                this.$apollo.query({query: UnidadeMedidaQuieries.getAll}).then((result) => {
                    this.unidadesMedida = result.data.unidadesMedida;
                    //this.unidadesMedida.push({})
                });
            },
            copiarGarantia(){
                this.garantia = this._.cloneDeep(this.garantias[this.garantias.length - 1]);
                delete this.garantia.id;

                if(this.garantia.descricao && this.garantia.descricao.codigo){
                    this.garantia.descricao.descricao = this.garantia.descricao.codigo + ' - ' + this.garantia.descricao.descricao;
                }

                this.fixNumeros();
                this.$refs.quantidade.$el.getElementsByTagName('input')[0].value = this.garantia.quantidade;
                this.$refs.quantidade.$el.getElementsByTagName('input')[0].dispatchEvent(new Event('input'));
                this.$refs.valor.$el.getElementsByTagName('input')[0].value = this.garantia.valor;
                this.$refs.valor.$el.getElementsByTagName('input')[0].dispatchEvent(new Event('input'));
            },
            fixNumeros(){
                this.garantia.quantidade = numeral(this.garantia.quantidade).format('0.00').replace(',','.');
                this.garantia.valor = numeral(this.garantia.valor).format('0.00').replace(',','.');
            },
            clearQuantidade(){
                this.$refs.quantidade.$el.getElementsByTagName('input')[0].value = 0;
                this.$refs.quantidade.$el.getElementsByTagName('input')[0].dispatchEvent(new Event('input'));
            },
            clearValor(){
                this.$refs.valor.$el.getElementsByTagName('input')[0].value = 0;
                this.$refs.valor.$el.getElementsByTagName('input')[0].dispatchEvent(new Event('input'));
            },
            save() {
                if (this.$refs.formGarantia.validate()) {
                    const garantiaParsed = this._.cloneDeep(this.garantia);
                    garantiaParsed.valor = this.convertMoneyToFloat(garantiaParsed.valor);
                    garantiaParsed.quantidade = this.convertMoneyToFloat(garantiaParsed.quantidade);
                    delete garantiaParsed.localizacao;
                    delete garantiaParsed.index;

                    if(garantiaParsed.qualificacao === undefined){
                        delete garantiaParsed.qualificacao;
                    }

                    if (!this.editMode) {
                        this.$apollo.mutate({
                            mutation: FinanciamentoGarantiaQueries.save,
                            variables: {
                                financiamentoId: this.currentFinanciamento.id,
                                financiamentoGatantia: garantiaParsed,
                            },
                        }).then((result) => {
                            const garantia = Object.assign({}, result.data.saveFinanciamentoGarantia);
                            this.$emit(this.editMode ? 'edited' : 'saved', garantia);
                            this.closeDialog();
                        });
                    } else {
                        this.$apollo.mutate({
                            mutation: FinanciamentoGarantiaQueries.update,
                            variables: {
                                id: garantiaParsed.id,
                                financiamentoGarantia: garantiaParsed,
                            },
                        }).then((result) => {
                            this.$emit('edited', Object.assign({}, result.data.updateFinanciamentoGarantia), this.garantia.index);
                            this.closeDialog();
                        });
                    }
                }
            },
            cancel() {
                this.closeDialog();
            },
            newDescricaoDialog() {
                this.$refs.descricaoDialog.openDialog();
            },
            onDescricaoSaved(descricao) {
                this.descricoes.push(descricao);
                this.garantia.descricao = descricao;
            },
            newQualificacaoDialog() {
                this.$refs.qualificacaoDialog.openDialog();
            },
            onQualificacaoSaved(qualificacao) {
                this.qualificacoes.push(qualificacao);
                this.garantia.qualificacao = qualificacao;
            },
            convertMoneyToFloat(value) {
                if (!isNaN(value)) {
                    return value;
                }
                return parseFloat(value.replace(/\./g, '').replace(',', '.'));
            },
        },
    };
</script>

<style scoped>

</style>
