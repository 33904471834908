<template>
    <v-dialog v-model="dialog" fullscreen hide-overlay
              transition="dialog-bottom-transition"
               content-class="dialog-no-scroll  ">
        <v-card class=""  >
            <v-toolbar color="#81c784">
                <v-btn icon color="#6d4c41" @click="closeDialog">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title style="color: #6d4c41">Email</v-toolbar-title>
                <v-spacer></v-spacer>
                <!--<v-toolbar-items>
                    <v-btn dark text @click="dialog = false">
                        Save
                    </v-btn>
                </v-toolbar-items>-->
            </v-toolbar>
            <v-row no-gutters style="height: calc(100vh - 64px);">
                <v-col class="shrink fill-height" style="border-right: 1px solid #e5e5e5;" >
                    <div class="fill-height overflow-y-auto">
                        <v-list two-line max-width="500px">
                            <v-list-item-group mandatory v-model="selectedMessage" active-class="green--text">
                                <template v-for="(message, index) in messages">
                                    <v-list-item @click="">
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                {{message.subject}}
                                            </v-list-item-title>
                                            <v-list-item-subtitle>
                                                {{message.snippet}}
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-action>
                                            <v-list-item-action-text>{{message.date}}</v-list-item-action-text>

                                            <div v-if="message.attachments.length > 0">
                                                <v-icon>mdi-attachment</v-icon>
                                                <span class="caption">{{message.attachments.length}}</span>
                                            </div>
                                        </v-list-item-action>
                                    </v-list-item>
                                </template>
                            </v-list-item-group>

                        </v-list>
                    </div>
                </v-col>
                <v-col class="overflow-y-auto fill-height email-content">
                    <template v-if="payload.body">
                        <div class="grey lighten-4 pa-4" >
                            <span class="subtitle-1 d-block font-weight-medium">{{payload.subject}}</span>
                            <v-row no-gutters>
                                <v-col>
                                    <span class="d-block">de: {{payload.from}}</span>
                                    <span class="d-block">para: {{payload.to}}</span>
                                </v-col>
                                <v-col class="shrink">
                                    <span class="caption d-block text-no-wrap">{{payload.date | moment("DD [de] MMM [de] YYYY HH:mm")}}</span>
                                </v-col>
                            </v-row>
                            <div class="mt-2" v-if="messages[selectedMessage].attachments.length > 0">
                                <span class="caption font-weight-medium">{{messages[selectedMessage].attachments.length}} anexos</span>
                                <div class="d-flex mt-1">
                                    <template v-for="attachment in messages[selectedMessage].attachments">
                                        <v-menu bottom left offset-y>
                                            <template v-slot:activator="{ on }">
                                                <v-card v-on="on" class="mr-4">
                                                    <v-hover v-slot:default="{ hover }">
                                                        <v-list-item dense class="">
                                                            <v-list-item-avatar>
                                                                <v-icon :style="'opacity: '+(hover?'1':'0.6')"
                                                                        :color="getIconColor(attachment.filename)">
                                                                    {{getIcon(attachment.filename)}}
                                                                </v-icon>
                                                            </v-list-item-avatar>
                                                            <v-list-item-content>
                                                                <v-list-item-title>
                                                                    {{attachment.filename}}
                                                                </v-list-item-title>
                                                                <v-list-item-subtitle >
                                                                    {{bytesToHumanReadable(attachment.body.size)}}
                                                                </v-list-item-subtitle>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                    </v-hover>
                                                    <!--<v-card-text>
                                                        {{attachment.filename}}
                                                    </v-card-text>-->
                                                </v-card>
                                            </template>

                                            <v-list>
                                                <v-list-item @click="downloadAttachment(messages[messages.length - 1].id, messages[selectedMessage].id, attachment)">
                                                    <v-list-item-title>Download</v-list-item-title>
                                                </v-list-item>
                                                <v-list-item @click="viewAttachment(messages[messages.length - 1].id, messages[selectedMessage].id, attachment)">
                                                    <v-list-item-title>Visualizar</v-list-item-title>
                                                </v-list-item>
                                                <v-list-item @click="addAttachmentsToFiscalizacao(messages[messages.length - 1].id, messages[selectedMessage].id, attachment)">
                                                    <v-list-item-title>Adicionar na fiscalização</v-list-item-title>
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>

                                    </template>
                                </div>
                            </div>
                        </div>

                        <div class=" pa-4" v-html="payload.body"></div>
                    </template>
                </v-col>
            </v-row>
            <!--<v-card-actions>
                <div class="pa-3" style="width: 100%">

                    <div class="float-right">
                        <v-btn text color="primary" depressed @click="closeDialog" >
                            Ok
                        </v-btn>
                    </div>

                </div>
            </v-card-actions>-->
            <v-overlay absolute :value="loading" color="white">
                <v-progress-circular :size="100" :width="7" color="deep-orange" indeterminate></v-progress-circular>
            </v-overlay>
        </v-card>
        <definir-arquivos-emails-tags-dialog ref="definirArquivosEmailsTagsDialog" />
    </v-dialog>
</template>

<script>
import {decode} from "base64util"
import {getIcon, getExtensionFile, getIconColor, bytesToHumanReadable} from "../../../assets/FileUtils"
// @ts-ignore
import DefinirArquivosEmailsTagsDialog from "./DefinirArquivosEmailTagsDialog";
export default {
    name: "ViewEmailDialog",
    components: {DefinirArquivosEmailsTagsDialog},
    data() {
        return {
            dialog: false,
            loading: false,
            fiscalizacao: null,
            messages: [],
            selectedMessage: null,
            payload: {
                date: null,
                from: null,
                to: null,
                subject: null,
                body: null,
            }
        }
    },
    watch:{
        selectedMessage(value){
            if(this.messages && this.messages.length > 0){
                this.showEmail(this.messages[value].payload);
            }
        }
    },
    methods: {
        getIcon, getIconColor, getExtensionFile, bytesToHumanReadable,
        openDialog(gmailThreadId, fiscalizacao) {
            this.fiscalizacao = fiscalizacao;
            this.payload = {
                date: null,
                from: null,
                to: null,
                subject: null,
                body: null,
            };
            this.selectedMessage = null;
            this.messages = [];
            this.dialog = true;
            this.getThread(gmailThreadId)
        },
        closeDialog() {
            this.dialog = false;
        },
        getThread(gmailThreadId){
            this.$axios.get("/google/gmail/messages/thread/" + gmailThreadId)
                .then((result) => {
                    this.messages = result.data.messages.map((message) => {
                        return {
                            id: message.id,
                            subject: message.payload.headers.find(header => header.name === "Subject").value,
                            snippet: message.snippet,
                            date: this.$moment(message.internalDate).fromNow(),
                            payload: message.payload,
                            attachments: this.getAttachments(message.payload.parts)
                        }
                    }).reverse()
                })
        },
        showEmail(payload){
            //console.log("showEmail", payload)
            //console.log(result);
            //let body = payload.parts[0].body.data;
            let body = this.getTextHtmlPart(payload.parts).body.data
            this.payload.body = decode(body);
            //console.log(this.payload.body);
            for(let header of payload.headers){
                switch (header.name){
                    case "Subject":
                        this.payload.subject = header.value;
                        break;
                    case "Date":
                        this.payload.date = header.value;
                        break;
                    case "To":
                        this.payload.to = header.value;
                        break;
                    case "From":
                        this.payload.from = header.value;
                        break;
                }
            }
            //console.log("attachments", this.getAttachments(payload.parts))
        },
        /*getEmail(gmailEmailId){
            this.loading = true;
            this.$axios.get("/google/gmail/messages/" + gmailEmailId)
                .then((result) => {
                    console.log(result);
                    let body = result.data.payload.parts[0].body.data;
                    this.payload.body = decode(body);
                    console.log(this.payload.body);
                    for(let header of result.data.payload.headers){
                        switch (header.name){
                            case "Subject":
                                this.payload.subject = header.value;
                                break;
                            case "Date":
                                this.payload.date = header.value;
                                break;
                            case "To":
                                this.payload.to = header.value;
                                break;
                            case "From":
                                this.payload.from = header.value;
                                break;
                        }
                    }
                })
                .finally(() => {
                    this.loading = false;
                })
        },*/
        getAttachments(parts){
            const search = (part) => {
                let header = part.headers.find(header => header.name === "Content-Disposition")
                if (header != null && header.value.startsWith("attachment;")) {
                    if(part.headers.find(header => header.name === "Content-ID") === undefined){
                        return part;
                    }
                }

                if(part.parts){
                    for (const subpart of part.parts) {
                        const res = search(subpart);

                        if (res) {
                            return res;
                        }
                    }
                }
            };

            let attachments = []
            for(let part of parts){
                let result = search(part);
                if(result){
                    attachments.push(result)
                }
            }
            return attachments;
        },
        getTextHtmlPart(parts){
            const search = (part) => {
                if (part.mimeType === "text/html") {
                    return part;
                }

                if(part.parts){

                    for (const subpart of part.parts) {
                        const res = search(subpart);

                        if (res) {
                            return res;
                        }
                    }
                }
            };

            for(let part of parts){
                let result = search(part);
                if(result){
                    return result
                }
            }
        },
        downloadAttachment(originMessageId, messageId, attachment){
            //console.log("attachment", attachment)
            this.$axios.get("/google/gmail/messages/" + originMessageId + "/attachment/" + messageId + "/" + attachment.body.attachmentId, {responseType: 'blob'})
                .then((response) => {
                    const fileLink = document.createElement('a');
                    fileLink.href = window.URL.createObjectURL(new Blob([response.data]));
                    fileLink.setAttribute('download', attachment.filename);
                    document.body.appendChild(fileLink);
                    fileLink.click();
                    document.body.removeChild(fileLink);
                })
        },
        viewAttachment(originMessageId, messageId, attachment){
            this.$axios.get("/google/gmail/messages/" + originMessageId + "/attachment/" + messageId + "/" + attachment.body.attachmentId, {responseType: 'blob'})
                .then((response) => {
                    let options = {
                        type: attachment.mimeType
                    };

                    let blob = new Blob([response.data], options)
                    let fileURL = URL.createObjectURL(blob);
                    window.open(fileURL)
                }).catch(() => {
                    this.$dialog.error({
                        text: 'Não foi possível abrir o arquivo. Contate o desenvolvedor.',
                        title: 'Ops',
                    });

                });
        },
        addAttachmentsToFiscalizacao(originMessageId, messageId, attachment){
            let attachments = [attachment];
            this.$refs.definirArquivosEmailsTagsDialog.openDialog(attachments, this.fiscalizacao, originMessageId, messageId);
        }
    }
}
</script>

<style>
    .dialog-no-scroll{
        overflow: unset;
    }
    .email-content pre{
        white-space: pre-wrap;
    }
</style>