<template>
    <div>
        <v-card outlined class="">
            <v-card-title class="pb-0">
                Registro Fotográfico
                <v-btn rounded small color="primary" class="mt-2 ml-3 mb-2"
                       :loading="imageUploading"
                       :disabled="imageUploading || finalizado || fiscalizacao.dataCancelamento"
                       @click="$refs.inputImageUpload.click()">Adicionar Imagem</v-btn>
                <input v-show="false" ref="inputImageUpload" type="file"
                       accept="image/png, image/jpeg, image/bmp" multiple
                       @change="uploadImageManually" >
                <v-switch v-model="cropImage" label="Tamanho Original" class="ml-2 mt-0" dense hide-details :disabled="fiscalizacao.dataCancelamento"></v-switch>
            </v-card-title>
            <v-card-text class="black--text">
                <div style="position: relative">
                    <div ref="imageDragArea" class="drag-area"></div>
                    <div ref="imagesContainer" v-if="fiscalizacao.fotos.length > 0" class="d-flex flex-wrap">

                        <div class="ma-2" v-for="(foto, index) in fiscalizacao.fotos" :key="foto.id">
                            <v-img :contain="cropImage" aspect-ratio="1.6" class="relatorio-imagem "
                                   style="background-color: #FAFAFA; border: #E0E0E0 solid 0.5px"
                                   :src="$env.VUE_APP_API_ENDPOINT + 'fiscalizacoes/image/' + foto.id + '/s'"
                                   :lazy-src="$env.VUE_APP_API_ENDPOINT + 'fiscalizacoes/image/' + foto.id + '/0'">
                                <template v-slot:placeholder>
                                    <v-row class="fill-height ma-0" align="center" justify="center">
                                        <v-progress-circular indeterminate color="secondary lighten-5"></v-progress-circular>
                                    </v-row>
                                </template>
                                <v-btn color="red" x-small fab elevation="0" class="float-right mr-2 mt-2"
                                       :disabled="finalizado" @click="removeFoto(foto.id, index)">
                                    <v-icon color="white">mdi-delete</v-icon>
                                </v-btn>
                            </v-img>
                        </div>
                    </div>
                    <div v-else  class="image-container-empty">
                        <v-alert color="yellow" dense icon="mdi-alert" class="mt-4 mx-4" style="align-self: center; pointer-events: none">
                            <div class="subtitle-1">
                                Nenhuma foto adicionada. Arraste imagens para esta área ou clique no botão acima.
                            </div>
                        </v-alert>

                    </div>
                </div>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
    import {FiscalizacaoQueries} from "../../../../../queries/FiscalizacaoQueries";

    export default {
        name: "RegistroFotograficoFragment",
        props: {
            fiscalizacao: null,
            finalizado: null,
        },
        data(){
           return {
               imageUploading: false,
               cropImage: false,
           }
        },
        mounted() {
            if(!this.finalizado){
                let self = this;
                this.montaDragAndDrop(this.$refs.imageDragArea, this.$refs.imagesContainer,  function (files) {
                    self.uploadImages(files);
                });
            }
        },
        methods: {
            uploadImageManually(e){
                this.uploadImages(e.target.files)

            },

            uploadImages(files){
                for (let i = 0; i < files.length; i++) {
                    this.uploadImage(files[i])
                }
            },

            uploadImage(file){
                if(this.finalizado){
                    return;
                }
                this.imageUploading = true;
                if(file.size > 5000000){
                    this.$dialog.warning({text: 'Imagem não pode ter tamanho superior a 5 MB!', title: 'Atenção'});
                    return;
                }

                const formData = new FormData();
                formData.append('file', file);
                this.$axios.post('/fiscalizacoes/' + this.fiscalizacao.id + '/uploadImage', formData, {
                    headers: {
                        // @ts-ignore
                        'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
                    },
                }).then((result) => {
                    this.imageUploading = false;
                    this.$notify({text: 'Imagem enviada com sucesso!', type: 'success'});
                    this.fiscalizacao.fotos.push({id: result.data});
                }).catch(() => {
                    this.imageUploading = false;
                    this.$dialog.error({text: 'Falha ao enviar arquivo!', title: 'Erro'});
                });
            },
            removeFoto(id, index) {
                this.$dialog.confirm({
                    text: 'Deseja realmente apagar esta foto?',
                    title: 'Atenção',
                }).then((result) => {
                    if (result) {
                        this.$apollo.mutate({
                            mutation: FiscalizacaoQueries.deleteFoto,
                            variables: {id},
                        }).then(() => {
                            this.fiscalizacao.fotos.splice(index, 1);
                        });
                    }
                });
            },
            montaDragAndDrop(element, imagesContainerElement, callback){
                element.addEventListener("dragover", function (event) {
                    event.preventDefault();
                    element.classList.add("drag-over")
                    if(imagesContainerElement !== undefined){
                        imagesContainerElement.classList.add("images-container-none-pointer")
                    }
                });
                element.addEventListener("dragenter", function (event) {
                    event.preventDefault();
                    element.classList.add("drag-over")
                    if(imagesContainerElement !== undefined){
                        imagesContainerElement.classList.add("images-container-none-pointer")
                    }
                });
                element.addEventListener("dragleave", function (event) {
                    event.preventDefault();
                    element.classList.remove("drag-over")
                    if(imagesContainerElement !== undefined){
                        imagesContainerElement.classList.remove("images-container-none-pointer")
                    }
                });
                element.addEventListener("drop", function (event) {
                    event.preventDefault();
                    element.classList.remove("drag-over");
                    if(imagesContainerElement !== undefined){
                        imagesContainerElement.classList.remove("images-container-none-pointer")
                    }
                    callback(event.dataTransfer.files);
                })
            },
        }
    }
</script>

<style scoped>
    .images-container-none-pointer{
        pointer-events: none;
    }
    .drag-area{
        opacity: 0;
        transition: opacity 175ms;
        width: 100%;
        height: 100%;
        position: absolute;
    }

    .drag-over{
        border: 2px dashed;
        background-color: #cefacc;
        padding: 10px;
        opacity: 1;
    }

    .image-container-empty {
        min-height: 210px;
        display: flex;
        justify-content: center;
    }
    .image-container-empty span{
        align-self: center;
    }
    .relatorio-imagem{
        min-width: 250px;
    }
    @media(min-width: 1440px) {
        .relatorio-imagem{
            min-width: 350px;
        }
    }

</style>