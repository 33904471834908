<template>
    <div v-if="notaFiscal">
        <v-subheader class="text-uppercase caption pt-4 ">
            <span class="blue-grey--text">Nota Fiscal</span>
        </v-subheader>
        <div style="position: relative">
            <div ref="fileContainer" class="drag-area"></div>
            <v-list dense class="pa-0" color="transparent">
                <v-list-item :to="{ name: 'nota_fiscal_view', params: { id: parseInt(notaFiscal.id) }}">
                    <v-list-item-content class="py-2">
                        <v-list-item-title class="font-weight-regular">
                            {{notaFiscal.numero}}
                        </v-list-item-title>
                        <v-list-item-subtitle class="font-weight-regular" style="font-size: 10px">
                            Emitida em {{notaFiscal.dataEmissao | moment('DD/MM/YYYY [às] HH:mm')}}
                        </v-list-item-subtitle>
                    </v-list-item-content>

                </v-list-item>
            </v-list>
        </div>
    </div>
</template>

<script>
    import gql from 'graphql-tag';

    export default {
        name: "NotaFiscalFragment",
        props:{
            fiscalizacao:null,
        },
        data(){
            return {
                notaFiscal: null,
            }
        },
        mounted() {
            this.getNotaFiscal(this.fiscalizacao.id);
        },
        methods:{
            getNotaFiscal(id){
                this.$apollo.query({
                    query: gql`query ($id: Int!){
                        notaFiscal:notaFiscalPorFiscalizacao(fiscalizacao_id: $id){
                            id
                            numero
                            dataEmissao
                        }
                    }`,
                    variables: {id},
                }).then((result) => {
                    this.notaFiscal = result.data.notaFiscal;
                });
            }
        }
    }
</script>

<style scoped>

</style>