<template>
    <v-dialog scrollable v-model="dialog" persistent max-width="300px">
        <v-card v-if="dialog">
            <v-card-title>
                <span class="headline">Definir prorrogação</span>
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col>
                        <v-text-field :value="$moment(solicitacaoProrrogacao.dataLimiteAnterior).format('DD/MM/YYYY')"
                                      label="Data limite anterior"
                                      disabled hide-details
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field v-if="solicitacaoProrrogacao.dataLimiteSolicitada"
                                      :value="$moment(solicitacaoProrrogacao.dataLimiteSolicitada).format('DD/MM/YYYY')"
                                      label="Data limite solicitada"
                                      disabled hide-details
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row >
                    <v-col>
                        <v-form ref="form1" v-model="form1Valid" lazy-validation>
                            <v-menu v-model="dataLimiteDefinidaMenu"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                        v-model="dataLimiteDefinidaFormated"
                                        label="Data limite definida" placeholder=" "
                                        readonly hide-details :rules="requiredRules" required
                                        v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker v-model="dataLimiteDefinida"
                                               no-title :min="$moment(solicitacaoProrrogacao.dataLimiteAnterior).format('YYYY-MM-DD')"
                                               locale="pt-br" required
                                               @input="dataLimiteDefinidaMenu = false">
                                </v-date-picker>
                            </v-menu>
                        </v-form>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <div class="pa-3" style="width: 100%">
                    <v-btn text @click="closeDialog">Cancelar</v-btn>

                    <div class="float-right">
                        <v-btn color="primary" text depressed @click="save" >
                            Salvar
                        </v-btn>
                    </div>

                </div>
            </v-card-actions>
            <v-overlay absolute :value="loading" color="white">
                <v-progress-circular :size="100" :width="7" color="deep-orange" indeterminate></v-progress-circular>
            </v-overlay>
        </v-card>
    </v-dialog>
</template>

<script>
import gql from "graphql-tag";

export default {
    name: "DefinirProrrogacaoDialog",
    data() {
        return {
            dialog: false,
            loading: false,
            form1Valid: true,
            solicitacaoProrrogacao: null,
            dataLimiteDefinida: null,
            dataLimiteDefinidaMenu: false,
            requiredRules: [
                (v) => !!v || 'Campo obrigatório',
            ],
        }
    },
    computed:{
        dataLimiteDefinidaFormated: {
            get() {
                if(!this.dataLimiteDefinida){
                    return ''
                }
                return this.$moment(this.dataLimiteDefinida).format('DD/MM/YYYY');
            },
            set(newValue) {
                this.dataLimiteDefinida = newValue;
            },
        },
    },
    methods: {
        openDialog(solicitacaoProrrogacao) {
            this.dialog = true;
            this.solicitacaoProrrogacao = solicitacaoProrrogacao;
            this.dataLimiteDefinida = null;
        },
        closeDialog() {
            this.dialog = false;
        },
        save(){
            if(!this.$refs.form1.validate()){
                return false
            }
            this.loading = true;
            this.$apollo.mutate({
                mutation: gql`mutation ($id: Int!, $dataDefinida: LocalDate!){
                        solicitacaoProrrogacao:definirSolicitacaoProrrogacaoDataLimite(id: $id, dataDefinida: $dataDefinida){
                            id
                            justificativa
                            dataLimiteAnterior
                            dataLimiteSolicitada
                            dataLimiteDefinida
                            emailSolicitacao{
                                enviadoPor{
                                    id
                                    imagem{
                                        id
                                    }
                                    nome
                                    sobrenome
                                }
                            }
                            createdAt
                        }
                    }`,
                variables: {
                    id: this.solicitacaoProrrogacao.id,
                    dataDefinida: new Date(this.dataLimiteDefinida).getTime()
                },
            }).then((result) => {
                this.$emit('saved', Object.assign({}, result.data.solicitacaoProrrogacao));
                this.loading = false;
                this.closeDialog();
            }).finally(() => {
                this.loading = false;
            });
        }
    }
}
</script>

<style scoped>

</style>