import gql from 'graphql-tag';

export class GarantiaDescricaoQueries {
    public static getAll = gql`query {
        garantiaDescricoes{
            id
            codigo
            descricao
        }
    }`;

    public static save = gql`mutation ($garantiaDescricao:GarantiaDescricaoInput!){
        saveGarantiaDescricao(data: $garantiaDescricao){
            id
            codigo
            descricao
        }
    }`;
}
