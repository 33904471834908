<template>
    <v-dialog v-model="dialog" width="500">
        <v-card>
            <v-card-title>
                <span class="headline">Nova Descrição</span>
            </v-card-title>
            <v-card-text>
                <v-form ref="formDescricao" v-model="formDescricao" lazy-validation>
                    <v-row>
                        <v-col cols="4">
                            <v-text-field v-model="descricao.codigo" label="Código"
                                          placeholder=" " v-mask="'###'"></v-text-field>
                        </v-col>
                        <v-col cols="8">
                            <v-text-field v-model="descricao.descricao" label="Descrição"
                                          placeholder=" "
                                          :rules="requiredRules" required></v-text-field>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <div class="pa-3" style="width: 100%">
                    <v-btn text @click="cancel">Cancelar</v-btn>

                    <div class="float-right">
                        <v-btn text color="primary" @click="save">Salvar</v-btn>
                    </div>

                </div>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import {GarantiaDescricaoQueries} from '../../../../queries/GarantiaDescricaoQueries';

    export default {
        name: 'DescricaoDialog',
        data() {
            return {
                dialog: false,
                descricao: {
                    codigo: null,
                    descricao: null,
                },
                formDescricao: true,
                requiredRules: [
                    (v) => !!v || 'Campo obrigatório',
                ],
            };
        },
        methods: {
            openDialog() {
                this.dialog = true;
            },
            closeDialog() {
                this.dialog = false;
                this.descricao = {codigo: null, descricao: null};
            },
            resetForm() {
                this.$refs.formDescricao.reset();
            },
            save() {
                if (this.$refs.formDescricao.validate()) {
                    this.$apollo.mutate({
                        mutation: GarantiaDescricaoQueries.save,
                        variables: {
                            garantiaDescricao: this._.cloneDeep(this.descricao),
                        },
                    }).then((result) => {
                        this.$emit('saved', Object.assign({}, result.data.saveGarantiaDescricao));
                        this.resetForm();
                        this.closeDialog();
                    });
                }

            },
            cancel() {
                this.resetForm();
                this.closeDialog();
            },
        },
    };
</script>

<style scoped>

</style>
