import gql from 'graphql-tag';

export class ObjetoDescricaoQueries {
    public static getAll = gql`query {
        objetoDescricoes{
            id
            codigo
            descricao
        }
    }`;

    public static save = gql`mutation ($objetoDescricao:ObjetoDescricaoInput!){
        saveObjetoDescricao(data: $objetoDescricao){
            id
            codigo
            descricao
        }
    }`;
}
