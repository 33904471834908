import gql from 'graphql-tag';

export class PontoPartidaQueries {
    public static getPontoPartidaByEstado = gql`query GetPontoPartidaByEstado($id: Int!) {
        pontoPartidaByEstado(id: $id){
            id
            cidade{
                id
                nome
                estado{
                    id
                    nome
                    sigla
                }
            }
        }
    }`;
}