<template>
    <v-dialog v-model="dialog" persistent scrollable max-width="800px">
        <v-card v-if="dialog">
            <v-card-title>
                Arquivos
            </v-card-title>
            <v-card-text>
                <v-form ref="form1" v-model="form1Valid" lazy-validation>
                    <v-simple-table v-if="arquivos.length > 0" class="custom-table">
                        <template v-slot:default>
                            <thead>
                            <tr>
                                <th></th>
                                <th>Nome</th>
                                <th style="width: 100%">Tags</th>
                                <th>Status</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(arquivo, index) in arquivos" :key="index">
                                <td class="px-0">
                                    <v-btn icon color="red" small @click="arquivos.splice(index, 1)"><v-icon small>mdi-close</v-icon></v-btn>
                                </td>
                                <td style="max-width: 300px; text-overflow: ellipsis; overflow: hidden" class="text-no-wrap">
                                    <span class="caption font-weight-light text-no-wrap" :title="arquivo.attachment.filename" >{{arquivo.attachment.filename}}</span>
                                </td>
                                <td>
                                    <v-autocomplete v-model="arquivo.tags" :items="tags"
                                            class="tags-input"
                                            dense hide-details chips small-chips
                                            multiple outlined deletable-chips clearable
                                            item-text="nome" item-value="id"
                                            :rules="arquivo.isInvalid ? [] : [rules.required]" required
                                            :disabled="arquivo.isInvalid"
                                    ></v-autocomplete>
                                </td>
                                <td>
                                    <v-icon v-if="arquivo.status === 1">mdi-upload</v-icon>
                                    <v-icon v-if="arquivo.status === 2" color="green">mdi-check-circle</v-icon>
                                    <v-tooltip v-if="arquivo.isInvalid || arquivo.status === 3"
                                               bottom color="red" max-width="300px">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon small v-on="on" color="red">
                                                mdi-alert
                                            </v-icon>
                                        </template>
                                        <span>{{arquivo.errorMessage}}</span>
                                    </v-tooltip>
                                </td>
                            </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                    <div v-else class="text-center py-6">
                        <span class="subtitle-1">Nenhum arquivo para ser enviado</span>
                    </div>
                    <v-overlay v-if="arquivos" absolute style="bottom: 76px;top: 58px;"
                               :value="enviando" opacity="0.6" color="white">
                        <v-sheet class="pt-3 pb-2 px-4" color="deep-orange" elevation="3">
                            <v-progress-linear style="width: 200px" rounded color="white" :background-opacity="0" stream
                                               buffer-value="0" :value="Math.floor(totalEnviado / arquivosValidos.length*100)" class="mb-1"  />
                            <span class="caption">Enviado {{totalEnviado}} de {{arquivosValidos.length}}</span>
                        </v-sheet>
                    </v-overlay>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <div class="pa-3" style="width: 100%">
                    <v-btn text @click="closeDialog">Cancelar</v-btn>

                    <div class="float-right">
                        <v-btn text color="primary" :disabled="enviando || arquivosValidos < 1" @click="uploadAttachments">
                            Enviar
                        </v-btn>
                    </div>

                </div>
            </v-card-actions>
            <v-overlay absolute :value="loading" color="white">
                <v-progress-circular :size="100" :width="7" color="deep-orange" indeterminate></v-progress-circular>
            </v-overlay>
        </v-card>
    </v-dialog>
</template>

<script>
    import gql from "graphql-tag";

    export default {
        name: "DefinirArquivosEmailsTagsDialog",
        data() {
            return {
                dialog: false,
                loading: false,
                tags: [],
                arquivos: [],
                fiscalizacao: null,
                originMessageId: null,
                messageId: null,
                enviando: false,
                form1Valid: true,
                hasErrors: false,
                totalEnviado: 0,
                rules: {
                    required: (v) => {
                        return (!!v && v.length > 0) || 'Campo Obrigatório.'
                    },
                },
            }
        },
        watch:{
            totalEnviado(value){
                if(this.enviando && this.arquivosValidos.length === value){
                    if(!this.hasErrors){
                        this.$notify({text: 'Arquivos anexados com sucesso!', type: 'success'});
                        this.$emit('completed');
                        this.enviando = false;
                        this.closeDialog();
                    }else{
                        this.totalEnviado = 0;
                        this.$notify({text: 'Algumas arquivos falharam ao serem anexados!', type: 'error'});
                        this.enviando = false;
                    }
                }
            }
        },
        computed:{
            arquivosValidos(){
                return this.arquivos.filter((arquivo) => !arquivo.isInvalid)
            }
        },
        methods: {
            openDialog(attachments, fiscalizacao, originMessageId, messageId) {
                this.dialog = true;
                this.fiscalizacao = fiscalizacao;
                this.originMessageId = originMessageId;
                this.messageId = messageId;
                this.getTags();
                this.mapFiles(attachments);
            },
            closeDialog() {
                this.dialog = false;
                this.totalEnviado = 0;
                this.arquivos = [];
                //TODO Cancelar as requisiçoes com https://stackoverflow.com/questions/50516438/cancel-previous-request-using-axios-with-vue-js
            },
            mapFiles(attachments){
                for (let i = 0; i < attachments.length; i++) {
                    let arquivo = {
                        attachment: attachments[i],
                        status: 0,
                        isInvalid: false,
                        tags:[]
                    }
                    if(arquivo.attachment.body.size > 8000000){
                        arquivo.isInvalid = true;
                        arquivo.errorMessage = "Arquivo muito grande";
                    }
                    this.arquivos.push(arquivo);
                }
            },
            getTags(){
                this.loading = true;
                this.$apollo.query({
                    query: gql`query {
                        tags{
                            id
                            nome
                        }
                    }`
                }).then((result) => {
                    this.tags = result.data.tags;
                }).finally(()=>{
                    this.loading = false;
                })
            },
            uploadAttachments(){
                this.hasErrors = false;
                if(!this.$refs.form1.validate()){
                    return
                }

                for (let arquivo of this.arquivosValidos) {
                    this.uploadAttachment(arquivo)
                }
            },
            uploadAttachment(arquivo){
                this.enviando = true;
                arquivo.status = 1;
                const formData = new FormData();
                formData.append('attachmentId', arquivo.attachment.body.attachmentId);
                formData.append('filename', arquivo.attachment.filename);
                formData.append('originMessageId', this.originMessageId);
                formData.append('messageId', this.messageId);
                formData.append('tags', arquivo.tags);
                this.$axios.post('/fiscalizacoes/' + this.fiscalizacao.id + '/uploadFileFromEmail', formData).
                    then((result) => {
                        arquivo.status = 2;
                        this.fiscalizacao.arquivos.push(result.data)
                        /*this.$notify({text: 'Arquivo enviado com sucesso!', type: 'success'});
                        let arquivo = result.data;
                        this.fiscalizacao.arquivos.push(arquivo)*/
                    }).catch((error) => {
                        this.hasErrors = true;
                        arquivo.status = 3;
                        arquivo.errorMessage = error.response.data.message;
                    }).finally(()=>{
                        this.totalEnviado++;
                    })
            },
        }
    }
</script>

<style>
    .custom-table td{
        height: 30px;
    }
    .tags-input{
        margin-top: 2px !important;
        margin-bottom: 2px !important;
    }
    .tags-input .v-input__control .v-input__slot {
        min-height: auto !important;
        display: flex !important;
        align-items: center !important;
    }
</style>