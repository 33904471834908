<template>
    <div>
        <p>Olá Prezados</p>
        <p>Solicitamos uma prorrogação da operação abaixo<span v-if="dataLimiteSugerida"> para pelo menos <b>{{dataLimiteSugerida | moment('DD/MM/YYYY')}}</b></span>.</p>
        <!--CONTRATO-->
        <table>
            <tbody>
            <tr>
                <td><strong class="mr-2">Nº SOL.</strong></td>
                <td><strong class="mr-2">CÉDULA&nbsp;&nbsp;</strong></td>
                <td><strong>MUTUARIO</strong></td>
            </tr>
            <tr>
                <td>{{fiscalizacao.numeroSolicitacao}}</td>
                <td>{{fiscalizacao.financiamento.cedula}}</td>
                <td>{{fiscalizacao.financiamento.mutuario}}</td>
            </tr>
            </tbody>
        </table>
        <br/>
        <!--JUSTIFICATIVA-->
        <span class="d-block"><strong>Justificativa</strong></span>
        <span>{{justificativa}}</span>
        <br/>
        <br/>

        <!--ASSINATURA-->
        <table style="margin-bottom: 20px">
            <tbody>
            <tr>
                <td>
                    <strong>{{fiscalizacao.supervisedBy.nome + ' ' + fiscalizacao.supervisedBy.sobrenome}}</strong>
                    <br>
                    <strong>(66)99687-8442 <img src="https://drive.google.com/uc?export=view&id=17sQbOpYa7LgsGzkxDbe2yLkC4XG0O4Z4" width="14" height="14"></strong> |
                    <strong>(66)3531-4164 <img src="https://drive.google.com/uc?export=view&id=17sQbOpYa7LgsGzkxDbe2yLkC4XG0O4Z4" width="14" height="14"></strong>
                    <br>
                    <strong><a href="mailto:fiscalizacao@jfassessoriarural.com.br" target="_blank">fiscalizacao@jfassessoriarural.com.br</a></strong>
                </td>
            </tr>
            </tbody>
        </table>

        <email-footer1 />
    </div>
</template>

<script>
import EmailFooter1 from "@/views/servico/fiscalizacao/fragments/email_templates/EmailFooter1";
export default {
    name: "EmailSolicitacaoProrrogacaoTemplate",
    components: {EmailFooter1},
    props:{
        fiscalizacao:null,
        dataLimiteSugerida:null,
        justificativa: null,
    },
    data(){
        return{
            /*dataLimiteSolicitada:null,
            justificativa: null,*/
        }
    }
}
</script>

<style scoped>

</style>