<template>
    <v-dialog v-model="dialog" scrollable persistent max-width="600px">
        <v-card v-if="dialog">
            <v-card-title>
                <span class="headline">Alterar Resposta</span>
            </v-card-title>
            <v-card-text>
                <!--<v-btn rounded x-small color="primary" class="mb-1">Adicionar Item</v-btn>-->
                <v-list flat >
                    <v-list-item @click="checkAll" class="grey lighten-3">
                        <v-list-item-content>
                            <v-list-item-title class="subtitle-2">{{allChecked ? "Descmarcar" : "Marcar"}} todos</v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-checkbox v-model="allChecked" readonly @click="" :indeterminate="!hasAllSameValue"></v-checkbox>
                        </v-list-item-action>
                    </v-list-item>
                    <template v-for="(item, index) in itens">
                        <v-list-item @click="item.valor = !item.valor" :disabled="item.valor === null">
                            <v-list-item-content>
                                <v-list-item-title>{{item.itemAFiscalizar.descricao}}</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action v-if="item.valor !== null">
                                <v-checkbox v-model="item.valor" @click.stop=""></v-checkbox>
                            </v-list-item-action>
                        </v-list-item>
                    </template>
                </v-list>
            </v-card-text>
            <v-card-actions>
                <div class="pa-3" style="width: 100%">
                    <v-btn text @click="closeDialog">Cancelar</v-btn>

                    <div class="float-right">
                        <v-btn color="primary" text depressed @click="salvar" >
                            Salvar
                        </v-btn>
                    </div>

                </div>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        name: "AlterarRespostaDialog",
        data(){
            return{
                dialog: false,
                itens: [],
                allChecked: false,
            }
        },
        watch:{

        },
        computed:{
            hasAllSameValue(){
                let firstValue;
                for(let i = 0; i < this.itens.filter(iten => iten.valor != null).length; i++){
                    if(i === 0){
                        firstValue = this.itens[i].valor;
                    }else{
                        if(firstValue !== this.itens[i].valor){
                            return false;
                        }
                    }
                }
                this.allChecked = firstValue;
                return true;
            }
        },
        methods: {
            openDialog(itens) {
                this.dialog = true;
                this.itens = this._.cloneDeep(itens)
            },
            closeDialog() {
                this.dialog = false;
                this.itens = []
            },
            checkAll(value){
                this.allChecked = !this.allChecked;
                this.itens.forEach(item => {
                    if(item.valor !== null){
                        item.valor = this.allChecked
                    }
                })

            },
            salvar(){
                this.$emit('saved', this._.cloneDeep(this.itens));
                this.closeDialog();
            }
        }
    }
</script>

<style scoped>

</style>