<template>
    <div>
        <v-subheader class="text-uppercase caption pt-4 ">
            <span class="blue-grey--text">Anexos</span>
            <v-btn icon x-small color="blue-grey darken-1" class="ml-1"
                   :loading="fileUploading"
                   :disabled="fileUploading"
                   @click.stop="onAdicionarArquivosButtonClicked">
                <v-icon small>mdi-plus-circle</v-icon>
                <template v-slot:loader>
                    <v-progress-circular
                            indeterminate width="2" size="14"
                            color="blue-grey "
                    ></v-progress-circular>
                </template>
            </v-btn>
            <input v-show="false" ref="inputFileUpload" type="file" multiple
                   @input="uploadFileManually" >
            <v-spacer/>
            <span v-if="fiscalizacao.arquivos.length > 0" class="ml-4 blue-grey--text" style="font-size: 0.6rem">{{fiscalizacao.arquivos.length}} arquivos</span>
        </v-subheader>
        <div style="position: relative; max-height: 400px; overflow-y: auto">
            <div ref="fileContainer" class="drag-area"></div>
            <v-list dense class="pa-0" color="transparent" style="" v-if="fiscalizacao.arquivos.length > 0">
                <template v-for="(arquivo, index) in fiscalizacao.arquivos">
                    <v-hover v-slot:default="{ hover }">
                        <v-list-item :key="arquivo.id" @click="downloadArquivo(arquivo.id)">
                            <v-list-item-avatar class="my-0" >
                                <v-icon :style="'opacity: '+(hover?'1':'0.6')"
                                        :color="getIconColor(arquivo.nome)">
                                    {{getIcon(arquivo.nome)}}
                                </v-icon>
                            </v-list-item-avatar>

                            <v-list-item-content class="py-2">
                                <v-list-item-title class="font-weight-regular"  :title="arquivo.nome">
                                    {{arquivo.nome}}
                                </v-list-item-title>
                                <v-list-item-subtitle >
                                    <template v-for="tag in arquivo.tags">
                                        <v-chip x-small label color="blue" text-color="white"
                                                :title="arquivo.tags.map(t => t.nome).join(', ')"
                                                class="mr-1 px-2" style="height: 14px; font-size: 10px">
                                            {{tag.nome}}
                                        </v-chip>
                                    </template>

                                </v-list-item-subtitle>
                                <v-list-item-subtitle class="font-weight-regular" style="font-size: 10px">
                                    Criado em {{arquivo.createdAt | moment('DD-MM-YYYY [às] HH:mm')}}
                                </v-list-item-subtitle>
                            </v-list-item-content>

                            <v-list-item-action class="my-0" v-if="hover" title="">
                                <div>
                                    <v-btn icon
                                           v-if="canViewFile(arquivo.nome)"
                                           @mousedown.stop @touchstart.native.stop
                                           @click.stop="viewArquivo(arquivo.id)">
                                        <v-icon small>mdi-eye</v-icon>
                                    </v-btn>
                                    <v-btn v-if="!arquivo.locked " icon
                                           @click.stop="editArquivo(arquivo, index)" :disabled="finalizado || fiscalizacao.dataCancelamento"
                                           @mousedown.stop @touchstart.native.stop>
                                        <v-icon small>mdi-pencil</v-icon>
                                    </v-btn>
                                    <v-btn icon color="red"
                                           :disabled="arquivo.locked || finalizado || fiscalizacao.dataCancelamento"
                                           @mousedown.stop @touchstart.native.stop
                                           @click.stop="removeArquivo(arquivo.id, index)">
                                        <v-icon small>mdi-delete</v-icon>
                                    </v-btn>
                                </div>
                            </v-list-item-action>
                        </v-list-item>
                    </v-hover>
                </template>
            </v-list>
            <div v-else class="body-2 text-center pa-2 mx-4 mt-2 blue-grey--text lighten-2">
                <span class="overline font-weight-medium">Nenhuma arquivo adicionado.</span><br/>
                <div class="overline mt-1 mb-2" style="line-height: 120%">
                    Arraste arquivos para esta área <br/>
                    ou clique no <v-icon color="blue-grey lighten-2" x-small>mdi-plus-circle</v-icon> acima.</div>
            </div>
        </div>
        <definir-arquivos-tags-dialog ref="definirArquivosTagsDialog" @completed="" @canceled="" />
        <editar-arquivo-dialog ref="editarArquivoDialog" @edited="onArquivoEdited" />
    </div>
</template>

<script>
    import {FiscalizacaoQueries} from "../../../../../queries/FiscalizacaoQueries";
    // @ts-ignore
    import DefinirArquivosTagsDialog from "../../DefinirArquivosTagsDialog";
    // @ts-ignore
    import EditarArquivoDialog from "../../EditarArquivoDialog";
    import {getIcon, getExtensionFile, getIconColor, canViewFile} from "../../../../../assets/FileUtils"

    export default {
        name: "AnexosFragment",
        components: {EditarArquivoDialog, DefinirArquivosTagsDialog},
        props: {
            fiscalizacao: null,
        },
        data(){
            return {
                fileUploading: false,
                finalizado: null,
            }
        },
        mounted() {
            if(!this.finalizado) {
                let self = this;
                /*this.montaDragAndDrop(this.$refs.fileContainer, function (files) {
                    self.uploadFiles(files);
                });*/
            }
        },
        methods: {
            getIcon, getIconColor, getExtensionFile, canViewFile,
            onAdicionarArquivosButtonClicked(){
                this.$refs.inputFileUpload.value = "";
                this.$refs.inputFileUpload.click();
            },
            editArquivo(arquivo, index){
                arquivo.index = index;
                this.$refs.editarArquivoDialog.openDialog(arquivo)
            },
            onArquivoEdited(arquivo, index){
                let arquivoExisted = this.fiscalizacao.arquivos[index];
                arquivoExisted = Object.assign(arquivoExisted, arquivo);
            },
            removeArquivo(id, index) {
                this.$dialog.confirm({
                    text: 'Deseja realmente apagar este arquivo?',
                    title: 'Atenção',
                }).then((result) => {
                    if (result) {
                        this.$apollo.mutate({
                            mutation: FiscalizacaoQueries.deleteArquivo,
                            variables: {id},
                        }).then(() => {
                            this.fiscalizacao.arquivos.splice(index, 1);
                        });
                    }
                });
            },
            viewArquivo(id){
                this.$axios.get('/fiscalizacoes/arquivo/' + id, {responseType: 'blob'}).then((response) => {
                    const fileName = response.headers['content-disposition'].split('filename=')[1].replace(/^"|"$/g, '');
                    let extension = this.getExtensionFile(fileName);
                    let options = {};
                    switch (extension) {
                        case 'pdf':
                            options.type = 'application/pdf';
                            break
                        case 'jpg':
                            options.type = 'image/jpg';
                            break
                    }
                    let blob = new Blob([response.data], options)
                    let fileURL = URL.createObjectURL(blob);
                    window.open(fileURL)
                }).catch(() => {
                    this.$dialog.error({
                        text: 'Não foi possível abrir o arquivo. Contate o desenvolvedor.',
                        title: 'Ops',
                    });

                });
            },
            downloadArquivo(id) {
                this.$axios.get('/fiscalizacoes/arquivo/' + id, {responseType: 'blob'}).then((response) => {
                    const fileLink = document.createElement('a');
                    fileLink.href = window.URL.createObjectURL(new Blob([response.data]));
                    const fileName = response.headers['content-disposition'].split('filename=')[1].replace(/^"|"$/g, '');
                    fileLink.setAttribute('download', fileName);
                    document.body.appendChild(fileLink);
                    fileLink.click();
                    document.body.removeChild(fileLink);
                }).catch(() => {
                    this.$dialog.error({
                        text: 'Não foi possível baixar o arquivo. Contate o desenvolvedor.',
                        title: 'Ops',
                    });

                });
            },
            uploadFileManually(e){
                this.$refs.definirArquivosTagsDialog.openDialog(e.target.files, this.fiscalizacao);
            },
            montaDragAndDrop(element, callback){
                element.addEventListener("dragover", function (event) {
                    event.preventDefault();
                    element.classList.add("drag-over")
                })
                element.addEventListener("dragenter", function (event) {
                    event.preventDefault();
                    element.classList.add("drag-over")
                })
                element.addEventListener("dragleave", function (event) {
                    event.preventDefault();
                    element.classList.remove("drag-over")
                })
                element.addEventListener("drop", function (event) {
                    event.preventDefault();
                    element.classList.remove("drag-over")
                    callback(event.dataTransfer.files);
                })
            },
        }
    }
</script>

<style scoped>
    .drag-area{
        opacity: 0;
        transition: opacity 175ms;
        right: 0;
        left: 0;
        top: 0;
        bottom: 0;
        position: absolute;
    }

    .drag-over{
        background-color: #fff;
        padding: 10px;
        opacity: 0.3;
    }
</style>