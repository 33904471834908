<template>
    <v-dialog v-model="dialog" scrollable persistent max-width="600px">
        <v-card v-if="dialog">
            <v-card-title>
                <span class="headline mr-3">Finalizar</span>
                <v-btn rounded x-small color="primary" v-if="stepperPosition === 1"
                       @click="alterarResposta" class="mb-1">Alterar Resposta</v-btn>
            </v-card-title>
            <v-card-text>
                <v-stepper v-model="stepperPosition" :vertical="false" class="stepper-fix" >
                    <v-stepper-items>
                        <v-stepper-content step="1">

                            <v-simple-table class="custom-table">
                                <template v-slot:default>
                                    <thead>
                                    <tr>
                                        <th style="padding-left: 0">ITEM A FISCALIZAR</th>
                                        <th class="text-center">SIM</th>
                                        <th class="text-center">NÃO</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="item in itens" :key="item.id">
                                        <td style="padding-left: 0">
                                            <span class="body-2 font-weight-light">{{ item.itemAFiscalizar.descricao }}</span>
                                        </td>
                                        <template v-if="item.valor != null">
                                            <td class="text-center">
                                                <v-icon size="12px" color="green" v-if="item.valor === true">mdi-checkbox-blank-circle</v-icon>
                                            </td>
                                            <td class="text-center">
                                                <v-icon size="12px" color="red" v-if="item.valor === false">mdi-checkbox-blank-circle</v-icon>
                                            </td>
                                        </template>
                                        <template v-else>
                                            <td colspan="2">
                                                <v-text-field v-model="item.resposta"
                                                              class="resposta-input caption font-weight-light"
                                                              height="30" style="width: 150px" clearable
                                                              dense outlined hide-details />
                                            </td>
                                        </template>
                                    </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-stepper-content>
                        <v-stepper-content step="2">
                            <v-form ref="form1" v-model="form1Valid" lazy-validation>
                                <v-row>
                                    <v-col class="text-center pt-0">
                                        <v-btn-toggle v-model="isRegular" mandatory dense >
                                            <v-btn :value="true" active-class="primary white--text toggle_icon_active">
                                                <v-icon left>mdi-check-circle-outline</v-icon>
                                                <span>Regular</span>
                                            </v-btn>

                                            <v-btn :value="false" active-class="red white--text toggle_icon_active">
                                                <v-icon left>mdi-close-circle-outline</v-icon>
                                                <span>Irregular</span>
                                            </v-btn>
                                        </v-btn-toggle>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="4" class="pb-0">
                                        <v-menu v-model="dataFiscalizacaoMenu"
                                                :close-on-content-click="false"
                                                transition="scale-transition"
                                                offset-y
                                                max-width="290px">
                                            <template v-slot:activator="{ on }">
                                                <v-text-field
                                                        v-model="dataFiscalizacaoFormated"
                                                        label="Data Fiscalização"
                                                        readonly
                                                        v-on="on"
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker v-model="dataFiscalizacao"
                                                           no-title
                                                           locale="pt-br"
                                                           :min="dataFiscalizacaoMinima"
                                                           @input="dataFiscalizacaoMenu = false">
                                            </v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col cols="4" class="pb-0">
                                        <v-text-field v-model="new Date(fiscalizacao.dataArquivoBanco).toLocaleDateString()"
                                                      label="Data Envio Arquivo" disabled readonly>
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="4" class="pb-0">
                                        <v-text-field v-model="new Date(fiscalizacao.dataLimite).toLocaleDateString()"
                                                      label="Data Limite" disabled readonly>
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col sm cols="col-6" class="pb-0">
                                        <v-text-field v-model="informante" label="Informante"
                                                      placeholder=" " :rules="requiredRules" required>
                                        </v-text-field>
                                    </v-col>
                                    <v-col sm cols="col-6" class="pb-0">
                                        <v-text-field v-model="funcaoInformante" label="Função"
                                                      placeholder=" " :rules="requiredRules" required>
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row no-gutters>
                                    <v-col>
                                        <v-select v-model="comentarioSelecionado"
                                                  :items="comentarios"
                                                  placeholder="Selecione uma opção"
                                                  :rules="requiredRules" required
                                                  label="Comentário e Justificatvas" @input="onComentarioInput"
                                        ></v-select>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col class="pt-0">
                                        <v-textarea v-model="comentario" class="pt-0"
                                                auto-grow rows="2"
                                                :disabled="comentarioSelecionado !== 'Outro'"
                                                :rules="requiredRules" required row-height="30"
                                        ></v-textarea>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-checkbox v-model="incluirTodasCoordenadasNoLaudo" label="Incluir todas as coordenadas no laudo"></v-checkbox>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-stepper-content>
                        <v-stepper-content step="3">
                            <v-form ref="form2" v-model="form2Valid" lazy-validation>

                                <v-row no-gutters>
                                    <v-col>
                                        <span class="caption d-block">Roteiro de acesso</span>
                                        {{fiscalizacao.financiamento.area.roteiroDeAcesso}}
                                    </v-col>
                                </v-row>
                                <v-card class="mt-2 pa-2 ma-1">
                                    <v-row no-gutters>
                                        <v-col>
                                            <div id="map"></div>
                                        </v-col>
                                    </v-row>
                                    <v-row no-gutters>
                                        <v-col>
                                            <v-slide-group v-model="selectedRoute" mandatory class="mt-1 mb-n1">
                                                <v-slide-item class="my-1"
                                                              v-for="(route, index) in routes" :key="index"
                                                              v-slot:default="{ active, toggle }">
                                                    <v-card class="px-2 mx-1" outlined
                                                            :color="active ? 'primary white--text' : ''"
                                                            @click="toggle" :disabled="naoFoiAoLocal">
                                                        <span  class="mb-n2 caption d-block">{{route.summary}}</span>
                                                        <span class=" caption">{{route.legs[0].distance.text}}</span>
                                                    </v-card>
                                                </v-slide-item>
                                            </v-slide-group>
                                        </v-col>
                                    </v-row>
                                </v-card>

                                <v-row class="mt-2">
                                    <v-col cols="col-xs-12 col-sm-6">
                                        <v-text-field v-model="distancia" suffix="Km" label="Distância percorrida (Ida e volta)"
                                                      hint="Informe se tiver acréscimo" persistent-hint
                                                      placeholder=" " :rules="requiredRules2" class="distancia-input"
                                                      required :disabled="naoFoiAoLocal"></v-text-field>
                                    </v-col>
                                    <v-col cols="col-xs-12 col-sm-6" v-if="false">
                                        <v-checkbox v-model="naoFoiAoLocal" @change="desativaDistancia" label="Não foi ao local" />
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-stepper-content>
                    </v-stepper-items>
                </v-stepper>
            </v-card-text>
            <v-card-actions>
                <div class="pa-3" style="width: 100%">
                    <v-btn text @click="closeDialog">Cancelar</v-btn>

                    <div class="float-right">
                        <v-btn text @click="previousStep" v-if="stepperPosition > 1">
                            Voltar
                        </v-btn>
                        <v-btn color="primary" text @click="nextStep" v-if="stepperPosition < 3">
                            Próximo
                        </v-btn>
                        <v-btn text color="primary" depressed @click="finalizar" v-if="stepperPosition === 3">
                            Finalizar
                        </v-btn>
                    </div>

                </div>
            </v-card-actions>
            <v-overlay absolute :value="loading" color="white">
                <v-progress-circular :size="100" :width="7" color="deep-orange" indeterminate></v-progress-circular>
            </v-overlay>
        </v-card>
        <alterar-resposta-dialog ref="alterarRespostaDialog" @saved="respostaAtualizada" />
    </v-dialog>
</template>

<script>
    import {FiscalizacaoQueries} from '../../../queries/FiscalizacaoQueries';
    import GoogleMapsInit from "../../../assets/GoogleMapsInit";
    import {Fiscalizacao} from '../../../models/Fiscalizacao';
    import {PontoPartidaQueries} from "../../../queries/PontoPartidaQueries";
    // @ts-ignore
    import AlterarRespostaDialog from "./AlterarRespostaDialog";

    export default {
        name: 'FinalizarDialog',
        components: {AlterarRespostaDialog},
        computed:{
            dataFiscalizacaoFormated: {
                get() {
                    return this.$moment(this.dataFiscalizacao).format('DD/MM/YYYY');
                },
                set(newValue) {
                    if (newValue) {
                        this.dataFiscalizacao = newValue;
                    }
                },
            },
        },
        watch:{
            stepperPosition(value){
                if(value === 3){
                    this.initMap()
                        .then(async (google) => {
                            if(google === undefined){
                                return;
                            }
                            let result = await this.getPontoPartida()
                            this.pontoPartida = result.data.pontoPartidaByEstado;
                            this.getRoutes(google)
                        })
                }
            },
            selectedRoute(index){
                this.directionsRender.setRouteIndex(index)
                if(index === null){
                    return;
                }
                this.distanciaFixa = (this.routes[index].legs[0].distance.value / 1000).toFixed();
                this.distancia = this.distanciaFixa * 2;
            }
        },
        data() {
            return {
                dialog: false,
                form1Valid: true,
                form2Valid: true,
                requiredRules: [
                    (v) => !!v || 'Campo obrigatório',
                ],
                requiredRules2: [
                    (v) => this.naoFoiAoLocal ? v >= 0 : v > 0 || 'Campo obrigatório',
                ],
                fiscalizacao: new Fiscalizacao(),
                distanciaFixa: 0,
                distancia: 0,
                naoFoiAoLocal: false,
                dataFiscalizacao: null,
                dataFiscalizacaoMinima: null,
                informante: null,
                funcaoInformante: null,
                pontoPartida: null,
                dataFiscalizacaoMenu: false,
                stepperPosition: 1,
                itens: [],
                comentario: null,
                isRegular: true,
                incluirTodasCoordenadasNoLaudo: false,
                loading: false,
                googleMapsInstance: null,
                brasilLocation: {lat: -15.35, lng: -56.06},
                routes: [],
                directionsRender: null,
                selectedRoute: null,
                comentarios: [
                    'O mutuário cumpriu com o programado, conforme proposta orçamento apresentada no banco.',
                    'A agência não enviou os documentos exigidos (NF e GTA).',
                    'Outro'],
                comentarioSelecionado: null,
            };
        },
        methods: {
            openDialog(fiscalizacao, vencida) {
                this.dialog = true;
                this.fiscalizacao = fiscalizacao;

                if(vencida){
                    this.dataFiscalizacao = this.$moment(fiscalizacao.dataArquivoBanco).format('YYYY-MM-DD');
                    this.dataFiscalizacaoMinima = this.dataFiscalizacao;
                }else {
                    this.dataFiscalizacaoMinima = this.$moment(fiscalizacao.dataArquivoBanco).format('YYYY-MM-DD');
                    if(new Date() > new Date(fiscalizacao.dataLimite)){
                        this.dataFiscalizacao = this.$moment(fiscalizacao.dataLimite).format('YYYY-MM-DD');
                    }else{
                        this.dataFiscalizacao = this.$moment(new Date()).format('YYYY-MM-DD');
                    }
                }


                this.informante = fiscalizacao.financiamento.mutuario;
                this.funcaoInformante = "Proprietário";

                this.getItens(fiscalizacao.tipo.id);

            },
            closeDialog() {
                this.dialog = false;
                this.stepperPosition = 1;
                this.distancia = null;
                this.distanciaFixa = 0;
                this.googleMapsInstance = null;
                this.selectedRoute = null;
                this.routes = [];
                this.itens = [];
                this.isRegular = true;
                this.comentario = "O mutuário cumpriu com o programado, conforme proposta orçamento apresentada no banco.";
                this.$refs.form1.resetValidation();

            },
            nextStep(){
                if(this.stepperPosition === 1){
                    let encontrou = this.itens.find((item) => {
                        return item.valor === null && (item.resposta === null || item.resposta.trim() === '')
                    })

                    if(encontrou !== undefined){
                        this.$dialog.confirm({
                            title: 'Atenção',
                            text: 'Deseja realmente continuar sem responder as perguntas?'
                        }).then((result) => {
                            if (result) {
                                this.stepperPosition++;
                            }
                        })
                        return;
                    }
                }
                if(this.stepperPosition === 2){
                    if(!this.$refs.form1.validate()){
                        return;
                    }

                    if(this.dataFiscalizacao > this.fiscalizacao.dataLimite){
                        this.$dialog.confirm({
                            text: 'A data da fiscalização é maior que a data limite. Deseja continuar mesmo assim?',
                            title: 'Atenção',
                        }).then((result) => {
                            if (result) {
                                this.stepperPosition++;
                            }
                        });
                        return
                    }
                }
                this.stepperPosition++;
            },
            previousStep(){
                this.stepperPosition--;
            },
            initMap(){
                return new Promise((resolve, reject) => {
                    if (this.googleMapsInstance) {
                        resolve();
                        return;
                    }

                    GoogleMapsInit().then((google) => {
                        this.googleMapsInstance = new google.maps.Map(document.getElementById('map'), {
                            zoom: 4,
                            center: this.brasilLocation,
                            mapTypeId: 'roadmap',
                            zoomControl: true,
                            disableDefaultUI: true,
                        });

                        resolve(google);
                    }).catch(error => {
                        reject(error);
                    });
                })
            },
            getRoutes(google){
                const origin = this.pontoPartida.cidade.nome + ', ' + this.pontoPartida.cidade.estado.sigla;
                const destination =
                    this.fiscalizacao.financiamento.area.localizacao.cidade.nome + ', ' +
                    this.fiscalizacao.financiamento.area.localizacao.cidade.estado.sigla;

                const directionsService = new google.maps.DirectionsService();
                directionsService.route({
                    origin,
                    destination,
                    provideRouteAlternatives: true,
                    travelMode: google.maps.DirectionsTravelMode.DRIVING,
                    unitSystem: google.maps.UnitSystem.METRIC,
                }, (response, status) => {
                    if (status === google.maps.DirectionsStatus.OK) {
                        this.directionsRender = new google.maps.DirectionsRenderer({
                            map: this.googleMapsInstance,
                            directions: response
                        });
                        this.routes = response.routes;
                        //chmar fitBound quando clicar em uma rota
                        this.googleMapsInstance.fitBounds(response.routes[0].bounds)

                    } else {
                        console.log("Unable to retrieve your route");
                    }
                })
            },
            alterarResposta(){
                this.$refs.alterarRespostaDialog.openDialog(this.itens);
            },
            respostaAtualizada(itens){
                this.itens = itens;
            },
            onComentarioInput(value){
                if(value !== "Outro"){
                    this.comentario = value;
                }else{
                    this.comentario = null;
                }
            },
            desativaDistancia(){
                this.distancia = 0;
            },
            finalizar() {
                if(!this.$refs.form2.validate()){
                    return;
                }
                this.loading = true;
                this.$apollo.mutate({
                    mutation: FiscalizacaoQueries.finalizarFiscalizacao,
                    variables: {
                        id: this.fiscalizacao.id,
                        dataFiscalizacao: new Date(this.dataFiscalizacao).getTime(),
                        distancia: this.distancia,
                        informante: this.informante,
                        funcaoInformante: this.funcaoInformante,
                        itens: this.itens.map(item => {
                            item.descricao = item.itemAFiscalizar.descricao;
                            delete item.itemAFiscalizar;
                            return item;
                        }),
                        comentario: this.comentario,
                        caminho: this.distancia > 0 && this.routes.length > 0 ? this.routes[this.selectedRoute].overview_polyline.replaceAll("\\", "\\\\") : null,
                        is_regular: this.isRegular,
                        incluirTodasCoordenadasNoLaudo: this.incluirTodasCoordenadasNoLaudo
                    },
                }).then((result) => {
                    this.loading = false;
                    this.$emit('finalized', Object.assign({}, null));
                    this.closeDialog();
                    this.$notify({text: 'Fiscalização finalizada com sucesso!', type: 'success'});
                }).catch(()=>{
                    this.loading = false;
                    this.$dialog.error({
                        title: 'Ops!',
                        text: "Algo deu errado ao finalizar a fiscalização",
                        actions: {
                            yes: 'OK'
                        }
                    });
                });
            },
            getItens(tipoId){
                this.$apollo.query({
                    query: FiscalizacaoQueries.getItensByTipo,
                    variables: {tipo: {id: tipoId}}
                }).then(result => {
                    this.itens = result.data.itensByTipo.map(item => {
                        let novoItem = this._.cloneDeep(item);
                        novoItem.valor = item.valorPadrao;
                        if(item.valorPadrao === null){
                            novoItem.resposta = 'em anexo';
                        }
                        novoItem.posicao = item.posicaoPadrao;
                        delete novoItem.valorPadrao;
                        delete novoItem.posicaoPadrao;
                        return novoItem;
                    });
                });
            },
            getPontoPartida(){
                return this.$apollo.query({
                    query: PontoPartidaQueries.getPontoPartidaByEstado,
                    variables: {id: this.fiscalizacao.financiamento.area.localizacao.cidade.estado.id}
                });
            },
            calculateDistance(cidade) {
                const loader = new Loader('AIzaSyDVmydqrR66sMvCjiFRy9yTXo3KMzJuQIw', {
                    libraries: ['drawing'],
                    version: '3.38'
                });

                loader.load().then(function(google) {
                    const origim = cidade;
                    const destination =
                        this.fiscalizacao.financiamento.area.localizacao.cidade.nome + ',' +
                        this.fiscalizacao.financiamento.area.localizacao.cidade.estado.sigla;

                    const directionsService = new google.maps.DirectionsService();
                    directionsService.route({
                        origin,
                        destination,
                        provideRouteAlternatives: true,
                        travelMode: google.maps.DirectionsTravelMode.DRIVING,
                        unitSystem: google.maps.UnitSystem.METRIC,
                    }, (response, status) => {
                        if (status == google.maps.DirectionsStatus.OK) {
                            for (var i = 0, len = response.routes.length; i < len; i++) {
                                new google.maps.DirectionsRenderer({
                                    map: mapObject,
                                    directions: response,
                                    routeIndex: i
                                });
                            }
                        } else {
                            console.log("Unable to retrieve your route");
                        }
                    })

                    /*const distanceMatrixService = new google.maps.DistanceMatrixService();

                    distanceMatrixService.getDistanceMatrix({
                        origins: [origim],
                        destinations: [destination],
                        travelMode: 'DRIVING',
                        unitSystem: google.maps.UnitSystem.METRIC,
                    }, function(response, status) {
                        this.distanciaFixa = (response.rows[0].elements[0].distance.value / 1000).toFixed();
                        this.distancia = this.distanciaFixa * 2;
                    }.bind(this));*/
                }.bind(this));
            },
        },
    };
</script>

<style>
    .custom-table td{
        height: 30px;
    }
    .resposta-input{
        margin-top: 2px !important;
        margin-bottom: 2px !important;
    }
    .resposta-input .v-input__control .v-input__slot {
        min-height: auto !important;
        display: flex !important;
        align-items: center !important;
        padding-right: 4px !important;
    }

    .resposta-input .v-input__append-inner{
        margin-top: 3px !important;
    }

    .resposta-input .v-input__append-inner .v-icon{
        font-size: 18px;
    }

    .distancia-input input{
        text-align: right;
    }
    .stepper-fix{
        box-shadow: unset;
    }
    .stepper-fix .v-stepper__content{
        padding: unset;
    }
    .toggle_icon_active i{
        color: white !important;
    }
    #map {
        height: 300px;  /* The height is 400 pixels */
        width: 100%;  /* The width is the width of the web page */
    }
</style>