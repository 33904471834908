<template>
    <v-dialog v-model="dialog" persistent :fullscreen="$vuetify.breakpoint.xsOnly" scrollable content-class="custom-dialog" max-width="700">
        <v-card style="">
            <v-card-title>
                <span class="headline">Coordenadas</span>
            </v-card-title>
            <v-card-text class="pb-0 px-0 px-sm-6" style="display: flex; flex-direction: column; overflow: auto">
                <!--<v-switch v-model="fiscalizacao.financiamento.area.circular" class="ma-0 pa-0" label="É circular"></v-switch>-->
                <div>
                    <div id="map"></div>
                    <v-switch ref="custom_switch" dense v-model="isMarkersVisible" label="Marcações" style="display: none" class="my-1 mx-2 custom_switch" hide-details @change="setMarkersVisibility"></v-switch>
                </div>
                <div >
                    <v-chip v-for="(coordArea, index) in area.coordenadas" :key="index"
                            :class="'mr-2 mt-2 ' + (areaSelecionada === index ? 'primary--text teal lighten-5' : '')"
                            close small @click:close="removeArea(index)" @click="areaSelecionada = index">
                        Área {{ index + 1 }}
                    </v-chip>
                    <v-chip small class="mt-2" @click="addBlankArea" >
                        +
                    </v-chip>
                </div>

                <div class="mt-2 ml-2 ml-sm-0">
                    <div>
                        <v-btn small color="primary" dark class="pr-0"
                               @click="newCoordenadaDialog">
                            Adicionar Coordenada
                            <v-menu offset-y bottom left>
                                <template v-slot:activator="{ on }">
                                    <v-btn color="primary" small class="ml-3 px-0" min-width="30px" v-on="on">
                                        <v-icon>mdi-menu-down</v-icon>
                                    </v-btn>
                                </template>
                                <v-list class="py-1">
                                    <v-list-item @click="newCoordenadaLoteDialog" style="min-height: 30px">
                                        <v-list-item-title class="text-uppercase caption">Adicionar em Lote</v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </v-btn>
                    </div>
                </div>
                <div style="overflow: auto; display: flex">
                    <v-simple-table fixed-header height="100%" style="width: 100%">
                        <template v-slot:default>
                            <thead>
                            <tr>
                                <th></th>
                                <th class="text-left">Latitude</th>
                                <th class="text-left">Longitude</th>
                                <th class="text-left">Altitude</th>
                                <th class="text-center">Ação</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(coordenada, index) in coordenadasPorArea" :key="index" >
                                <td>
                                    <v-avatar color="#ea4335" size="24">
                                        <span class="white--text subtitle-2 font-weight-bold">{{index + 1}}</span>
                                    </v-avatar>
                                </td>
                                <td>{{ coordenada.lat + 'º'}}</td>
                                <td>{{ coordenada.lng + 'º'}}</td>
                                <td>{{ coordenada.alt + 'm'}}</td>
                                <td class="text-center">
                                    <div class="d-flex">
                                        <v-btn text icon color="primary" @click="editCoordenadaDialog(coordenada, index)">
                                            <v-icon>mdi-pencil</v-icon>
                                        </v-btn>

                                        <v-btn text icon color="secondary" @click="removeCoordenada(index)">
                                            <v-icon>mdi-close-circle</v-icon>
                                        </v-btn>
                                    </div>

                                </td>
                            </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </div>

            </v-card-text>
            <v-card-actions>
                <div class="pa-3" style="width: 100%">
                    <v-btn text @click="cancel">Cancelar</v-btn>

                    <div class="float-right">
                        <v-btn text color="primary" @click="save">Salvar</v-btn>
                    </div>

                </div>
            </v-card-actions>

        </v-card>
        <coordenada-dialog ref="coordenadaDialog" @saved="onCoordenadaSaved" @edited="onCoordenadaEdited" />
        <add-coordenada-lote ref="addCoordenadaDialog"  @saved="onCoordenadaLoteSaved" />
    </v-dialog>
</template>

<script>
    import GoogleMapsInit from "../../../assets/GoogleMapsInit";
    // @ts-ignore
    import CoordenadaDialog from './CoordenadaDialog';
    import {Area} from '../../../models/Area';
    import {AreaQueries} from '../../../queries/AreaQueries';
    // @ts-ignore
    import AddCoordenadaLote from "./AddCoordenadaLoteDialog";

    export default {
        name: 'EditCoordenadasDialog',
        components: {
            AddCoordenadaLote,
            CoordenadaDialog,
        },
        computed: {
            coordenadasPorArea(){
                return this.area.coordenadas[this.areaSelecionada]
            }
        },
        data() {
            return {
                googleMapsInstance: null,
                dialog: false,
                area: new Area(),
                areasPolygon: [],
                infowindows: [],
                brasilLocation: {lat: -15.35, lng: -56.06},
                isMarkersVisible: true,
                markers: [],
                areaSelecionada: 0,
            };
        },
        methods: {
            openDialog(area = null) {
                this.dialog = true;
                if (area != null) {
                    this.area = this._.cloneDeep(area);
                    this.area.coordenadas = JSON.parse(this.area.coordenadas);

                }
                this.initMap().then((google) => {
                    const bounds = new google.maps.LatLngBounds();
                    for(const [i, area] of this.area.coordenadas.entries()){
                        this.drawAreaPolygon(area, i)
                        for (let i = 0; i < area.length; i++) {
                            // @ts-ignore
                            bounds.extend(new google.maps.LatLng(area[i].lat, area[i].lng));
                        }
                    }

                    if(this.area.coordenadas.length === 0){
                        this.addBlankArea();
                    }

                    this.googleMapsInstance.fitBounds(bounds);
                });


            },
            closeDialog() {
                this.dialog = false;
                if (this.googleMapsInstance) {
                    this.googleMapsInstance = null;
                }
            },
            save() {
                let parseCoordenadas = (coordenadas) => {
                    if(coordenadas.length > 0 && coordenadas[0].length !== 0){
                        return JSON.stringify(coordenadas)
                    }
                    return null;
                }
                const coordenadasParsed = parseCoordenadas(this.area.coordenadas);
                this.$apollo.mutate({
                    mutation: AreaQueries.updateCoordenadas,
                    variables: {
                        fiscalizacaoId: this.$route.params.id,
                        coordenadas: coordenadasParsed,
                    },

                }).then((result) => {
                    this.$emit('saved', result.data.updateCoordenadas.coordenadas);
                    this.closeDialog();
                });
            },
            cancel() {
                // this.resetForm();
                this.closeDialog();
            },
            initMap() {
                return new Promise((resolve, reject) => {
                    if (this.googleMapsInstance) {
                        resolve();
                        return;
                    }

                    GoogleMapsInit().then((google) => {
                        this.googleMapsInstance = new google.maps.Map(document.getElementById('map'), {
                            zoom: 4,
                            center: this.brasilLocation,
                            mapTypeId: 'satellite',
                            disableDefaultUI: true,
                            zoomControl: true,
                            fullscreenControl: true
                        });

                        let centerControlDiv = document.createElement('div');
                        this.createToggleControl(centerControlDiv, this);

                        centerControlDiv.index = 1;
                        this.googleMapsInstance.controls[google.maps.ControlPosition.TOP_LEFT].push(centerControlDiv);
                        resolve(google);
                    }).catch(error => {
                        reject(error);
                    });
                });
            },
            newCoordenadaDialog() {
                this.$refs.coordenadaDialog.openDialog();
            },
            newCoordenadaLoteDialog(){
                this.$refs.addCoordenadaDialog.openDialog();
            },
            editCoordenadaDialog(coordenada, index) {
                coordenada.index = index;
                this.$refs.coordenadaDialog.openDialog(coordenada);
            },
            removeCoordenada(index) {
                this.coordenadasPorArea.splice(index, 1);

                this.updateAreaPolygon(this.coordenadasPorArea, this.areaSelecionada);
            },
            onCoordenadaSaved(coordenada) {
                this.coordenadasPorArea.push(coordenada);

                this.updateAreaPolygon(this.coordenadasPorArea, this.areaSelecionada);
            },
            onCoordenadaEdited(coordenada) {
                const coordenadaExisted = this.coordenadasPorArea[coordenada.index];
                coordenadaExisted.lat = coordenada.lat;
                coordenadaExisted.lng = coordenada.lng;
                coordenadaExisted.alt = coordenada.alt;

                this.coordenadasPorArea.splice(coordenada.index, 1, coordenadaExisted)

                this.updateAreaPolygon(this.coordenadasPorArea, this.areaSelecionada);
            },
            onCoordenadaLoteSaved(coordenadas){
                this.$set(this.area.coordenadas, this.areaSelecionada, this.coordenadasPorArea.concat(coordenadas))
                for(let i = 0; i < this.coordenadasPorArea.length; i++){
                    this.coordenadasPorArea[i].index = i;
                }
                this.updateAreaPolygon(this.coordenadasPorArea, this.areaSelecionada);
            },

            updateAreaPolygon(area, index) {
                this.areasPolygon[index].setPath(area);
                const bounds = this.getBoundsByArea(area);
                this.drawMarkersByArea(area, index);

                if (area.length > 2) {
                    this.calculateAreaByArea(bounds, index);
                }

                this.updateBounds();
            },

            drawAreaPolygon(area, index){
                let areaPolygon = new google.maps.Polygon({
                    strokeColor: '#FF0000',
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillColor: '#FF0000',
                    fillOpacity: 0.35,
                });
                areaPolygon.setMap(this.googleMapsInstance);

                google.maps.event.addListener(areaPolygon, 'click', function (event) {
                    this.infowindows[index].open(this.googleMapsInstance)
                }.bind(this));

                this.areasPolygon[index] = areaPolygon;

                if(area){
                    this.updateAreaPolygon(area, index);
                }
            },

            setMarkersVisibility(value) {
                if (value) {
                    this.drawMarkers();
                } else {
                    this.clearMarkers();
                }
            },

            drawMarkersByArea(area, index) {
                this.clearMarkersByArea(index);
                for (let i = 0; i < area.length; i++) {
                    const marker = new google.maps.Marker({
                        // @ts-ignore
                        position: new google.maps.LatLng(area[i].lat, area[i].lng),
                        title: 'Ponto ' + (i + 1),
                        label: {text: (i + 1) + '', fontWeight: '700', color: 'white'},
                        map: this.googleMapsInstance,
                    });
                    if(this.markers[index] === undefined){
                        this.markers[index] = [];
                    }
                    this.markers[index].push(marker);
                }
            },

            drawMarkers(){
                for(let i = 0; i < this.area.coordenadas.length; i++){
                    this.drawMarkersByArea(this.area.coordenadas[i], i)
                }
            },

            clearMarkersByArea(areaIndex) {
                if(this.markers[areaIndex] === undefined){
                    return
                }
                for (let i = 0; i < this.markers[areaIndex].length; i++) {
                    this.markers[areaIndex][i].setMap(null);
                }
                this.markers[areaIndex] = [];
            },

            clearMarkers() {
                for(let i = 0; i < this.area.coordenadas.length; i++){
                    this.clearMarkersByArea(i)
                }
            },
            getBoundsByArea(area) {
                const bounds = new google.maps.LatLngBounds();
                for (let i = 0; i < area.length; i++) {
                    // @ts-ignore
                    bounds.extend(new google.maps.LatLng(area[i].lat, area[i].lng));
                }
                return bounds;
            },
            updateBounds() {
                // @ts-ignore
                const bounds = new google.maps.LatLngBounds();

                if (this.area.coordenadas.length === 0) {
                    this.googleMapsInstance.setCenter(this.brasilLocation);
                    this.googleMapsInstance.setZoom(4);
                } else {
                    // @ts-ignore
                    for (let i = 0; i < this.area.coordenadas.length; i++) {
                        for(let f = 0; f < this.area.coordenadas[i].length; f++){
                            // @ts-ignore
                            bounds.extend(new google.maps.LatLng(this.area.coordenadas[i][f].lat, this.area.coordenadas[i][f].lng));
                        }
                    }
                    this.googleMapsInstance.fitBounds(bounds);
                }

                return bounds;
            },

            calculateAreaByArea(bounds, index) {
                if(this.infowindows[index] === undefined || this.infowindows[index] === null){
                    // @ts-ignore
                    this.infowindows[index] = new google.maps.InfoWindow();
                }

                const area = google.maps.geometry.spherical.computeArea(this.areasPolygon[index].getPath());
                const areaInHectare = area / 10000;
                this.infowindows[index].setContent('Área: ' + areaInHectare.toFixed(2) + ' Ha.');
                this.infowindows[index].setPosition(bounds.getCenter());
                this.infowindows[index].open(this.googleMapsInstance);
            },

            createToggleControl(controlDiv, context) {
                controlDiv.style.marginTop = "10px"
                controlDiv.style.marginLeft = "10px"
                let controlUI = document.createElement('div');
                controlUI.style.backgroundColor = '#fff';
                controlUI.style.border = '2px solid #fff';
                controlUI.style.borderRadius = '3px';
                controlUI.style.boxShadow = '0 2px 6px rgba(0,0,0,.3)';
                controlUI.style.marginBottom = '22px';
                controlUI.style.textAlign = 'center';
                //let switcher = context.$el.querySelector(".custom_switch")
                let switcher = this.$refs.custom_switch.$el;

                switcher.style.display = 'block';
                controlUI.appendChild(switcher);

                controlDiv.appendChild(controlUI);

            },
            addBlankArea(){
                this.area.coordenadas.push([]);
                this.areaSelecionada = this.area.coordenadas.length - 1
                this.drawAreaPolygon(null, this.areaSelecionada)
            },
            removeArea(index) {
                this.$dialog.confirm({
                    text: 'Deseja realmente apagar esta área?',
                    title: 'Atenção',
                }).then((result) => {
                    if (result) {
                        this.area.coordenadas.splice(index, 1)

                        //this.areasPolygon.splice(index, 1)
                        this.updateAreaPolygon([], index)
                        this.clearMarkersByArea(index)
                        if(this.areaSelecionada === index && this.areaSelecionada > 0){
                            this.areaSelecionada--;
                        }
                        if(this.area.coordenadas.length === 0){
                            this.addBlankArea()
                        }
                    }
                });
            },
        },
    };
</script>

<style >
    .custom-dialog #map {
        height: 250px;  /* The height is 400 pixels */
        width: 100%;  /* The width is the width of the web page */
    }

    @media(min-width: 1440px) {
        .custom-dialog{
            max-width: 600px !important;
        }
        .custom-dialog #map {
            height: 300px;  /* The height is 400 pixels */
        }
    }
</style>
