<template>
    <v-dialog v-model="dialog" persistent max-width="400px">
        <v-card v-if="dialog">
            <v-card-text>
                <v-form ref="form1" v-model="form1Valid" lazy-validation>
                    <v-row>
                        <v-col>
                            <v-select v-model="tipoId"
                                      :items="tipos" item-text="nome" item-value="id"
                                      label="Tipo" single-line :rules="requiredRules" required
                            >
                                <template slot="item" slot-scope="data">
                                    <!-- HTML that describe how select should render items when the select is open -->
                                    <div>
                                        <v-avatar size="32" class="mr-4">
                                            <v-icon small class="light-green lighten-3" v-text="getItemIcon(data.item)"></v-icon>
                                        </v-avatar>
                                        {{ data.item.nome }}
                                    </div>
                                </template>
                            </v-select>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <div class="pa-3" style="width: 100%">
                    <v-btn text @click="closeDialog">Cancelar</v-btn>

                    <div class="float-right">
                        <v-btn text color="primary" @click="save">
                            Salvar
                        </v-btn>
                    </div>

                </div>
            </v-card-actions>
            <v-overlay absolute :value="loading" color="white">
                <v-progress-circular :size="100" :width="7" color="deep-orange" indeterminate></v-progress-circular>
            </v-overlay>
        </v-card>
    </v-dialog>
</template>

<script>
    import {FiscalizacaoQueries} from "../../../queries/FiscalizacaoQueries";
    import gql from "graphql-tag";
    import {getIconByFiscalizacaoTipo} from '../../../assets/FiscaizacaoUtil'

    export default {
        name: "AlteraTipoDialog",
        data() {
            return {
                dialog: false,
                fiscalizacaoId: null,
                form1Valid: true,
                requiredRules: [
                    (v) => !!v || 'Campo obrigatório',
                ],
                loading: false,
                tipos: [],
                tipoId: null,
            }
        },
        methods: {
            openDialog(fiscalizacaoId) {
                this.dialog = true;
                this.fiscalizacaoId = fiscalizacaoId;
                this.getTipos();
            },
            closeDialog() {
                this.dialog = false;
            },
            save(){
                if(!this.$refs.form1.validate()){
                    return false
                }
                this.loading = true;
                this.$apollo.mutate({
                    mutation: gql`mutation ($fiscalizacaoId:Int!, $tipoId:Int!){
                        alteraTipoFiscalizacao(fiscalizacaoId: $fiscalizacaoId, tipoId: $tipoId){
                            id
                            nome
                        }
                    }`,
                    variables: {
                        fiscalizacaoId: this.fiscalizacaoId,
                        tipoId: this.tipoId
                    },
                }).then((result) => {
                    this.$emit('saved', Object.assign({}, result.data.alteraTipoFiscalizacao));
                    this.loading = false;
                    this.closeDialog();
                });
            },
            getTipos() {
                this.$apollo.query({query: FiscalizacaoQueries.getTipos}).then((result) => {
                    this.tipos = result.data.tipos.map((tipo) => {
                        tipo.nome = tipo.nome.toUpperCase();
                        return tipo;
                    });
                });
            },
            getItemIcon(tipo){
                return getIconByFiscalizacaoTipo(tipo);
            },
        }
    }
</script>

<style scoped>

</style>