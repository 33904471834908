<template>
    <v-dialog v-model="dialog" persistent max-width="400px">
        <v-card v-if="dialog">
            <v-card-title>
                <span class="headline">Contato</span>
            </v-card-title>
            <v-card-text>
                <v-form ref="form1" v-model="form1Valid" lazy-validation>
                    <v-row>
                        <v-col cols="6">
                            <v-text-field v-model="contato.numero" label="Número"
                                          maxlength="30" placeholder=" " :rules="requiredRules" v-mask="['(##) ####-####', '(##) #####-####']"
                                          required></v-text-field>
                        </v-col>

                        <v-col cols="6">
                            <v-switch v-model="contato.whatsapp">
                                <template v-slot:label>
                                    Whatsapp<v-icon class="ml-2" color="green">mdi-whatsapp</v-icon>
                                </template>
                            </v-switch>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field v-model="contato.observacao" label="Observação"
                                          maxlength="200" placeholder=" "></v-text-field>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <div class="pa-3" style="width: 100%">
                    <v-btn text @click="closeDialog">Cancelar</v-btn>

                    <div class="float-right">
                        <v-btn color="primary" text depressed @click="save" >
                            Salvar
                        </v-btn>
                    </div>

                </div>
            </v-card-actions>
            <v-overlay absolute :value="loading" color="white">
                <v-progress-circular :size="100" :width="7" color="deep-orange" indeterminate></v-progress-circular>
            </v-overlay>
        </v-card>
    </v-dialog>
    
</template>

<script>
    import {FiscalizacaoQueries} from '../../../queries/FiscalizacaoQueries';
    export default {
        name: "AddContatoDialog",
        data() {
            return {
                dialog: false,
                fiscalizacao: null,
                contato: {
                    numero: null,
                    observacao: null,
                    whatsapp: false,
                },
                loading: false,
                form1Valid: true,
                editMode: false,
                requiredRules: [
                    (v) => !!v || 'Campo obrigatório',
                ],
            }
        },
        methods: {
            openDialog(fiscalizacao, contato) {
                this.dialog = true;
                this.fiscalizacao = fiscalizacao;
                if(contato){
                    this.editMode = true;
                    this.contato = this._.cloneDeep(contato);
                }
            },
            closeDialog() {
                this.dialog = false;
                this.editMode = false;
                this.contato = {
                    numero: null,
                    observacao: null,
                    whatsapp: false,
                }
            },
            save(){
                if(!this.$refs.form1.validate()){
                    return false
                }
                this.loading = true;
                if(!this.editMode){
                    this.$apollo.mutate({
                        mutation: FiscalizacaoQueries.addContatoToFiscalizacao,
                        variables: {
                            contato: {
                                fiscalizacao: {
                                    id: this.fiscalizacao.id
                                },
                                numero: this.contato.numero,
                                observacao: this.contato.observacao,
                                whatsapp: this.contato.whatsapp
                            },
                        },
                    }).then((result) => {
                        this.loading = false;
                        this.$emit('saved', Object.assign({}, result.data.addContatoToFiscalizacao));
                        this.closeDialog();
                    }).catch(()=>{
                        this.loading = false;
                    });
                }else{
                    this.$apollo.mutate({
                        mutation: FiscalizacaoQueries.editContato,
                        variables: {
                            id: this.contato.id,
                            contato: {
                                numero: this.contato.numero,
                                observacao: this.contato.observacao,
                                whatsapp: this.contato.whatsapp
                            },
                        },
                    }).then((result) => {
                        this.loading = false;
                        this.$emit('edited', Object.assign({}, result.data.editContato), this.contato.index);
                        this.closeDialog();
                    }).catch(()=>{
                        this.loading = false;
                    });
                }

            }
        }
    }
</script>

<style scoped>

</style>