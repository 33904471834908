    <template>
    <v-dialog v-model="dialog" persistent scrollable max-width="500px">
        <v-card v-if="dialog">
            <v-card-title>Arquivo</v-card-title>
            <v-card-text class="pb-0">
                <v-row no-gutters>
                    <!--<v-col>{{arquivo.nome}}</v-col>-->
                    <v-list dense class="py-0" max-width="450">
                        <v-list-item class="px-0">
                            <v-list-item-avatar class="my-0" >
                                <v-icon style="opacity: 1" :color="getIconColor(arquivo.nome)">
                                    {{getIcon(arquivo.nome)}}
                                </v-icon>
                            </v-list-item-avatar>

                            <v-list-item-content class="py-2">
                                <v-list-item-title class="font-weight-regular"  :title="arquivo.nome">
                                    {{arquivo.nome}}
                                </v-list-item-title>
                                <v-list-item-subtitle class="font-weight-regular" style="font-size: 10px">
                                    Criado em {{arquivo.createdAt | moment('DD-MM-YYYY [às] HH:mm')}}
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-row>
                <v-row>
                    <v-col>
                        <v-form ref="form1" v-model="form1Valid" lazy-validation>
                            <v-autocomplete v-model="arquivo.tags" :items="tags"
                                    class="tags-input" label="Tags" placeholder=" "
                                    dense hide-details chips small-chips
                                    multiple outlined deletable-chips clearable
                                    item-text="nome" item-value="id" return-object
                                    :rules="arquivo.isInvalid ? [] : [rules.required]" required
                                    :disabled="arquivo.isInvalid"
                            ></v-autocomplete>
                        </v-form>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <div class="pa-3" style="width: 100%">
                    <v-btn text @click="closeDialog">Cancelar</v-btn>

                    <div class="float-right">
                        <v-btn text color="primary" @click="atualizar" >
                            Salvar
                        </v-btn>
                    </div>

                </div>
            </v-card-actions>
            <v-overlay absolute :value="loading" color="white">
                <v-progress-circular :size="100" :width="7" color="deep-orange" indeterminate></v-progress-circular>
            </v-overlay>
        </v-card>
    </v-dialog>
</template>

<script>
    import gql from "graphql-tag";
    import {getIcon, getExtensionFile, getIconColor} from "../../../assets/FileUtils"

    export default {
        name: "EditarArquivoDialog",
        data() {
            return {
                dialog: false,
                loading: false,
                arquivo: null,
                tags: [],
                form1Valid: true,
                rules: {
                    required: (v) => {
                        return (!!v && v.length > 0) || 'Campo Obrigatório.'
                    },
                },
            }
        },
        methods: {
            getIcon, getIconColor, getExtensionFile,
            openDialog(arquivo) {
                this.dialog = true;
                this.arquivo = this._.cloneDeep(arquivo);
                this.getTags();
            },
            closeDialog() {
                this.dialog = false;
                this.totalEnviado = 0;
            },
            getTags(){
                this.loading = true;
                this.$apollo.query({
                    query: gql`query($categoria: String!) {
                        tags:tagsPorTipo(categoria: $categoria){
                            id
                            nome
                        }
                    }`,
                    variables: {
                        categoria: 'fiscalizacao'
                    }
                }).then((result) => {
                    this.tags = result.data.tags;
                }).finally(()=>{
                    this.loading = false;
                })
            },
            atualizar(){
                if(!this.$refs.form1.validate()){
                    return false
                }
                this.loading = true;
                this.$apollo.mutate({
                    mutation: gql`mutation ($arquivoId:Int!, $tags:[TagInput]!){
                        arquivo:editArquivoTags(arquivoId: $arquivoId, tags: $tags){
                            id
                            nome
                            createdAt
                            tags{
                                id
                                nome
                            }
                        }
                    }`,
                    variables: {
                        arquivoId: this.arquivo.id,
                        tags: this.arquivo.tags.map(tag => {
                            return {id:tag.id}
                        })
                    },
                }).then((result) => {
                    this.$emit('edited', Object.assign({}, result.data.arquivo), this.arquivo.index);
                    this.closeDialog();
                }).finally(()=>{
                    this.loading = false;
                });
            },
        }
    }
</script>

<style scoped>

</style>