<template>
    <div>
        <v-card outlined class="">
            <v-card-title class="pb-0">
                Garantias de Financiamento
                <v-btn rounded small color="primary" class="mt-2 ml-3" :disabled="finalizado || fiscalizacao.dataCancelamento" @click="newGarantiaDialog">Nova Garantia</v-btn>
            </v-card-title>
            <v-card-text class="black--text">
                <v-simple-table class="mt-3" style="width: 100%" v-if="fiscalizacao.financiamento.garantias.length > 0">
                    <thead>
                    <tr>
                        <th class="text-left">Descrição</th>
                        <th class="text-left">Qualificação</th>
                        <th class="text-left">Quantidade</th>
                        <th class="text-left">Valor</th>
                        <th class="text-left">Ação</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(garantia, index) in fiscalizacao.financiamento.garantias" :key="index">
                        <td>{{garantia.descricao.descricao}}</td>
                        <td>{{garantia.qualificacao ? garantia.qualificacao.descricao : ''}}</td>
                        <td>{{formatarNumero(garantia.quantidade)}} {{garantia.quantidadeUnidadeMedida ? garantia.quantidadeUnidadeMedida.sigla : ''}}</td>
                        <td>{{formatGarantiaValor(garantia.valor)}}</td>
                        <td>
                            <v-btn text icon color="grey" @click="editGarantiaDialog(garantia, index)" :disabled="finalizado || fiscalizacao.dataCancelamento">
                                <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                            <v-btn text icon color="red" :disabled="finalizado || fiscalizacao.dataCancelamento" @click="removeGarantia(garantia, index)">
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </td>
                    </tr>
                    </tbody>
                </v-simple-table>
                <v-alert v-else color="yellow" dense icon="mdi-alert" class="mt-4 mx-4">
                    Nenhuma garantia adicionada. Clique no botão acima para inserir uma nova garantia.
                </v-alert>
            </v-card-text>
        </v-card>
        <garantia-dialog ref="garantiaDialog" :garantias="fiscalizacao.financiamento.garantias" @saved="onGarantiaSaved" @edited="onGarantiaEdited" />
    </div>
</template>

<script>
    // @ts-ignore
    import GarantiaDialog from '../../garantia/GarantiaDialog';
    import {FinanciamentoGarantiaQueries} from "../../../../../queries/FinanciamentoGarantiaQueries";
    import numeral from "numeral";

    export default {
        name: "GarantiasFinanciamentoFragment",
        props: {
            fiscalizacao: null,
            finalizado: null,
        },
        components: {
            GarantiaDialog
        },
        methods: {
            newGarantiaDialog() {
                this.$refs.garantiaDialog.openDialog(this.fiscalizacao.financiamento);
            },
            editGarantiaDialog(garantia, index) {
                garantia.index = index;
                this.$refs.garantiaDialog.openDialog(this.fiscalizacao.financiamento, garantia);
            },
            removeGarantia(garantia, index) {
                this.$dialog.confirm({
                    text: 'Deseja realmente apagar esta garantia?',
                    title: 'Atenção',
                }).then((result) => {
                    if (result) {
                        this.$apollo.mutate({
                            mutation: FinanciamentoGarantiaQueries.delete,
                            variables: {id: garantia.id},
                        }).then(() => {
                            this.fiscalizacao.financiamento.garantias.splice(index, 1);
                        });
                    }
                });
            },
            onGarantiaSaved(garantia) {
                this.fiscalizacao.financiamento.garantias.push(garantia);
            },
            onGarantiaEdited(garantia, index) {
                let garantiaExisted = this.fiscalizacao.financiamento.garantias[index];
                garantiaExisted = Object.assign(garantiaExisted, garantia);
            },
            formatGarantiaValor(valor){
                if (valor > 0) {
                    return 'R$ ' + numeral(valor).format('0,0.00');
                } else {
                    return 'xxxxx';
                }

            },
            formatarNumero(value){
                return numeral(value).format('0,0.00');
            },
        }
    }
</script>

<style scoped>

</style>