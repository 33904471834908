<template>
    <v-dialog v-model="dialog" persistent max-width="600px">
        <v-card v-if="dialog">
            <v-card-title>
                <span class="headline">Coordenadas</span>
            </v-card-title>
            <v-card-text>
                <v-form ref="formCoordenada" v-model="formCoordenada" lazy-validation>
                <v-textarea outlined rows="4" label="Coordenadas"
                            v-model="coordenadasLote"
                            :rules="requiredRules" required>
                </v-textarea>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <div class="pa-3" style="width: 100%">
                    <v-btn text @click="closeDialog">Cancelar</v-btn>

                    <div class="float-right">
                        <v-btn color="primary" text @click="save">Salvar</v-btn>
                    </div>

                </div>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        name: "AddCoordenadaLote",
        data() {
            return {
                dialog: false,
                formCoordenada: true,
                requiredRules: [
                    (v) => !!v || 'Campo obrigatório',
                ],
                coordenadasLote: ''
            }
        },
        methods: {
            openDialog() {
                this.dialog = true;
            },
            closeDialog() {
                this.$refs.formCoordenada.resetValidation();
                this.coordenadasLote = ''
                this.dialog = false;
            },
            save(){
                if (!this.$refs.formCoordenada.validate()) {
                    return;
                }
                let lines = this.coordenadasLote.split(/\r?\n/)
                let coordenadas = [];
                for(let line of lines){
                    let campos = line.split(/\s+/).filter(e => e.trim() !== '');
                    if(campos.length !== 5){
                        this.$dialog.error({
                            title:'Formato inválido',
                            text: 'Verifique o formato das coordenadas',
                            actions:{ok:'ok'}})
                        break;
                    }
                    let coordenada = {
                        lat: campos[2].replace(',', '.'),
                        lng: campos[3].replace(',', '.'),
                        alt: campos[4].replace(',', '.')
                    };

                    coordenadas.push(this.castValues(coordenada));
                }
                this.$emit('saved', coordenadas);
                this.closeDialog();
            },
            castValues(coordenada) {
                const castedCoordenada = Object.assign({}, coordenada);
                castedCoordenada.lat = parseFloat(coordenada.lat);
                castedCoordenada.lng = parseFloat(coordenada.lng);
                castedCoordenada.alt = parseFloat(coordenada.alt);

                return castedCoordenada;
            },
        }
    }
</script>

<style scoped>

</style>