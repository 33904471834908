<template>
    <div>
        <v-subheader class="text-uppercase caption pt-4 ">
            <span class="blue-grey--text">Contatos</span>
            <v-btn icon x-small color="blue-grey darken-1" class="ml-1"
                   @click.stop="newContatoDialog">
                <v-icon small>mdi-plus-circle</v-icon>
                <template v-slot:loader>
                    <v-progress-circular
                            indeterminate width="2" size="14"
                            color="blue-grey "
                    ></v-progress-circular>
                </template>
            </v-btn>
        </v-subheader>
        <v-list dense class="pa-0" color="transparent" v-if="fiscalizacao.contatos && fiscalizacao.contatos.length > 0">
            <template v-for="(contato, index) in fiscalizacao.contatos">
                <v-hover v-slot:default="{ hover }">
                    <v-list-item :key="index" @click="" :title="getContatoTitle(contato)">
                        <v-list-item-avatar class="my-0" >
                            <v-icon v-if="contato.whatsapp" color="blue-grey lighten-1">mdi-whatsapp</v-icon>
                            <v-icon v-else color="blue-grey lighten-1">mdi-phone</v-icon>
                        </v-list-item-avatar>

                        <v-list-item-content class="py-2">
                            <v-list-item-title class="font-weight-regular">
                                {{contato.numero}}
                            </v-list-item-title>
                            <v-list-item-subtitle class="font-weight-regular" style="font-size: 10px">
                                {{contato.observacao}}
                            </v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-action class="my-0" v-if="hover" title="">
                            <div>
                                <v-btn v-if="contato.whatsapp" icon
                                       color="green" dark
                                       @mousedown.stop @touchstart.native.stop
                                       :href="'https://web.whatsapp.com/send?phone=55'+ parseNumero(contato.numero)"
                                       target="_blank">
                                    <v-icon small>mdi-forum</v-icon>
                                </v-btn>
                                <v-btn icon
                                       @click.stop="editContatoDialog(contato, index)"
                                       @mousedown.stop @touchstart.native.stop>
                                    <v-icon small>mdi-pencil</v-icon>
                                </v-btn>
                                <v-btn icon color="red" dark
                                       @mousedown.stop @touchstart.native.stop
                                       @click.stop="removeContato(contato, index)">
                                    <v-icon small>mdi-delete</v-icon>
                                </v-btn>
                                <!--<v-menu bottom left >
                                    <template v-slot:activator="{ on }">
                                        <v-btn icon v-on="on">
                                            <v-icon small>mdi-dots-vertical</v-icon>
                                        </v-btn>
                                    </template>

                                    <v-list>
                                        <v-list-item @click="editContatoDialog(contato, index)">
                                            <v-list-item-title>Editar</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item @click="removeContato(contato, index)">
                                            <v-list-item-title class="red&#45;&#45;text">Apagar</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>-->
                            </div>
                        </v-list-item-action>
                    </v-list-item>
                </v-hover>
            </template>
        </v-list>
        <div v-else class="body-2 text-center pa-2 mx-4 mt-2 blue-grey--text lighten-2">
            <span class="overline font-weight-medium">Nenhum contato adicionado.</span><br/>
            <div class="overline mt-1 mb-2" style="line-height: 120%">
                Crie um novo contato clicando no <v-icon color="blue-grey lighten-2" x-small>mdi-plus-circle</v-icon> acima.</div>
        </div>
        <add-contato-dialog ref="addContatoDialog" @saved="onContatoSaved" @edited="onContatoEdited" />
    </div>
</template>

<script>
    import {FiscalizacaoQueries} from "../../../../../queries/FiscalizacaoQueries";
    // @ts-ignore
    import AddContatoDialog from "../../AddContatoDialog";

    export default {
        name: "ContatosFragment",
        components: {AddContatoDialog},
        props: {
            fiscalizacao: null,
        },
        methods: {
            getContatoTitle(contato){
                let text = contato.numero;
                if(contato.observacao){
                    text += '\n' + contato.observacao;
                }
                return text;
            },
            parseNumero(numero){
                return numero.replace(/[^\d]/g, '');
            },
            newContatoDialog(){
                this.$refs.addContatoDialog.openDialog(this.fiscalizacao);
            },
            editContatoDialog(contato, index) {
                contato.index = index;
                this.$refs.addContatoDialog.openDialog(this.fiscalizacao, contato);
            },
            onContatoSaved(contato){
                this.fiscalizacao.contatos.push(contato);
            },
            onContatoEdited(contato, index){
                let contatoExisted = this.fiscalizacao.contatos[index];
                contatoExisted = Object.assign(contatoExisted, contato);
            },
            removeContato(contato, index) {
                this.$dialog.confirm({
                    text: 'Deseja realmente apagar este contato?',
                    title: 'Atenção',
                }).then((result) => {
                    if (result) {
                        this.$apollo.mutate({
                            mutation: FiscalizacaoQueries.removeContato,
                            variables: {id: contato.id},
                        }).then(() => {
                            this.fiscalizacao.contatos.splice(index, 1);
                        });
                    }
                });
            },
        }
    }
</script>

<style scoped>

</style>