<template>
    <div>
        <v-card outlined class="">
            <v-card-title class="pb-0">
                Objetos Financiados
                <v-btn small color="primary" :disabled="finalizado || fiscalizacao.dataCancelamento" rounded  class="mt-2 ml-3 pr-0" style="overflow: hidden"
                       @click="newObjetoDialog">
                    Novo Objeto
                    <v-menu offset-y bottom left>
                        <template v-slot:activator="{ on }">
                            <v-btn color="primary" tile small :disabled="finalizado || fiscalizacao.dataCancelamento"
                                   class="ml-3 px-0"
                                   min-width="28px" v-on="on">
                                <v-icon class="ml-n1">mdi-menu-down</v-icon>
                            </v-btn>
                        </template>
                        <v-list class="py-1">
                            <v-list-item @click="newObjetoLoteDialog" style="min-height: 30px">
                                <v-list-item-title class="text-uppercase caption">Adicionar em Lote</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </v-btn>
            </v-card-title>
            <v-card-text class="black--text">
                <v-simple-table class="mt-3" style="width: 100%" v-if="fiscalizacao.financiamento.objetos.length > 0">
                    <thead>
                    <tr>
                        <th class="text-left">Descrição</th>
                        <th class="text-left">Qualificação</th>
                        <th class="text-left">Quantidade</th>
                        <th class="text-left">Valor</th>
                        <th class="text-left">Ação</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(objeto, index) in fiscalizacao.financiamento.objetos" :key="index">
                        <td>{{objeto.descricao.descricao}}</td>
                        <td>{{objeto.qualificacao ? objeto.qualificacao.descricao : ''}}</td>
                        <!--<td>{{formatarNumero(objeto.quantidade)}} {{objeto.quantidadeUnidadeMedida ? objeto.quantidadeUnidadeMedida.sigla : ''}}</td>-->
                        <td>{{formatarNumero(objeto.quantidade)}} {{objeto.quantidadeUnidadeMedida ? objeto.quantidadeUnidadeMedida.sigla : ''}}</td>
                        <td>{{objeto.valor | formatNumber}}</td>
                        <td>
                            <v-btn text icon color="grey" @click="editObjetoDialog(objeto, index)" :disabled="finalizado || fiscalizacao.dataCancelamento">
                                <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                            <v-btn text icon color="red" :disabled="finalizado || fiscalizacao.dataCancelamento" @click="removeObjeto(objeto, index)">
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </td>
                    </tr>
                    </tbody>
                </v-simple-table>
                <v-alert v-else color="yellow" dense icon="mdi-alert" class="mt-4 mx-4">
                    Nenhum objeto adicionado. Clique no botão acima para inserir um novo objeto.
                </v-alert>
            </v-card-text>
        </v-card>
        <objetivo-dialog ref="objetoDialog" :objetivos="fiscalizacao.financiamento.objetos" @saved="onObjetoSaved" @edited="onObjetoEdited" />
        <add-objeto-lote ref="addObjetoLote" @saved="onObjetoLoteSaved" />
    </div>

</template>

<script>
    import numeral from "numeral";
    import {ObjetivoFinanciadoQueries} from "../../../../../queries/ObjetivoFinanciadoQueries";
    // @ts-ignore
    import ObjetivoDialog from '../../objetivo/ObjetivoDialog';
    // @ts-ignore
    import AddObjetoLote from "../../AddObjetoLote";

    export default {
        name: "ObjetosFinanciadosFragment",
        props: {
            fiscalizacao: null,
            finalizado: null,
        },
        components: {
            AddObjetoLote,
            ObjetivoDialog
        },
        methods: {
            formatarNumero(value) {
                //return numeral(value).format('0,0.00');
                numeral.locale('pt-br');
                return numeral(value).format('0,0.00');
            },
            newObjetoDialog() {
                this.$refs.objetoDialog.openDialog(this.fiscalizacao.financiamento);
            },
            editObjetoDialog(objetivo, index) {
                objetivo.index = index;
                this.$refs.objetoDialog.openDialog(this.fiscalizacao.financiamento, objetivo);
            },
            removeObjeto(objeto, index) {
                this.$dialog.confirm({
                    text: 'Deseja realmente apagar este objeto?',
                    title: 'Atenção',
                }).then((result) => {
                    if (result) {
                        this.$apollo.mutate({
                            mutation: ObjetivoFinanciadoQueries.delete,
                            variables: {id: objeto.id},
                        }).then(() => {
                            this.fiscalizacao.financiamento.objetos.splice(index, 1);
                        });
                    }
                });
            },
            newObjetoLoteDialog(){
                this.$refs.addObjetoLote.openDialog(this.fiscalizacao);
            },
            onObjetoSaved(objeto) {
                this.fiscalizacao.financiamento.objetos.push(objeto);
            },
            onObjetoEdited(objeto, index) {
                let objetoExisted = this.fiscalizacao.financiamento.objetos[index];
                objetoExisted = Object.assign(objetoExisted, objeto);
            },
            onObjetoLoteSaved(objetos) {
                this.fiscalizacao.financiamento.objetos.push(...objetos);
            },
        }
    }
</script>

<style scoped>

</style>