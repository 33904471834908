<template>
    <v-dialog v-model="dialog" scrollable persistent max-width="1000px" >
        <v-card v-if="dialog">
            <v-card-title>
                <span class="headline">Objetos</span>
            </v-card-title>
            <v-card-text>
                <v-form ref="formObjeto" v-model="formObjeto" lazy-validation>
                    <v-row>
                        <v-col cols="4">
                            <v-text-field label="Quantidade" placeholder=" " ref="quantidade"
                                          v-model="quantidade"
                                          v-money="quant" clearable @click:clear="clearQuantidade"
                                          :rules="requiredRules" required></v-text-field>
                        </v-col>

                        <v-col cols="3">
                            <v-select label="Un. Medida" placeholder=" "
                                      v-model="quantidadeUnidadeMedida" clearable
                                      return-object :items="unidadesMedida" item-text="sigla"
                            ></v-select>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="pb-0">
                            <v-textarea outlined ref="objetosLote" rows="4" label="Objetos"
                                        v-model="objetosLote"
                                        :rules="requiredRules" required>
                            </v-textarea>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col class="text-center">
                            <v-btn color="primary" small rounded class="mt-n2"
                                   @click="process" :disabled="processing">
                                <v-icon left :class="processing ? 'custom-loader':''">mdi-cog</v-icon>
                                Processar
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-form>
                <v-form ref="formObjeto2" v-model="formObjeto2" lazy-validation>
                    <v-row no-gutters>
                        <v-col>
                            <v-simple-table>
                                <thead>
                                <tr>
                                    <th class="text-left">Descrição</th>
                                    <th class="text-left">Qualificação</th>
                                    <th class="text-left">Quantidade</th>
                                    <th class="text-left">Valor</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(objeto, index) in objetos" :key="index" >
                                    <td>
                                        <div class="d-flex">
                                            <v-autocomplete v-model="objeto.descricao" dense
                                                            :items="descricoes" item-text="descricao" return-object
                                                            hide-details outlined filled
                                                            :rules="requiredSelectRules"></v-autocomplete>
                                            <v-btn v-if="!objeto.descricao.id" text icon color="primary"
                                                   @click="newDescricaoDialog(objeto.descricao, index)">
                                                <v-icon>mdi-plus</v-icon>
                                            </v-btn>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="d-flex">
                                            <v-autocomplete v-model="objeto.qualificacao" dense
                                                            :items="qualificacoes" item-text="descricao" return-object
                                                            hide-details outlined filled></v-autocomplete>
                                            <v-btn v-if="objeto.qualificacao === undefined" text icon color="primary"
                                                   @click="newQualificacaoDialog(objeto.qualificacao, index)">
                                                <v-icon>mdi-plus</v-icon>
                                            </v-btn>
                                        </div>
                                    </td>
                                    <td class="text-no-wrap">
                                        <span>{{quantidade}}</span>
                                        <span v-if="quantidadeUnidadeMedida">
                                                {{quantidadeUnidadeMedida.sigla}}
                                        </span>
                                    </td>
                                    <td>
                                        <v-text-field prefix="R$" ref="valor" dense
                                                      v-model="objeto.valor" v-money="money"
                                                      hide-details outlined filled style="text-align: right"
                                                      :rules="requiredRules" required></v-text-field>
                                    </td>
                                </tr>
                                </tbody>
                            </v-simple-table>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <div class="pa-3" style="width: 100%">
                    <v-btn text @click="closeDialog">Cancelar</v-btn>

                    <div class="float-right">
                        <v-btn :disabled="!processed" color="primary" text @click="save">Salvar</v-btn>
                    </div>

                </div>
            </v-card-actions>
            <v-overlay absolute :value="loading" color="white">
                <v-progress-circular :size="100" :width="7" color="deep-orange" indeterminate></v-progress-circular>
            </v-overlay>
        </v-card>
        <descricao-dialog ref="descricaoDialog" @saved="onDescricaoSaved" />
        <qualificacao-dialog ref="qualificacaoDialog" @saved="onQualificacaoSaved" />
    </v-dialog>
</template>

<script>
    import {ObjetoDescricaoQueries} from "../../../queries/ObjetoDescricaoQueries";
    import {ObjetoQualificacaoQueries} from "../../../queries/ObjetoQualificacaoQueries";
    import {UnidadeMedidaQuieries} from "../../../queries/UnidadeMedidaQuieries";
    // @ts-ignore
    import QualificacaoDialog from "./objetivo/QualificacaoDialog";
    // @ts-ignore
    import DescricaoDialog from "./objetivo/DescricaoDialog";
    import gql from "graphql-tag";

    export default {
        name: "AddObjetoLote",
        components: {QualificacaoDialog, DescricaoDialog},
        data() {
            return {
                dialog: false,
                formObjeto: true,
                formObjeto2: true,
                requiredRules: [
                    (v) => !!v || 'Campo obrigatório',
                ],
                requiredSelectRules: [
                    (v) => !!v.id || 'Campo obrigatório',
                ],
                money: {
                    decimal: ',',
                    thousands: '.',
                    precision: 2,
                    masked: false /* doesn't work with directive */
                },
                quant: {
                    decimal: ',',
                    thousands: '.',
                    precision: 2,
                    masked: false /* doesn't work with directive */
                },
                objetosLote: '',
                objetos: [],
                quantidade:null,
                quantidadeUnidadeMedida: null,
                descricoes: [],
                qualificacoes: [],
                unidadesMedida: [],
                processed: false,
                fiscalizacao: null,
                loading: false,
                processing: false,
            }
        },
        methods: {
            openDialog(ficalizacao) {
                this.fiscalizacao = ficalizacao;
                this.dialog = true;
                this.loading = true;
                Promise.all([
                    this.getUnidadesMedida(),
                    this.getDescricoes(),
                    this.getQualificacoes()
                ]).then(() => {
                    this.loading = false;
                })
            },
            closeDialog() {
                this.$refs.formObjeto.resetValidation();
                this.objetosLote = ''
                this.objetos = []
                this.quantidade = null;
                this.quantidadeUnidadeMedida = null;
                this.processed = false;
                this.dialog = false;
            },
            getDescricoes() {
                return this.$apollo.query({query: ObjetoDescricaoQueries.getAll}).then((result) => {
                    this.descricoes = result.data.objetoDescricoes.map((descricao) => {
                        if(descricao.codigo){
                            descricao.descricao = descricao.codigo + " - " + descricao.descricao;
                        }
                        return descricao;
                    });
                });
            },
            getQualificacoes() {
                return this.$apollo.query({query: ObjetoQualificacaoQueries.getAll}).then((result) => {
                    this.qualificacoes = result.data.objetoQualificacoes.map((qualificacao) => {
                        if(qualificacao.codigo){
                            qualificacao.descricao = qualificacao.codigo + " - " + qualificacao.descricao;
                        }
                        return qualificacao;
                    });
                });
            },
            getUnidadesMedida() {
                return this.$apollo.query({query: UnidadeMedidaQuieries.getAll}).then((result) => {
                    this.unidadesMedida = result.data.unidadesMedida.map((unidade) => {
                        return unidade;
                    });
                });
            },
            clearQuantidade(){
                this.$refs.quantidade.$el.getElementsByTagName('input')[0].value = 0;
                this.$refs.quantidade.$el.getElementsByTagName('input')[0].dispatchEvent(new Event('input'));
            },
            process(){
                let getMatches = (string, regex)=> {
                    let matches = [];
                    let match;
                    while (match = regex.exec(string)) {
                        console.log("exec", match)
                        matches.push(match);
                    }
                    return regex.exec(string);
                }

                this.objetos = [];
                if (!this.$refs.formObjeto.validate()) {
                    return;
                }
                this.processing = true;
                let lines = this.objetosLote.split(/\r?\n/);

                try {
                    let match = /^(?:\s+)?(\d+)(?:\s+)(.+?)(A LIBERAR|IMEDIATA|\d{2}[.]\d{2}[.]\d{4})(?:\s+)(\d{2}[.]\d{2}[.]\d{4})?(?:\s+)?(.+?)(?:\s+)(.+)/g;
                    for(let line of lines){
                        console.log("line", line);
                        //let matches = match.exec(line);
                        let matches = getMatches(line, match);
                        console.log("matches", matches);
                        let descricao = this.findDescricao(matches[2]);

                        let objeto = {
                            descricao: descricao,
                            qualificacao: this.findQualificacao(matches[3]),
                            valor: matches[5]
                        };
                        this.objetos.push(objeto);
                    }
                    this.processed = true;
                    this.processing = false;
                }catch (e) {
                    console.log(e)
                    this.processing = false;
                    this.$refs.objetosLote.errorMessages = 'Erro ao processar lote. Verifique e tente novamente.'
                }


            },
            findDescricao(descricao){
                let result =  this.descricoes.find(item => {return item.descricao === descricao});
                if(result === undefined){
                    return {descricao:descricao}
                }
                return result;
            },
            findQualificacao(qualificacao){
                if(qualificacao.match(/\d{2}.\d{2}.\d{4}/)){
                    return null;
                }else{
                    return this.qualificacoes.find(item => {return item.descricao === qualificacao})
                }
            },
            save() {
                if (!this.$refs.formObjeto2.validate()) {
                    return;
                }

                this.loading = true;
                for(let objeto of this.objetos){
                    objeto.quantidade = this.quantidade;
                    objeto.quantidadeUnidadeMedida = this.quantidadeUnidadeMedida;
                    delete objeto.descricao.descricao;
                    delete objeto.descricao.codigo;
                    delete objeto.descricao.index;
                    if(objeto.qualificacao){
                        delete objeto.qualificacao.descricao;
                        delete objeto.qualificacao.codigo;
                    }
                    objeto.valor = this.convertMoneyToFloat(objeto.valor);
                    objeto.quantidade = this.convertMoneyToFloat(objeto.quantidade);
                }

                this.$apollo.mutate({
                    mutation: gql`mutation ($financiamentoId: Int!, $objetos:[ObjetoFinanciadoInput]!){
                        objetos:saveObjetoLote(financiamentoId: $financiamentoId, objetos: $objetos){
                            id
                            descricao{
                                id
                                codigo
                                descricao
                            }
                            qualificacao{
                                id
                                codigo
                                descricao
                            }
                            quantidade
                            quantidadeUnidadeMedida{
                                id
                                sigla
                            }
                            valor
                        }
                    }`,
                    variables: {
                        financiamentoId: this.fiscalizacao.financiamento.id,
                        objetos: this.objetos
                    },
                }).then((result) => {
                    let res = this._.cloneDeep(result.data.objetos);
                    this.$emit('saved', res);
                    this.closeDialog();
                    this.loading = false;
                });
            },
            newDescricaoDialog(sugestao, index) {
                sugestao.index = index;
                this.$refs.descricaoDialog.openDialog(sugestao);
            },
            onDescricaoSaved(descricao) {
                this.descricoes.push(descricao)
                let mergedObjeto = this.objetos[descricao.index];
                mergedObjeto.descricao = descricao
                this.$set(this.objetos, descricao.index, mergedObjeto)
            },
            newQualificacaoDialog(sugestao, index) {
                sugestao.index = index;
                this.$refs.qualificacaoDialog.openDialog(sugestao);
            },
            onQualificacaoSaved(qualificacao) {
                this.qualificacoes.push(qualificacao);
                let mergedObjeto = this.objetos[qualificacao.index];
                mergedObjeto.qualificacao = qualificacao
                this.$set(this.objetos, qualificacao.index, mergedObjeto)
            },
            convertMoneyToFloat(value) {
                if (!isNaN(value)) {
                    return value;
                }
                return parseFloat(value.replace(/\./g, '').replace(',', '.'));
            },
        }
    }
</script>

<style scoped>
    .custom-loader {
        animation: loader 1s infinite;
        display: flex;
    }
    @-moz-keyframes loader {
        from {
            transform: rotate(0);
        }
        to {
            transform: rotate(360deg);
        }
    }
    @-webkit-keyframes loader {
        from {
            transform: rotate(0);
        }
        to {
            transform: rotate(360deg);
        }
    }
    @-o-keyframes loader {
        from {
            transform: rotate(0);
        }
        to {
            transform: rotate(360deg);
        }
    }
    @keyframes loader {
        from {
            transform: rotate(0);
        }
        to {
            transform: rotate(360deg);
        }
    }
</style>