<template>
    <v-dialog v-model="dialog" v-if="dialog" width="500">
        <v-card>
            <v-card-title>
                <span class="headline">{{editMode ? 'Editar ' : 'Novo '}} Objetivo</span>
            </v-card-title>
            <v-card-text>
                <v-btn v-if="!editMode && objetivos.length > 0" small rounded color="primary" @click="copiarObjetivo">Copiar Anterior</v-btn>
                <v-form ref="formObjetivo" v-model="formObjetivo" lazy-validation>
                    <v-row>
                        <v-col cols="12">
                            <v-row>
                                <v-col cols="10" class="py-0">
                                    <v-autocomplete v-model="objeto.descricao" label="Descricão"
                                                    placeholder=" " :items="descricoes"
                                                    item-text="descricao" return-object clearable
                                                    :rules="requiredRules"></v-autocomplete>
                                </v-col>
                                <v-col cols="2" class="py-0 align-self-center">
                                    <v-btn text icon color="primary" @click="newDescricaoDialog">
                                        <v-icon>mdi-plus</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12">
                            <v-row class="">
                                <v-col cols="10" class="py-0">
                                    <v-autocomplete v-model="objeto.qualificacao" label="Qualificação"
                                                    placeholder=" " :items="qualificacoes"
                                                    item-text="descricao" return-object clearable
                                                    ></v-autocomplete>
                                </v-col>
                                <v-col cols="2" class="py-0 align-self-center">
                                    <v-btn text icon color="primary" @click="newQualificacaoDialog">
                                        <v-icon>mdi-plus</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="4">
                            <v-text-field label="Quantidade" placeholder=" " ref="quantidade"
                                          v-model="objeto.quantidade"
                                          v-money="quant" clearable @click:clear="clearQuantidade"
                                          :rules="requiredRules" required></v-text-field>
                        </v-col>

                        <v-col cols="3">
                            <v-select label="Un. Medida" placeholder=" "
                                      v-model="objeto.quantidadeUnidadeMedida" clearable
                                      return-object :items="unidadesMedida" item-text="sigla"
                                      ></v-select>
                        </v-col>

                        <v-col cols="5">
                            <v-text-field label="Valor" prefix="R$" placeholder=" " ref="valor"
                                          v-model="objeto.valor"
                                          v-money="money" clearable @click:clear="clearValor"
                                          :rules="requiredRules" required></v-text-field>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <div class="pa-3" style="width: 100%">
                    <v-btn text @click="cancel">Cancelar</v-btn>

                    <div class="float-right">
                        <v-btn color="primary" text @click="save">Salvar</v-btn>
                    </div>

                </div>
            </v-card-actions>
        </v-card>

        <descricao-dialog ref="descricaoDialog" @saved="onDescricaoSaved" />
        <qualificacao-dialog ref="qualificacaoDialog" @saved="onQualificacaoSaved" />
    </v-dialog>
</template>

<script>
    // @ts-ignore
    import DescricaoDialog from './DescricaoDialog';
    // @ts-ignore
    import QualificacaoDialog from './QualificacaoDialog';
    import {ObjetoDescricaoQueries} from '../../../../queries/ObjetoDescricaoQueries';
    import {ObjetoQualificacaoQueries} from '../../../../queries/ObjetoQualificacaoQueries';
    import {ObjetoFinanciado} from '../../../../models/ObjetoFinanciado';
    import {UnidadeMedidaQuieries} from '../../../../queries/UnidadeMedidaQuieries';
    import {ObjetivoFinanciadoQueries} from '../../../../queries/ObjetivoFinanciadoQueries';
    import numeral from "numeral";
    export default {
        name: 'ObjetivoDialog',
        components: {QualificacaoDialog, DescricaoDialog},
        props:{
            objetivos: null,
        },
        data() {
            return {
                dialog: false,
                editMode: false,
                formObjetivo: true,
                requiredRules: [
                    (v) => !!v || 'Campo obrigatório',
                ],
                descricoes: [],
                qualificacoes: [],
                unidadesMedida: [],
                objeto: new ObjetoFinanciado(),
                currentFinanciamento: null,
                money: {
                    decimal: ',',
                    thousands: '.',
                    precision: 2,
                    masked: false /* doesn't work with directive */
                },
                quant: {
                    decimal: ',',
                    thousands: '.',
                    precision: 2,
                    masked: false /* doesn't work with directive */
                }
            };
        },
        methods: {
            openDialog(financiamento, objeto) {
                this.dialog = true;
                this.currentFinanciamento = financiamento;
                this.getDescricoes();
                this.getQualificacoes();
                this.getUnidadesMedida();
                if (objeto) {
                    this.editMode = true;
                    this.objeto = this._.cloneDeep(objeto);
                    this.fixNumeros();
                }
            },
            closeDialog() {
                this.dialog = false;
                this.editMode = false;
                this.objeto = new ObjetoFinanciado();
                this.$refs.formObjetivo.resetValidation();
            },
            getDescricoes() {
                this.$apollo.query({query: ObjetoDescricaoQueries.getAll}).then((result) => {
                    this.descricoes = result.data.objetoDescricoes.map((descricao) => {
                        if(descricao.codigo){
                            descricao.descricao = descricao.codigo + " - " + descricao.descricao;
                        }
                        return descricao;
                    });
                });
            },
            getQualificacoes() {
                this.$apollo.query({query: ObjetoQualificacaoQueries.getAll}).then((result) => {
                    this.qualificacoes = result.data.objetoQualificacoes.map((qualificacao) => {
                        if(qualificacao.codigo){
                            qualificacao.descricao = qualificacao.codigo + " - " + qualificacao.descricao;
                        }
                        return qualificacao;
                    });
                });
            },
            getUnidadesMedida() {
                this.$apollo.query({query: UnidadeMedidaQuieries.getAll}).then((result) => {
                    this.unidadesMedida = result.data.unidadesMedida.map((unidade) => {
                        return unidade;
                    });
                });
            },
            copiarObjetivo(){
                this.objeto = this._.cloneDeep(this.objetivos[this.objetivos.length - 1]);
                delete this.objeto.id;

                this.fixNumeros();
                this.$refs.quantidade.$el.getElementsByTagName('input')[0].value = this.objeto.quantidade;
                this.$refs.quantidade.$el.getElementsByTagName('input')[0].dispatchEvent(new Event('input'));
                this.$refs.valor.$el.getElementsByTagName('input')[0].value = this.objeto.valor;
                this.$refs.valor.$el.getElementsByTagName('input')[0].dispatchEvent(new Event('input'));
            },
            fixNumeros(){
                this.objeto.quantidade = numeral(this.objeto.quantidade).format('0.00').replace(',','.');
                this.objeto.valor = numeral(this.objeto.valor).format('0.00').replace(',','.');
            },
            clearQuantidade(){
                this.$refs.quantidade.$el.getElementsByTagName('input')[0].value = 0;
                this.$refs.quantidade.$el.getElementsByTagName('input')[0].dispatchEvent(new Event('input'));
            },
            clearValor(){
                this.$refs.valor.$el.getElementsByTagName('input')[0].value = 0;
                this.$refs.valor.$el.getElementsByTagName('input')[0].dispatchEvent(new Event('input'));
            },
            save() {
                if (this.$refs.formObjetivo.validate()) {
                    const objetoParsed = this._.cloneDeep(this.objeto);
                    objetoParsed.valor = this.convertMoneyToFloat(objetoParsed.valor);
                    objetoParsed.quantidade = this.convertMoneyToFloat(objetoParsed.quantidade);
                    delete objetoParsed.index;

                    if(objetoParsed.qualificacao === null){
                        delete objetoParsed.qualificacao;
                    }

                    if (!this.editMode) {
                        this.$apollo.mutate({
                            mutation: ObjetivoFinanciadoQueries.save,
                            variables: {
                                financiamentoId: this.currentFinanciamento.id,
                                objetoFinanciado: objetoParsed,
                            },

                        }).then((result) => {
                            this.$emit('saved', Object.assign({}, result.data.saveObjetoFinanciado));
                            this.closeDialog();
                        });
                    } else {
                        this.$apollo.mutate({
                            mutation: ObjetivoFinanciadoQueries.update,
                            variables: {
                                id: objetoParsed.id,
                                objetoFinanciado: objetoParsed,
                            },

                        }).then((result) => {
                            this.$emit('edited', Object.assign({}, result.data.updateObjetoFinanciado), this.objeto.index);
                            this.closeDialog();
                        });
                    }
                }

            },
            cancel() {
                this.closeDialog();
            },

            newDescricaoDialog() {
                this.$refs.descricaoDialog.openDialog();
            },
            onDescricaoSaved(descricao) {
                this.descricoes.push(descricao);
                this.objeto.descricao = descricao;
            },
            newQualificacaoDialog() {
                this.$refs.qualificacaoDialog.openDialog();
            },
            onQualificacaoSaved(qualificacao) {
                this.qualificacoes.push(qualificacao);
                this.objeto.qualificacao = qualificacao;
            },
            convertMoneyToFloat(value) {
                if (!isNaN(value)) {
                    return value;
                }
                return parseFloat(value.replace(/\./g, '').replace(',', '.'));
            },
        },
    };
</script>

<style scoped>

</style>
