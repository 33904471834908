<template>
    <v-dialog v-model="dialog" width="500">
        <v-card>
            <v-card-title>
                <span class="headline">{{editMode ? 'Editar ' : 'Nova '}} Coordenada</span>
            </v-card-title>
            <v-card-text>
                <v-form ref="formCoordenada" v-model="formCoordenada" lazy-validation>
                    <v-row>
                        <v-col cols="6">
                            <v-text-field v-model="latitude" label="Latitude"
                                          placeholder=" " suffix="º"
                                          :rules="requiredRules" required></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field v-model="longitude" label="Longitude"
                                          placeholder=" " suffix="º"
                                          :rules="requiredRules"  required></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="6">
                            <v-text-field v-model="altitude" label="Altitude"
                                          placeholder=" " suffix="m"
                                          :rules="requiredRules"  required></v-text-field>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>

            <v-card-actions>
                <div class="pa-3" style="width: 100%">
                    <v-btn text @click="cancel">Cancelar</v-btn>

                    <div class="float-right">
                        <v-btn color="primary" text @click="save">Salvar</v-btn>
                    </div>

                </div>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        name: 'CoordenadaDialog',
        data() {
            return {
                dialog: false,
                editMode: false,
                formCoordenada: true,
                requiredRules: [
                    (v) => !!v || 'Campo obrigatório',
                ],
                coordenada: {
                    lat: null,
                    lng: null,
                    alt: null,
                },
            };
        },
        computed:{
            latitude:{
                get(){
                    return this.coordenada.lat;
                },
                set(value){
                    if(value){
                        this.coordenada.lat = value.replace(',', '.');
                    }
                }
            },
            longitude:{
                get(){
                    return this.coordenada.lng;
                },
                set(value){
                    if(value) {
                        this.coordenada.lng = value.replace(',', '.');
                    }
                }
            },
            altitude:{
                get(){
                    return this.coordenada.alt;
                },
                set(value){
                    if(value) {
                        this.coordenada.alt = value.replace(',', '.');
                    }
                }
            }
        },
        methods: {
            openDialog(coordenada = null) {
                if (coordenada) {
                    this.editMode = true;
                    this.coordenada = Object.assign({}, coordenada);
                }
                this.dialog = true;
            },
            closeDialog() {
                this.dialog = false;
                this.editMode = false;
                this.coordenada = {lat: null, lng: null, alt: null};
            },
            resetForm() {
                this.$refs.formCoordenada.reset();
            },
            save() {
                if (this.$refs.formCoordenada.validate()) {
                    this.$emit(this.editMode ? 'edited' : 'saved', Object.assign({}, this.castValues(this.coordenada)));
                    this.resetForm();
                    this.closeDialog();
                }

            },
            cancel() {
                this.resetForm();
                this.closeDialog();
            },
            castValues(coordenada) {
                const castedCoordenada = Object.assign({}, coordenada);
                castedCoordenada.lat = parseFloat(coordenada.lat);
                castedCoordenada.lng = parseFloat(coordenada.lng);
                castedCoordenada.alt = parseFloat(coordenada.alt);

                return castedCoordenada;
            },
        },
    };
</script>

<style scoped>

</style>
