<template>
    <v-card outlined class="">
        <v-card-title class="pb-0">Área</v-card-title>
        <v-card-text class="black--text">
            <v-row no-gutters>
                <v-col cols="6">
                    <span class="label">Propriedade</span>
                    <span v-if="fiscalizacao.financiamento.area && fiscalizacao.financiamento.area.nome">{{fiscalizacao.financiamento.area.nome}}</span>
                    <span v-else class="orange--text font-weight-regular"><v-icon class="orange--text" left style="font-size: 20px">warning</v-icon>Não informado</span>
                </v-col>
                <v-col cols="6">
                    <span class="label">Município</span>
                    <router-link v-if="fiscalizacao.financiamento.area && fiscalizacao.financiamento.area.localizacao"
                                 :to="{ name: 'cidade_view', params: { id: parseInt(fiscalizacao.financiamento.area.localizacao.cidade.id) }}">
                    <span>
                                {{fiscalizacao.financiamento.area.localizacao.cidade.nome}} /
                                {{fiscalizacao.financiamento.area.localizacao.cidade.estado.sigla}}
                                ({{fiscalizacao.financiamento.area.localizacao.cidade.codigo}})
                            </span>
                    </router-link>
                    <span v-else class="orange--text font-weight-regular"><v-icon class="orange--text" left style="font-size: 20px">warning</v-icon>Não informado</span>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col>
                    <span class="label">Roteiro de Acesso</span>
                    <span v-if="fiscalizacao.financiamento.area.roteiroDeAcesso">{{fiscalizacao.financiamento.area.roteiroDeAcesso}}</span>
                    <span v-else class="orange--text font-weight-regular"><v-icon class="orange--text" left style="font-size: 20px">warning</v-icon>Não informado</span>
                </v-col>
            </v-row>
            <v-row no-gutters class="mt-4"
                   style="background-color: whitesmoke; background-clip: content-box">
                <v-col class="shrink" v-if="parsedCoordenadas">
                    <v-carousel class="ma-2" height="auto" v-model="areaSelecionado"
                                :show-arrows="false" hide-delimiters >
                        <v-carousel-item v-for="(coordenadas, i) in parsedCoordenadas" :key="i">
                            <v-img :src="getMapImageUrl(coordenadas)"
                                   min-height="250" min-width="420">
                                <template v-slot:placeholder>
                                    <v-row class="fill-height ma-0" align="center" justify="center">
                                        <v-progress-circular indeterminate color="orange"></v-progress-circular>
                                    </v-row>
                                </template>
                            </v-img>
                        </v-carousel-item>
                    </v-carousel>
                </v-col>
                <v-col class="ml-2">
                    <v-subheader class="text-uppercase caption flex" style="align-items: center">
                        <span class="blue-grey--text mr-2">Coordenadas Geodésicas</span>

                        <v-btn v-if="fiscalizacao.financiamento.area && fiscalizacao.financiamento.area.coordenadas"
                               rounded depressed x-small color="primary"
                               :disabled="finalizado || fiscalizacao.dataCancelamento" @click="editCoordenadas(fiscalizacao.financiamento.area)">Editar Coordenadas</v-btn>
                        <v-btn v-else rounded depressed x-small color="primary"
                               :disabled="finalizado || fiscalizacao.dataCancelamento" @click="editCoordenadas(null)">Adicionar Coordenadas</v-btn>
                        <v-btn rounded depressed x-small color="grey lighten-2" class="ml-4"
                               :disabled="finalizado || fiscalizacao.dataCancelamento" @click="importarCoordenadas()">Importar Coordenadas</v-btn>
                    </v-subheader>

                    <template>
                        <div v-if="fiscalizacao.financiamento.area && fiscalizacao.financiamento.area.coordenadas">
                            <v-chip-group v-model="areaSelecionado" mandatory active-class="primary--text" class="ml-4">
                                <v-chip small v-for="(coordArea, index) in parsedCoordenadas" :key="index">
                                    Área {{ index + 1 }}
                                </v-chip>
                            </v-chip-group>

                            <v-simple-table dense class="mx-3 transparent" fixed-header height="170px">
                                <thead>
                                <tr>
                                    <th style="background-color: whitesmoke">Latitude</th>
                                    <th style="background-color: whitesmoke">Longitude</th>
                                    <th style="background-color: whitesmoke">Altitude</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(coordenada, index) in parsedCoordenadas[areaSelecionado]" :key="index" >
                                    <td>{{coordenada.lat}} º</td>
                                    <td>{{coordenada.lng}} º</td>
                                    <td>{{coordenada.alt}} m</td>
                                </tr>
                                </tbody>
                            </v-simple-table>
                        </div>
                        <v-alert v-else color="yellow" dense icon="mdi-alert" class="mt-4 mx-4">
                            Nenhuma coordenada adicionada. Clique no botão acima para inserir uma nova coordenada.
                        </v-alert>
                    </template>

                </v-col>

            </v-row>
        </v-card-text>
        <edit-coordenadas-dialog ref="editCoordenadasDialog" @saved="onCoordenadasUpdated" />
    </v-card>
</template>

<script>
    // @ts-ignore
    import EditCoordenadasDialog from '../../EditCoordenadasDialog';
    import gql from "graphql-tag";
    export default {
        name: "AreaFragment",
        props: {
            fiscalizacao: null,
            finalizado: null,
        },
        data(){
            return{
                areaSelecionado: 0,
            }
        },
        components: {
            EditCoordenadasDialog
        },
        computed:{
            parsedCoordenadas(){
                return JSON.parse(this.fiscalizacao.financiamento.area.coordenadas);
            }
        },
        methods: {
            getMapImageUrl(coordenadas){
                let url = 'https://maps.googleapis.com/maps/api/staticmap?key=AIzaSyDVmydqrR66sMvCjiFRy9yTXo3KMzJuQIw'
                url += '&size=420x250'
                url += '&maptype=satellite'
                url += '&sensor=false'
                url += '&path=color%3a0xff0000ff|weight:1|fillcolor%3a0xff000059'
                for(let coordenada of coordenadas){
                    url += '|'+coordenada.lat+','+coordenada.lng;
                }
                url += '|'+coordenadas[0].lat+','+coordenadas[0].lng;
                let center = this.averageGeolocation(coordenadas)
                url += '&center='+center.lat+','+center.lng
                url += ''

                return url;
            },
            averageGeolocation(coords) {
                if (coords.length === 1) {
                    return coords[0];
                }

                let x = 0.0;
                let y = 0.0;
                let z = 0.0;

                for (let coord of coords) {
                    let latitude = coord.lat * Math.PI / 180;
                    let longitude = coord.lng * Math.PI / 180;

                    x += Math.cos(latitude) * Math.cos(longitude);
                    y += Math.cos(latitude) * Math.sin(longitude);
                    z += Math.sin(latitude);
                }

                let total = coords.length;

                x = x / total;
                y = y / total;
                z = z / total;

                let centralLongitude = Math.atan2(y, x);
                let centralSquareRoot = Math.sqrt(x * x + y * y);
                let centralLatitude = Math.atan2(z, centralSquareRoot);

                return {
                    lat: centralLatitude * 180 / Math.PI,
                    lng: centralLongitude * 180 / Math.PI
                };
            },
            onCoordenadasUpdated(coordenadas) {
                this.fiscalizacao.financiamento.area.coordenadas = coordenadas;
            },
            editCoordenadas(area) {
                this.$refs.editCoordenadasDialog.openDialog(area);
            },
            importarCoordenadas(){
                let temArquivo = this.fiscalizacao.arquivos.some(arquivo => arquivo.tags.some(tag => tag.id === 5))
                if(!temArquivo){
                    this.$dialog.error({
                        text: 'É necessário um arquivo de coordenadas para continuar.',
                        title: 'Ops',
                    });
                    return
                }

                this.$dialog.confirm({
                    text: 'As coordenadas já cadastradas serão substituidas. Deseja continuar mesmo assim?',
                    title: 'Atenção',
                }).then((result) => {
                    if (result) {
                        this.$apollo.mutate({
                            mutation: gql`mutation($id:Int!) {
                                area:atualizaCoordenadasPorLaudo(fiscalizacaoId:$id ){
                                    id
                                    coordenadas
                                }
                            }`,
                            variables:{
                                id: this.$route.params.id
                            }
                        }).then((result) => {
                            console.log("area", result.data.area)
                            this.fiscalizacao.financiamento.area.coordenadas = result.data.area.coordenadas
                        })
                    }
                });
            }
        }
    }
</script>

<style scoped>
    .label{
        color: #616161 !important;
        caret-color: #616161 !important;

        font-size: 0.75rem !important;
        font-weight: 400;
        letter-spacing: 0.0333333333em !important;
        line-height: 1.25rem;

        display: block !important;
    }
</style>