<template>
    <v-dialog scrollable v-model="dialog" persistent max-width="1000px">
        <v-card v-if="dialog">
            <v-card-title>
                <span class="headline">Historico de Solicitações de Prorrogação</span>
            </v-card-title>
            <v-card-text>
                <v-simple-table dense>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left">Data da Solicitação</th>
                                <th class="text-left">Justificativa</th>
                                <th class="text-left">Data Limite Anterior</th>
                                <th class="text-left">Data Limite Solicitada</th>
                                <th class="text-left">Data Limite Definida</th>
                            </tr>
                        </thead>
                        <tbody>
                        <tr v-for="solicitacao in solicitacoesProrrogacao" :key="solicitacao.id">
                            <td>{{ solicitacao.createdAt | moment('DD/MM/YYYY') }}</td>
                            <td>{{ solicitacao.justificativa }}</td>
                            <td>{{ solicitacao.dataLimiteAnterior | moment('DD/MM/YYYY') }}</td>
                            <td>{{ solicitacao.dataLimiteSolicitada | moment('DD/MM/YYYY') }}</td>
                            <td>
                                <span v-if="solicitacao.dataLimiteDefinida">{{ solicitacao.dataLimiteDefinida | moment('DD/MM/YYYY') }}</span>
                                <v-chip v-else label x-small color="red" dark>AGUARDANDO RESPOSTA</v-chip>
                            </td>
                        </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-card-text>
            <v-card-actions>
                <div class="pa-3" style="width: 100%">
                    <div class="float-right">
                        <v-btn color="primary" text depressed @click="closeDialog" >
                            Fechar
                        </v-btn>
                    </div>
                </div>
            </v-card-actions>
            <v-overlay absolute :value="loading" color="white">
                <v-progress-circular :size="100" :width="7" color="deep-orange" indeterminate></v-progress-circular>
            </v-overlay>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "SolicitacoesProrrogacaoHistorioDialog",
    data() {
        return {
            dialog: false,
            loading: false,
            solicitacoesProrrogacao: [],
        }
    },
    computed:{
        solicitacoesProrrogacaoDefinidas(){

        }
    },
    methods:{
        openDialog(solicitacoesProrrogacao) {
            this.dialog = true;
            this.solicitacoesProrrogacao = solicitacoesProrrogacao;
        },
        closeDialog() {
            this.dialog = false;
        },
    }
}
</script>

<style scoped>

</style>