<template>
    <v-dialog v-model="dialog" persistent scrollable max-width="400px">
        <v-card v-if="dialog">
            <v-card-title>
                <span class="headline">Lembrete</span>
            </v-card-title>
            <v-card-text class="py-0">
                <v-form ref="form1" v-model="form1Valid" lazy-validation>
                    <v-row no-gutters v-if="!editMode">
                        <v-col>
                            <v-select v-model="mensagemSelecionada"
                                      :items="mensagens"
                                      placeholder="Selecione uma opção"
                                      :rules="requiredRules" required
                                      label="Mensagem" @input="onMensagemInput"
                            ></v-select>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col>
                            <v-textarea v-model="lembrete.descricao"
                                        outlined rows="3"
                                        :rules="requiredRules" required
                                        :disabled="!editMode && mensagemSelecionada !== 'Outro'"
                                        label="Descrição" auto-grow/>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <div class="pa-3" style="width: 100%">
                    <v-btn text @click="closeDialog">Cancelar</v-btn>

                    <div class="float-right">
                        <v-btn color="primary" text depressed @click="save" >
                            Salvar
                        </v-btn>
                    </div>

                </div>
            </v-card-actions>
            <v-overlay absolute :value="loading" color="white">
                <v-progress-circular :size="100" :width="7" color="deep-orange" indeterminate></v-progress-circular>
            </v-overlay>
        </v-card>
    </v-dialog>
</template>

<script>
    import gql from "graphql-tag";

    export default {
        name: "AddLembreteDialog",
        data() {
            return {
                dialog: false,
                fiscalizacaoId: null,
                lembrete: {
                    descricao: null,
                },
                loading: false,
                form1Valid: true,
                editMode: false,
                requiredRules: [
                    (v) => !!v || 'Campo obrigatório',
                ],
                mensagens: [
                    'O Laudo não foi anexado pelo banco',
                    'O Laudo não é referente à fiscalização',
                    'O cliente/gerente solicitou um prazo maior para apresentar os documentos',
                    'Outro'],
                mensagemSelecionada: null,
            }
        },
        methods: {
            openDialog(lembrete) {
                this.dialog = true;
                if (lembrete) {
                    this.editMode = true;
                    this.lembrete = this._.cloneDeep(lembrete);
                }
                this.fiscalizacaoId = this.$route.params.id
            },

            closeDialog() {
                this.dialog = false;
                this.editMode = false;
                this.lembrete = {
                    descricao: null
                }
                this.mensagemSelecionada = null;
            },

            onMensagemInput(value){
                if(value !== "Outro"){
                    this.lembrete.descricao = value;
                }else{
                    this.lembrete.descricao = null;
                }
            },

            save(){
                if(!this.$refs.form1.validate()){
                    return false
                }
                this.loading = true;
                if(!this.editMode){
                    this.$apollo.mutate({
                        mutation: gql`mutation ($id:Int!, $lembrete:LembreteInput!){
                            lembrete:addLembreteToFiscalizacao(fiscalizacaoId: $id, lembrete: $lembrete){
                                id
                                descricao
                                criadoPor{
                                    id
                                    nome
                                    sobrenome
                                    imagem{
                                        id
                                    }
                                }
                                createdAt
                            }
                        }`,
                        variables: {
                            id: this.fiscalizacaoId,
                            lembrete: {
                                descricao: this.lembrete.descricao
                            }
                        }
                    }).then((result) => {
                        this.loading = false;
                        this.$emit('saved', Object.assign({}, result.data.lembrete));
                        this.closeDialog();
                    }).catch(()=>{
                        this.loading = false;
                    });
                }else{
                    this.$apollo.mutate({
                        mutation: gql`mutation ($id: Int!, $lembrete:LembreteInput!){
                            lembrete:editLembrete(id: $id, lembrete: $lembrete){
                                id
                                descricao
                                createdAt
                            }
                        }`,
                        variables: {
                            id: this.lembrete.id,
                            lembrete: {
                                descricao: this.lembrete.descricao
                            }
                        }
                    }).then((result) => {
                        this.loading = false;
                        this.$emit('edited', Object.assign({}, result.data.lembrete), this.lembrete.index);
                        this.closeDialog();
                    }).catch(()=>{
                        this.loading = false;
                    });
                }
            }
        }
    }
</script>

<style scoped>

</style>