<template>
    <div>
        <v-subheader class="text-uppercase caption pt-4 ">
            <span class="blue-grey--text">Mensagens</span>
            <v-btn icon x-small color="blue-grey darken-1" class="ml-1"
                   @click.stop="enviarEmail" :disabled="finalizado || fiscalizacao.dataCancelamento">
                <v-icon small>mdi-plus-circle</v-icon>
            </v-btn>
        </v-subheader>
        <div class="">
            <template v-if="fiscalizacao.emailHistorico.length === 0">
                <div v-if="canSendEmail" class="body-2 text-center pa-2 mx-4 mt-2 blue-grey--text lighten-2">
                    <span class="overline font-weight-medium">Nenhum email enviado ainda.</span><br/>
                    <div class="overline mt-1 mb-2" style="line-height: 120%">
                        Crie uma nova mensagem clicando no <v-icon color="blue-grey lighten-2" x-small>mdi-plus-circle</v-icon> acima.<br/>
                        ou envie uma solicitação de dados complementares clicando no botão abaixo
                    </div>
                    <v-btn color="primary" depressed small
                           :loading="sendingEmail" :disabled="sendingEmail" @click="enviarEmail" >
                        <v-icon left>mdi-email</v-icon>
                        <span>Enviar solicitação</span>
                        <template v-slot:loader>
                            Enviando...
                        </template>
                    </v-btn>
                </div>
            </template>
            <template v-else>
                <v-list dense class="pa-0 px-2" color="transparent">
                    <template v-for="(email, index) in fiscalizacao.emailHistorico">
                        <!--<v-list-item :key="index" @click="" :title='email.createdAt | moment("DD/MM/YYYY [às] HH:mm [hrs]")'>
                            <v-list-item-content class="py-2">
                                <v-list-item-title class="font-weight-regular">

                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>-->
                        <v-list-item class="px-0" :key="index" @click="abrirEmail(email.gmailThreadId)"
                                     :title='email.createdAt | moment("DD/MM/YYYY [às] HH:mm [hrs]")'>
                            <v-alert class="ma-0"  color="cyan" border="left" dense
                                     colored-border width="100%">
                                <!--You've got <strong>5</strong> new updates on your timeline!.-->
                                <span class="caption">Enviado em {{email.createdAt | moment("from", "now")}}</span>
                            </v-alert>
                        </v-list-item>
                    </template>
                </v-list>
            </template>

        </div>

        <enviar-email-dialog ref="enviarEmailDialog" @sent="emailEnviado"/>
        <view-email-dialog ref="viewEmailDialog" />
    </div>
</template>

<script>
    // @ts-ignore
    import EnviarEmailDialog from "../../EnviarEmailDialog";
    // @ts-ignore
    import ViewEmailDialog from "../../ViewEmailDialog";
    export default {
        name: "EmailsFragment",
        props: {
            fiscalizacao: null,
            finalizado: null,
        },
        components: {
            ViewEmailDialog,
            EnviarEmailDialog,
        },
        computed: {
          canSendEmail(){
              if(this.fiscalizacao.dataFiscalizacao){
                  return false;
              }
              return  true;
          }
        },
        data(){
            return{
                sendingEmail: false,
            }
        },
        methods: {
            enviarEmail(){
                if(!this.fiscalizacao.supervisedBy){
                    this.$dialog.warning({
                        text: 'A fiscalização precisa ter um responsável',
                        title: 'Atenção',
                        actions: {text: "ok"}
                    });
                    return;
                }
                if (!this.fiscalizacao.tipo) {
                    this.$dialog.warning({
                        text: 'Defina o tipo da fiscalização antes de enviar',
                        title: 'Atenção',
                        actions: {text: "ok"}
                    });
                    return;
                }
                if (!this.fiscalizacao.financiamento.agencia) {
                    this.$dialog.warning({
                        text: 'Defina uma agência antes de enviar',
                        title: 'Atenção',
                        actions: {text: "ok"}
                    });
                    return;
                }
                if (!this.fiscalizacao.financiamento.origemRecursos) {
                    this.$dialog.warning({
                        text: 'Defina a origem dos recursos antes de enviar',
                        title: 'Atenção',
                        actions: {text: "ok"}
                    });
                    return;
                }
                this.$refs.enviarEmailDialog.openDialog(this.fiscalizacao);
            },
            emailEnviado(emailHistorico){
                this.fiscalizacao.emailHistorico.push(emailHistorico);
            },
            abrirEmail(gmailThreadId){
                if(gmailThreadId){
                    this.$refs.viewEmailDialog.openDialog(gmailThreadId, this.fiscalizacao);
                }
            }
        }
    }
</script>

<style scoped>

</style>